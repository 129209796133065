import React, {useReducer} from 'react';
import {
    Box,
    Button,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    Select, TextField,
    Typography
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {DeleteOutlined} from "@mui/icons-material";
import {useAdminState} from "../../../../providers/AdminStateProvider";
import {PresetType} from "../../../../types/PoolAgentsTypes";

const ModalParamsPreset = ({open, handleClose, preset}: {open: boolean, handleClose: () => void, preset: PresetType}) => {
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const adminState = useAdminState()!!
    const items_presets = adminState.getters.getItems()
    const abilities = adminState.getters

    const handleAddItem = () => {
        preset.items.push("")
        forceUpdate()
    }

    const handleDeleteItem = (id: number) => {
        preset.items.splice(id, 1)
        forceUpdate()
    }

    const handleAddAbility = () => {
        preset.abilities.push("")
        forceUpdate()
    }

    const handleDeleteAbility = (id: number) => {
        preset.abilities.splice(id, 1)
        forceUpdate()
    }

    const handleChangeRepGov = (val: number) => {
        preset.reputation_opposition = val
        forceUpdate()
    }

    const handleChangeRepOppos = (val: number) => {
        preset.reputation_opposition = val
        forceUpdate()
    }

    const handleChangeAttrAtk = (val: number) => {
        preset.attributes.attack = val
        forceUpdate()
    }

    const handleChangeAttrStl = (val: number) => {
        preset.attributes.stealth = val
        forceUpdate()
    }

    const handleChangeAttrDef = (val: number) => {
        preset.attributes.defence = val
        forceUpdate()
    }

    const handleChangeAttrSurv = (val: number) => {
        preset.attributes.survival = val
        forceUpdate()
    }


    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: "80%",
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
                color: "text.primary"
            }}>
                <Grid2 xs={12} container>
                    <Grid2 xs={12}>
                        <Typography textAlign="center" variant="h5">Пресет: {preset._id}</Typography>
                    </Grid2>
                    <Divider sx={{width: "100%", m: 1}}/>
                    <Grid2 xs={12} container spacing={1} columnSpacing={1}>
                        <Grid2 xs={3}>
                            <TextField
                                size="small"
                                type="number"
                                label="ATK"
                                value={preset.attributes.attack}
                                onChange={(e) => handleChangeAttrAtk(parseInt(e.target.value))}
                            />
                        </Grid2>
                        <Grid2 xs={3}>
                            <TextField
                                size="small"
                                type="number"
                                label="STL"
                                value={preset.attributes.stealth}
                                onChange={(e) => handleChangeAttrStl(parseInt(e.target.value))}
                            />
                        </Grid2>
                        <Grid2 xs={3}>
                            <TextField
                                size="small"
                                type="number"
                                label="SUR"
                                value={preset.attributes.survival}
                                onChange={(e) => handleChangeAttrSurv(parseInt(e.target.value))}
                            />
                        </Grid2>
                        <Grid2 xs={3}>
                            <TextField
                                size="small"
                                type="number"
                                label="DEF"
                                value={preset.attributes.defence}
                                onChange={(e) => handleChangeAttrDef(parseInt(e.target.value))}
                            />
                        </Grid2>
                    </Grid2>
                    <Divider sx={{width: "100%", m: 1}}/>
                    <Grid2 xs={12} container spacing={1} columnSpacing={1}>
                        <Grid2 xs={6} container justifyContent="center">
                            <TextField
                                size="small"
                                type="number"
                                label="REP GOV"
                                value={preset.reputation_government ? preset.reputation_government : 0}
                                onChange={(e) => handleChangeRepGov(parseInt(e.target.value))}
                            />
                        </Grid2>
                        <Grid2 xs={6} container justifyContent="center">
                            <TextField
                                size="small"
                                type="number"
                                label="REP OPPOS"
                                value={preset.reputation_opposition ? preset.reputation_opposition : 0}
                                onChange={(e) => handleChangeRepOppos(parseInt(e.target.value))}
                            />
                        </Grid2>
                    </Grid2>
                    <Divider sx={{width: "100%", m: 1}}/>
                    <Grid2 xs={12} container spacing={1} alignItems="center" justifyContent="center">
                        <Grid2 xs={12} mb={1}>
                            <Typography textAlign="center" variant="h5">Абилки</Typography>
                        </Grid2>
                        <Grid2 xs={12} mb={1} container justifyContent="center">
                            <Button variant="outlined" disabled={preset.items.length >= 4} onClick={handleAddAbility}>Добавить способность</Button>
                        </Grid2>
                        {preset.abilities.map((it, key) => <Grid2
                            key={key}
                            xs={3}
                            justifyContent="center"
                        >
                            <FormControl fullWidth size="small" variant="standard" sx={{display: "flex", alignItems:"center"}}>
                                <InputLabel>Способность</InputLabel>
                                <Select
                                    fullWidth
                                    label="Способность"
                                    defaultValue={preset.abilities[key]}
                                    onChange={(e) => preset.abilities[key] = e.target.value}
                                >
                                    <MenuItem value="greed">Жадность</MenuItem>
                                    <MenuItem value="athlete">Атлет</MenuItem>
                                    <MenuItem value="right_hand">Правая Рука</MenuItem>
                                    <MenuItem value="company_soul">Душа Компании</MenuItem>
                                    <MenuItem value="lonely_wolf">Одинокий Волк</MenuItem>
                                    <MenuItem value="bully">Задира</MenuItem>
                                    <MenuItem value="strah">Перестраховщик</MenuItem>
                                    <MenuItem value="gunner">Автоматчик</MenuItem>
                                    <MenuItem value="cowboy">Ковбой</MenuItem>
                                    <MenuItem value="hunter">Охотник</MenuItem>
                                    <MenuItem value="sniper">Снайпер</MenuItem>
                                    <MenuItem value="heavy">Тяжеловес</MenuItem>
                                    <MenuItem value="ninja">Ниндзя</MenuItem>
                                    <MenuItem value="mechanic">Механик</MenuItem>
                                    <MenuItem value="smart">Умник</MenuItem>
                                    <MenuItem value="shirt">Родился в рубашке</MenuItem>
                                    <MenuItem value="medic">Фельдшер</MenuItem>
                                    <MenuItem value="accountant">Бухгалтер</MenuItem>
                                    <MenuItem value="crazy">Отбитьй</MenuItem>
                                    <MenuItem value="lazy">Ленивый</MenuItem>
                                    <MenuItem value="claus">Клаустрофоб</MenuItem>
                                    <MenuItem value="agora">Агорафоб</MenuItem>
                                    <MenuItem value="survival_master">Мастер выжиывания</MenuItem>
                                    <MenuItem value="defence_master">Мастер защиты</MenuItem>
                                    <MenuItem value="stealth_master">Мастер скрытности</MenuItem>
                                    <MenuItem value="attack_master">Мастер атаки</MenuItem>
                                    <MenuItem value="light_weight">Ничего лишнего</MenuItem>
                                    <MenuItem value="diplomat">Димпломат</MenuItem>
                                    <MenuItem value="tank">Танк</MenuItem>
                                </Select>
                                <IconButton size={"small"} sx={{width: "10%", color: "text.primary"}} onClick={() => handleDeleteAbility(key)}><DeleteOutlined/></IconButton>
                            </FormControl>
                        </Grid2>)}
                    </Grid2>
                    <Divider sx={{width: "50%", m: 1}}/>
                    <Grid2 xs={12} container spacing={1} alignItems="center" justifyContent="center">
                            <Grid2 xs={12} mb={1}>
                                <Typography textAlign="center" variant="h5">Предметы</Typography>
                            </Grid2>
                            <Grid2 xs={12} mb={1} container justifyContent="center">
                                <Button variant="outlined" disabled={preset.items.length >= 4} onClick={handleAddItem}>Добавить предмет</Button>
                            </Grid2>
                            {preset.items.map((it, key) => <Grid2
                                key={key}
                                xs={3}
                                justifyContent="center"
                            >
                                <FormControl fullWidth size="small" variant="standard" sx={{display: "flex", alignItems:"center"}}>
                                    <InputLabel>Предмет</InputLabel>
                                    <Select
                                        fullWidth
                                        label="Предмет"
                                        defaultValue={preset.items[key]}
                                        onChange={(e) => preset.items[key] = e.target.value}
                                    >
                                        {items_presets?.map((item, key) =>
                                            <MenuItem key={key} value={item.id}>{item.title}</MenuItem>
                                        )}
                                    </Select>
                                    <IconButton size={"small"} sx={{width: "10%", color: "text.primary"}} onClick={() => handleDeleteItem(key)}><DeleteOutlined/></IconButton>
                                </FormControl>
                            </Grid2>)}
                    </Grid2>
                </Grid2>
            </Box>
        </Modal>
    );
};

export default ModalParamsPreset;