import React, {useEffect, useState} from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import {Button, Divider, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography} from "@mui/material";
import {useAdminState} from "../../../providers/AdminStateProvider";
import {useRequesting} from "../../../../../app/providers/RequestingProvider";

const AccountPurchases = () => {
    const [selectedPurchase, setSelectedPurchase] = useState<undefined | string>(undefined)
    const request = useRequesting()?.authorizeRequest!!
    const adminState = useAdminState()!!
    const account = adminState.getters.getAccount()
    const purchases = adminState.getters.getPurchaseIds()

    const handleChange = (event: SelectChangeEvent) => {
        setSelectedPurchase(event.target.value as string)
    }

    const handleInitiatePurchase = () => {
        request.post(`admin/purchases/initiate`, {}, {
            id: selectedPurchase,
            accountId: account?.selectedAcc
        })
    }

    return (
        <Grid2 xs={12} container>
            <Divider sx={{width: "100%"}}/>
            <Grid2 xs={12}><Typography variant="h5">Инициация покупок</Typography></Grid2>
            <Divider sx={{width: "100%"}}/>
            <Grid2 xs={12} m={1}>

                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Purchase</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedPurchase}
                        label="Purchase"
                        onChange={handleChange}
                    >
                        {purchases && purchases.map((it) => <MenuItem value={it.id}>
                            [{it.type}] - {it.name}
                        </MenuItem>)}
                    </Select>
                </FormControl>

            </Grid2>
            <Grid2 xs={12} m={1}>
                <Button variant="outlined" onClick={handleInitiatePurchase}>Добавить покупку</Button>
            </Grid2>
        </Grid2>
    );
};

export default AccountPurchases;