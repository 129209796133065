import React, {useEffect} from 'react';
import {ItemDataType, ObjDataType} from "../../../../../pages/admin/subpages/TranslatesPage";
import Grid2 from "@mui/material/Unstable_Grid2";
import TranslateObject from "./TranslateObject";
import {Button, Divider} from "@mui/material";

const TranslateItem = ({
                           data,
                           openModal,
                       }: {
    data: ItemDataType,
    openModal: (tag: string, values: Map<string, string>, obj: ObjDataType) => void,
}) => {

    return (
        <Grid2 container xs={12} alignItems="center" p={1} borderBottom="1px solid black">
            <Grid2 xs={2} display="flex" alignItems="center" justifyContent="center" sx={{overflowWrap:"anywhere"}}>
                {data.item}
            </Grid2>
            <Grid2 xs={2} display="flex" alignItems="center" justifyContent="center">{data.path}</Grid2>
            <Grid2 xs={8} container>{data.data.map((it) => <TranslateObject data={it} openModal={openModal}></TranslateObject>)}</Grid2>
        </Grid2>
    );
};

export default TranslateItem;