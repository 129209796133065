import React, {useEffect, useState} from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import {useRequesting} from "../../../app/providers/RequestingProvider";
import {Box, Divider, List, ListItemButton, ListItemText} from "@mui/material";
import TranslateCollection from "../../../widgets/admin/main_components/content/translates_content/TranslateCollection";

export type CollectionDataType = {
    collection: string,
    data: ItemDataType[]
}

export type ItemDataType = {
    path: string,
    item: string,
    data: ObjDataType[]
}

export type ObjDataType = {
    tag: string,
    obj: string,
    data: Map<string, string>
}

const TranslatesPage = () => { // todo переделать под данные в admin state
    const request = useRequesting()?.authorizeRequest!!

    const [collections, setCollections] = useState<undefined | CollectionDataType[]>(undefined)

    const [selectedColl, setSelectedColl] = useState<undefined | string>(undefined)
    
    useEffect(() => {
        request.get(`admin/languages`).then((res) => res.json()).then((res) => setCollections(res))
    }, [])

    return (
        <Grid2 xs={12} container>
            <Box width="100%" display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center">
                <List
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    {
                        collections?.map((it, key) => <ListItemButton key={key} id={it.collection} onClick={() => setSelectedColl(it.collection)}>
                            <ListItemText>{it.collection}</ListItemText>
                        </ListItemButton>)
                    }
                </List>
            </Box>
            <Divider sx={{width: "100%", mb: 1}}/>
            {selectedColl ? <TranslateCollection data={collections!!.find((it) => it.collection === selectedColl)!!}/> : undefined}
        </Grid2>
    );
};

export default TranslatesPage;