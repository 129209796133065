import React, {useEffect, useState} from 'react';
import {DataGrid, GridCellParams, GridColDef, GridFilterModel} from "@mui/x-data-grid";
import {AdminStateTypeActions, useAdminState} from "../../../providers/AdminStateProvider";
import {useRequesting} from "../../../../../app/providers/RequestingProvider";
import {AccountListCommon} from "../../../types/AccountTypes";

const TableAccounts = ({setIsModal}: { setIsModal: (val: boolean) => void }) => {
    const request = useRequesting()?.authorizeRequest!!
    const adminState = useAdminState()!!
    const [accounts, setAccounts] = useState<AccountListCommon>()
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });
    const [filterModel, setFilterModel] = useState<GridFilterModel>({items: []})

    useEffect(() => {
        updateAccounts()
    }, []);

    useEffect(() => {
        updateAccounts()
    }, [paginationModel, filterModel]);

    const updateAccounts = () => {
        request.get(`admin/accounts_list?count=10&page=${paginationModel.page}&filters=${JSON.stringify({
            filters: filterModel.items
        })}`)
            .then((res) => res.json())
            .then((res) => {
                setAccounts(res)
            })
    }

    const columns: GridColDef[] = [
        {field: "name", headerName: "Имя"},
        {field: "version", headerName: "Версия"},
        {field: "platform", headerName: "Платформа"},
        {field: "id", headerName: "Id"},
        {field: "authorizations", headerName: "Auth's", width: 400}
    ]

    const selectAccount = (data: GridCellParams) => {
        adminState.dispatch({
            type: AdminStateTypeActions.GetAccount,
            payload: data.id
        })
        setIsModal(false)
    }

    return (
        <DataGrid // todo problems with all data in one time
            sx={{width: "100%", color: "text.disabled"}}
            columns={columns}
            rows={accounts?.accounts ? accounts.accounts.map((it) => ({
                ...it,
                authorizations: it.authorizations.map((auth) => auth.platform)
            })) : []}
            rowCount={accounts?.meta.count}
            onCellClick={selectAccount}
            pageSizeOptions={[10]}
            paginationModel={paginationModel}
            paginationMode="server"
            filterMode="server"
            onPaginationModelChange={setPaginationModel}
            onFilterModelChange={setFilterModel}
        />
    );
};

export default TableAccounts;