import React, {createContext, useContext} from 'react';
import {useMediaQuery, useTheme} from "@mui/material";

const SizeableContext = createContext<any>(null)

export const SizeableProvider = ({children}: {children: any}) => {
    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
    const isDownMedium = useMediaQuery(theme.breakpoints.down("md"))
    const isDownLarge = useMediaQuery(theme.breakpoints.down("lg"))

    return (
        <SizeableContext.Provider
            value={{
                isSmall,
                isDownMedium,
                isDownLarge
            }}
        >
            {children}
        </SizeableContext.Provider>
    );
};

export const useSizeableContext = () => useContext(SizeableContext)
