import React from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import {Box, List, ListItemButton, ListItemText} from "@mui/material";
import {componentOfPoolAgents} from "../../../widgets/admin/main_components/content/Constants";
import {useAdminState} from "../../../widgets/admin/providers/AdminStateProvider";

const PoolsAgentsPage = () => {
    const listComponents = componentOfPoolAgents
    const adminState = useAdminState()!!
    const poolAgents = adminState.getters.getPoolAgents()

    const selectComponent = (id: string) => {
        adminState.setters.setComponentPoolAgents(id)
    }

    return (
        <Grid2 xs={12} container>
            <Box width="100%" display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center">
                <List
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "center"
                    }}
                >
                    {
                        listComponents.map((it, key) => <ListItemButton
                            key={key}
                            id={it.id}
                            onClick={() => selectComponent(it.id)}
                        >
                            <ListItemText>{it.label}</ListItemText>
                        </ListItemButton>)
                    }
                </List>
            </Box>
            {listComponents.map((it) => {
                return it.component!!(poolAgents?.openedComponent === it.id && poolAgents.pool !== undefined)
            })}
        </Grid2>
    );
};

export default PoolsAgentsPage;