import React, {useEffect, useState} from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import {useRequesting} from "../../../app/providers/RequestingProvider";
import RaidPvpResult from "./RaidPvpResult";

export type PvpRaidDetailType = {
    agents_solved: []
    attack_time: number
    attack_account: string | undefined
    attacker_money_reserved: number
    attacker_money_reward: number
    attacker_name: string
    attackers: []
    bot_power: number
    bot_status: string
    defence_account: string
    defence_tier: number
    defence_wealth: number
    defence_wealth_building: number
    defence_wealth_items: number
    defence_wealth_money: number
    defence_winrate: number
    defender_name: string
    defenders: []
    process_time: number
    ransom_paid_amount: number
    result_pending: string[]
    reveal_time: number
    status: string,
    winner: string,
    result: any
}

const RaidPvpDetails = ({pvpId}: {pvpId: string}) => {
    const request = useRequesting()?.authorizeRequest!!
    const [pvpDetail, setPvpDetail] = useState<undefined | PvpRaidDetailType>(undefined)

    useEffect(() => {
        request.get(`admin/pvp/${pvpId}`)
            .then((res) => res.json())
            .then(setPvpDetail)
    }, [])

    return (
        <Grid2 xs={12} container>
            <Grid2 xs={12}>
                Атакующий игрок: {pvpDetail?.attacker_name}
            </Grid2>
            <Grid2 xs={12}>
                Защищающийся игрок: {pvpDetail?.defender_name}
            </Grid2>
            <Grid2 xs={12}>
                Время атаки: {pvpDetail?.attack_time}
            </Grid2>
            <Grid2 xs={12}>
                Время обнаружения: {pvpDetail?.reveal_time}
            </Grid2>
            <Grid2 xs={12}>
                Время процессинга: {pvpDetail?.process_time}
            </Grid2>
            <Grid2 xs={12}>
                Сумма штрафа или награды: {pvpDetail?.attacker_money_reward}
            </Grid2>
            <Grid2 xs={12}>
                Заплачено за откуп от марадеров: {pvpDetail?.ransom_paid_amount}
            </Grid2>
            <Grid2 xs={12}>
                Состояние: {pvpDetail?.status}
            </Grid2>
            <Grid2 xs={12}>
                Результат: {pvpDetail ? <RaidPvpResult state={pvpDetail}/> : <></>}
            </Grid2>
        </Grid2>
    );
};

export default RaidPvpDetails;