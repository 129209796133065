import React, {createRef, MutableRefObject, useEffect, useRef, useState} from 'react';
import {CollectionDataType, ObjDataType} from "../../../../../pages/admin/subpages/TranslatesPage";
import Grid2 from "@mui/material/Unstable_Grid2";
import TranslateItem from "./TranslateItem";
import {
    Box,
    Button,
    Divider,
    FormControl, IconButton,
    InputLabel, MenuItem,
    Modal, Select,
    TextareaAutosize,
    TextField,
    Typography
} from "@mui/material";
import {useRequesting} from "../../../../../app/providers/RequestingProvider";
import ModalCreateTranslate from "../../../../abstract_components/modals/ui/ModalCreateTranslate";
import {Refresh, RefreshOutlined, RefreshRounded} from "@mui/icons-material";

type ChangeTranslateModalType = {
    tag: string
    values: {[x: string]: MutableRefObject<any>}
    initialValues: {[x: string]: any}
    obj: ObjDataType,
    newTag?: string
}

export type CreateTranslateModalType = {
    tag: string,
    values: {[x: string]: MutableRefObject<any>}
}

const TranslateCollection = ({data}: {data: CollectionDataType} ) => {
    const request = useRequesting()!!.authorizeRequest!!
    const [modalOpen, setModalOpen] = useState(false)
    const [modalData, setModalData] = useState<undefined | ChangeTranslateModalType>(undefined)
    const [language, setLanguage] = useState<undefined | string>(undefined)

    const [modalCreateTranslateOpen, setModalCreateTranslateOpen] = useState(false)
    const [modalCreateTranslateData, setModalCreateTranslateData] = useState<undefined | CreateTranslateModalType>(undefined)

    const handleOpen = () => {
        setModalOpen(true)
    }

    useEffect(() => {
        if(modalData)
            handleOpen()
    }, [modalData])

    useEffect(() => {
        if(modalCreateTranslateData)
            handleOpenCreateTranslateModal()
    }, [modalCreateTranslateData])

    const openWithModalData = (tag: string, values: Map<string, string>, obj: ObjDataType) => {
        const newMap: {[x: string]: MutableRefObject<any>} = Object.entries(values).map((it) => {
            const newRef: MutableRefObject<any> = createRef<any>()
            return ({[it[0]]: newRef})
        }).reduce((obj, item) => Object.assign(obj, item))
        const initialValues = Object.entries(values).map((it) => ({[it[0]]: it[1]})).reduce((obj, item) => Object.assign(obj, item))
        setModalData({tag: tag, values: newMap, initialValues: initialValues, obj: obj})
    }

    const openCreateTranslateModal = () => {
        setModalCreateTranslateData({tag: "", values: {ru: createRef(), en: createRef()}}) //todo убрать хардкод брать с бека
    }

    const handleOpenCreateTranslateModal = () => {
        setModalCreateTranslateOpen(true)
    }

    const handleCloseCreateTranslate = () => {
        setModalCreateTranslateData(undefined)
        setModalCreateTranslateOpen(false)
    }

    const handleClose = () => {
        setModalData(undefined)
        setLanguage(undefined)
        setModalOpen(false)
    }

    const handleSaveTranslate = () => {
        const result = Object.entries(modalData!!.values).map((it) => ({[it[0]]:it[1].current.value})).reduce((obj, item) => Object.assign(obj, item))
        request.post(`admin/languages/update-language`, {},
            {
                initialTag: modalData!!.tag,
                newTag: modalData!!.newTag ? `${data.collection}.${modalData!!.newTag}`: null,
                value: result
            })
            .then((res) => {
                modalData!!.obj.data = result as Map<string, string>
                handleClose()
            })
    }

    const handleAddKey = () => {
        setModalData(prevState => ({
            tag: prevState!!.tag,
            values: Object.assign(prevState!!.values, {[language!!]: createRef<any>()}),
            initialValues: Object.assign(prevState!!.initialValues, {[language!!]: ""}),
            obj: prevState!!.obj
        }))
    }

    const refreshTexts = () => {
        request.post("admin/languages/clear-cache")
    }

    return (
        <>
            <Grid2 xs={12} container justifyContent="center" alignItems="center">
                <Grid2 xs={12} container justifyContent="center">
                    {data.collection === "strings" &&
                        <>
                            <Button variant="outlined" onClick={() => openCreateTranslateModal()}>Добавить</Button>
                            <IconButton onClick={() => refreshTexts()}><RefreshRounded/></IconButton>
                        </>
                    }
                </Grid2>
                {data.data.map((it) =>
                    <TranslateItem data={it} openModal={openWithModalData} />
                )}
            </Grid2>
            <Modal
                open={modalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: "80%",
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                    color: "text.primary"
                }}>
                    {modalData && <Grid2 xs={12} container>
                        <Grid2 xs={12} container justifyContent="center">
                            <Typography variant="h6" textAlign="center" width="80%">
                                {modalData.newTag ? <TextField color="warning" label="Тег" value={modalData.newTag} fullWidth/> : modalData.tag}
                            </Typography>
                            {
                                !modalData.newTag && <Button
                                    onClick={() => {
                                        const initialTag = modalData!!.tag.split(".")
                                        initialTag.splice(0, 1)
                                        setModalData((prevState) =>
                                            ({...prevState!!, newTag: initialTag.join(".")})
                                        )
                                    }}
                                    size="small"
                                    variant="outlined"
                                    sx={{ml: 1}}
                                    color="warning"
                                >
                                    Изменить Тег
                                </Button>
                            }
                        </Grid2>
                        <Divider sx={{m:1, width: "100%", borderColor: "white"}}/>
                        <FormControl sx={{width: "50%"}} variant="outlined" size="small">
                            <InputLabel>Идентификатор</InputLabel>
                            <Select label="Идентификатор" value={language} onChange={(e) => setLanguage(e.target.value)}>
                                {!Object.keys(modalData.values).includes("en") && <MenuItem value="en">EN</MenuItem>}
                                {!Object.keys(modalData.values).includes("ru") && <MenuItem value="ru">RU</MenuItem>}
                                {/*todo убрать хардкод*/}
                            </Select>
                        </FormControl>
                        <Button disabled={!language} onClick={handleAddKey}>Добавить</Button>
                        <Divider sx={{m:1, width: "100%", borderColor: "white"}}/>
                        <Grid2 xs={12} container>
                            {Object.entries(modalData.values).map(([id, value]: [string, MutableRefObject<any>], key) => <Grid2 key={key} xs={12} container>
                                <Grid2 xs={1} textAlign="right">{id} |</Grid2>
                                <Grid2 xs={11}>
                                    <TextareaAutosize
                                        style={{fontSize: "15px", minWidth: "100%", maxWidth: "100%", maxHeight: "50vh"}}
                                        placeholder="value"
                                        defaultValue={modalData.initialValues[id]}
                                        ref={value}
                                    />
                                </Grid2>
                            </Grid2>)}
                        </Grid2>
                        <Divider sx={{width: "100%", m:1, borderColor: "white"}}/>
                        <Grid2 xs={12} container justifyContent="center">
                            <Button variant="outlined" onClick={handleSaveTranslate}>Сохранить</Button>
                        </Grid2>
                    </Grid2>
                    }
                </Box>
            </Modal>
            {modalCreateTranslateData && <ModalCreateTranslate
                collection={data.collection}
                modalOpen={modalCreateTranslateOpen}
                handleClose={handleCloseCreateTranslate}
                modalData={modalCreateTranslateData} // todo Добавить обновление после добавления строки
            />}
        </>
    );
};

export default TranslateCollection;