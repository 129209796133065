import React, {MutableRefObject, useEffect, useRef, useState} from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import {RaidType} from "../../../widgets/admin/types/RaidsTypes";
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import ModalChangeRewards from "../../../widgets/abstract_components/modals/ui/ModalChangeRewards";
import {useRequesting} from "../../../app/providers/RequestingProvider";

export type ItemRewardType = {
    item_template: string,
    quality: number,
    type: string
}

export type RewardType = {
    weight: number,
    type: string,
    money_amount: number,
    items: ItemRewardType[][]
}

const RaidPage = ({initialRaid}: {initialRaid: RaidType}) => {
    const [raid, setRaid] = useState<RaidType>({...initialRaid})
    const request = useRequesting()?.authorizeRequest!!

    const cord = {x: useRef<any>(), y: useRef<any>()}
    const [single, setSingle] = useState<boolean>(raid.single)
    const [coopRaid, setCoopRaid] = useState<{turn: boolean, count: MutableRefObject<any>}>({turn: raid.coop, count: useRef()})
    const [commonParams, setCommonParams] = useState<{type: string, diff: string, geo: string}>({type: raid.type, diff: raid.difficulty, geo: raid.geo_type})
    const loot = ([...raid.loot])
    const loot_big = ([...raid.loot_big])

    useEffect(() => {
        coopRaid.count.current.value = raid.coop_count
        cord.x.current.value = raid.x
        cord.y.current.value = raid.y
    }, [])

    const checkCoopRaid = () => {
        setCoopRaid({...coopRaid, turn: !coopRaid.turn})
    }

    const handleSingleCheck = () => {
        setSingle(!single)
    }

    const handleChangeType = (value: string) => {
        setCommonParams(prevState => ({...prevState!!, type: value}))
    }

    const handleChangeDiff = (value: string) => {
        setCommonParams(prevState => ({...prevState!!, diff: value}))
    }

    const handleChangeGeoType = (value: string) => {
        setCommonParams(prevState => ({...prevState!!, geo: value}))
    }

    const handleUpdateRaid = () => {
        const raidUpdate: RaidType = ({
            ...raid,
            single: single,
            coop: coopRaid.turn,
            coop_count: coopRaid.count.current.value,
            x: cord.x.current.value,
            y: cord.y.current.value,
            type: commonParams.type,
            difficulty: commonParams.diff,
            geo_type: commonParams.geo,
            loot: loot,
            loot_big: loot_big
        })

        request.post(`admin/raids/update_raid`, {}, raidUpdate).then((res) => res.json()).then((res) => setRaid(res))
    }

    return (
        <Grid2 xs={12} container>
            <Grid2 xs={12}>
                <Typography variant="h5" textAlign="center">{new Map(Object.entries(raid.title.value)).get("ru")}</Typography>
            </Grid2>
            <Grid2 xs={12} container spacing={2} justifyContent="center" alignItems="center">
                <Grid2 xs={4} sx={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                    <Button variant="outlined">Изменить</Button>
                    <img width="50%" src={`/images/uploaded/${raid.image}`}/>
                    <Grid2 xs={12}>
                        <TextField inputRef={cord.x} sx={{width: 100}} size="small" label="x" type="number"></TextField>
                        <TextField inputRef={cord.y} sx={{width: 100}} size="small" label="y" type="number"></TextField>
                    </Grid2>
                </Grid2>
                <Grid2 xs={4}>
                    <FormControl fullWidth margin={"dense"}>
                        <InputLabel>Тип</InputLabel>
                        {/*Переделать нахрен этот ручной ввод todo*/}
                        <Select onChange={(v) => handleChangeType(v.target.value)} label="Тип" value={commonParams.type}>
                            <MenuItem value="attack">Нападение</MenuItem>
                            <MenuItem value="survival">Исследование</MenuItem>
                            <MenuItem value="stealth">Шпионаж</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin={"dense"}>
                        <InputLabel>Сложность</InputLabel>
                        <Select onChange={(v) => handleChangeDiff(v.target.value)} label="Сложность" value={commonParams.diff}>
                            <MenuItem value="very_easy">Очень Легкий</MenuItem>
                            <MenuItem value="easy">Легкий</MenuItem>
                            <MenuItem value="regular">Обычный</MenuItem>
                            <MenuItem value="hard">Тяжелый</MenuItem>
                            <MenuItem value="extreme">Экстремальный</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin={"dense"}>
                        <InputLabel>Местность</InputLabel>
                        <Select onChange={(v) => handleChangeGeoType(v.target.value)} label="Местность" value={commonParams.geo}>
                            <MenuItem value="mixed">Смешанная</MenuItem>
                            <MenuItem value="open">Открытая</MenuItem>
                            <MenuItem value="closed">Закрытая</MenuItem>
                        </Select>
                    </FormControl>
                </Grid2>
                <Grid2 xs={4}>
                    <FormControlLabel control={<Checkbox checked={single} onClick={handleSingleCheck}/>} label="Соло рейд"/>
                    <FormControlLabel control={<>
                        <Checkbox checked={coopRaid.turn} onClick={checkCoopRaid}/>
                        <TextField inputRef={coopRaid.count} type="number" size="small" sx={{width: "40%"}}/>
                    </>} label="&nbsp;Кооп. рейд"/>
                    <TextField size="small" label="Placeholder" margin="dense"/>
                </Grid2>
            </Grid2>
            <Grid2 xs={12} container justifyContent="center">
                <ModalChangeRewards loot={loot} loot_big={loot_big}/>
            </Grid2>
            <Grid2 xs={12}>
                <Button variant="outlined" sx={{mt: 1}} fullWidth onClick={handleUpdateRaid}>Обновить</Button>
            </Grid2>
        </Grid2>
    );
};

export default RaidPage;