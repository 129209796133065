enum AppStand {
    PROD,
    DEVELOP,
    LOCAL
}

const AppStandUrl = {
    [AppStand.PROD]: process.env.REACT_APP_API_PROD_URL,
    [AppStand.DEVELOP]: process.env.REACT_APP_API_DEVELOP_URL,
    [AppStand.LOCAL]: process.env.REACT_APP_API_LOCAL_URL
}

export function getAppUrl() {
    const url = AppStandUrl[AppStand[process.env.REACT_APP_URL_TYPE as keyof typeof AppStand]]
    if(url)
        return url
    else
        return AppStandUrl[AppStand.LOCAL]
}