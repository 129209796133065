import React from 'react';
import {Box} from "@mui/material";

const PrivacyPolicy = () => {
    return (
        <Box height="100vh" width="100%" overflow="hidden">
            <iframe style={{height: "100%", width: "100%"}} src="/env_pages/privacy_policy.html"></iframe>
        </Box>
    );
};

export default PrivacyPolicy;