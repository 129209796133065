import React, {Dispatch, SetStateAction} from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import {FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {ItemTemplateType} from "../../../types/ItemsTypes";

const ItemCostAndQuality = ({costs, sell, quality, setItem}: {costs: number, sell: number, quality: number, setItem: Dispatch<SetStateAction<ItemTemplateType>>}) => {

    const handleUpdateQuality = (value: number) => {
        setItem(prevState => ({...prevState, quality: value}))
    }

    const handleUpdatePriceBuy = (value: number) => {
        setItem(prevState => ({...prevState, price: value}))
    }

    const handleUpdatePriceSold = (value: number) => {
        setItem(prevState => ({...prevState, price_sell: value}))
    }

    return (
        <Grid2 height="100%" xs={12} container justifyContent="center">
            <Grid2 xs={12} display="flex" alignItems="center">
                <FormControl fullWidth size="small" variant="standard">
                    <InputLabel>Качество</InputLabel>
                    <Select label="Качество" value={quality} onChange={(e) => handleUpdateQuality(Number(e.target.value))}>
                        <MenuItem value="0">Bad Quality</MenuItem>
                        <MenuItem value="1">Regular Quality</MenuItem>
                        <MenuItem value="2">Good Quality</MenuItem>
                        <MenuItem value="3">Excellent Quality</MenuItem>
                    </Select>
                </FormControl>
            </Grid2>
            <Grid2 xs={12}>
                <TextField
                    sx={{mb: -2}}
                    variant="standard"
                    size="small"
                    type="number"
                    label="Цена покупки"
                    value={costs}
                    onChange={(e) => handleUpdatePriceBuy(Number(e.target.value))}
                /> {/* todo проблема */}
            </Grid2>
            <Grid2 xs={12}>
                <TextField
                    variant="standard"
                    size="small"
                    type="number"
                    label="Цена Продажи"
                    value={sell}
                    onChange={(e) => handleUpdatePriceSold(Number(e.target.value))}
                />
            </Grid2>
        </Grid2>
    );
};

export default ItemCostAndQuality;