import React, {useReducer} from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import {useAdminState} from "../../../providers/AdminStateProvider";
import {Button, Checkbox, FormControlLabel, Paper} from "@mui/material";
import {useRequesting} from "../../../../../app/providers/RequestingProvider";

const PoolNames = () => {
    const adminState = useAdminState()!!
    const names = adminState.getters.getPoolAgents()?.pool.names
    const request = useRequesting()?.authorizeRequest!!
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const handleUpdate = (id: number) => {
        if(names){
            request.post(`admin/pools/names/${id}/update`, {}, names[id])
        }
    }

    const handleChangeMale = (id: number) => {
       if(names){
           names[id].male = !names[id].male
           forceUpdate()
       }
    }

    //todo Change card for separate item

    return (
        <>
            <Grid2 xs={12} container>

            </Grid2>
            {names?.map((it, key) => <Paper key={key} sx={{m: 1, minHeight: "30px"}}>
                <Grid2 xs={12} container>
                    <Grid2 xs={2} alignItems="center" justifyContent="center" display="flex" >{it.name}</Grid2>
                    <Grid2 xs={4}>
                        <FormControlLabel control={<Checkbox checked={it.male} onClick={() => handleChangeMale(key)}/>} label="Is Male"/>
                    </Grid2>
                    <Grid2 xs={6}>
                        <Button variant="outlined" onClick={() => handleUpdate(key)}>Обновить</Button>
                    </Grid2>
                </Grid2>
            </Paper>)}
        </>
    );
};

export default PoolNames;