import React, {useEffect, useState} from 'react';
import {useAdminState} from "../../../../providers/AdminStateProvider";
import {Button, FormControl, InputLabel, MenuItem, Paper, Select, TextField} from "@mui/material";
import {PresetType} from "../../../../types/PoolAgentsTypes";
import Grid2 from "@mui/material/Unstable_Grid2";
import {useRequesting} from "../../../../../../app/providers/RequestingProvider";

const CardPreset = ({id, modal}: {id: number, modal: (id: number, preset: PresetType) => void}) => {
    const adminState = useAdminState()!!
    const pools = adminState.getters.getPoolAgents()!!.pool
    const [preset, setPreset] = useState<PresetType>(pools.presets[id])
    const request = useRequesting()?.authorizeRequest!!

    const handleUpdatePreset = (id: number) => {
        request.post(`admin/pools/presets/${id}/update`, {}, ({
            ...preset,
            items: pools.presets[id].items,
            abilities: pools.presets[id].abilities,
            attributes: pools.presets[id].attributes,
            reputation_government: pools.presets[id].reputation_government,
            reputation_opposition: pools.presets[id].reputation_opposition
        }))
    }

    return (
        <Paper sx={{m:1, p:1}}>
            <Grid2 xs={12} container spacing={1} alignItems="center">
                <Grid2 xs={3}>
                    <FormControl fullWidth size="small" variant="standard">
                        <InputLabel>Имя</InputLabel>
                        <Select
                            label="Имя"
                            defaultValue={preset.name}
                            onChange={(e) => setPreset(prevState => ({...prevState, name: e.target.value as number}))}
                        >
                            {pools.names.map((it, key) => <MenuItem key={key} value={key}>{it.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid2>
                <Grid2 xs={3}>
                    <FormControl fullWidth size="small" variant="standard">
                        <InputLabel>Место</InputLabel>
                        <Select
                            label="Место"
                            defaultValue={preset.birth_place!!}
                            onChange={(e) => setPreset(prevState => ({...prevState, birth_place: e.target.value as number}))}
                        >
                            {pools.birth_places.map((it, key) => <MenuItem key={key} value={key}>{it.title}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid2>
                <Grid2 xs={3}>
                    <TextField
                        size="small"
                        type="number"
                        label="Возраст"
                        value={preset.age}
                        onChange={(e) => setPreset(prevState => ({...prevState, age: parseInt(e.target.value)}))}
                    />
                </Grid2>
                <Grid2 xs={3}>
                    <FormControl fullWidth size="small" variant="standard">
                        <InputLabel>Биография</InputLabel>
                        <Select
                            label="Биография"
                            defaultValue={preset.bio}
                            onChange={(e) => setPreset(prevState => ({...prevState, bio: e.target.value as number}))}
                        >
                            {pools.bios.map((it, key) => <MenuItem key={key} value={key}>{it.short_desc}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid2>
            </Grid2>
            <Grid2 xs={12} container mt={1} alignItems="center">
                <Grid2 xs={3}>
                    <FormControl fullWidth size="small" variant="standard">
                        <InputLabel>Тип</InputLabel>
                        <Select
                            label="Тип"
                            defaultValue={preset.special_type}
                            onChange={(e) => setPreset(prevState => ({...prevState, special_type: e.target.value}))}
                        >
                            <MenuItem value="default">Обычный персонаж из пула</MenuItem>
                            <MenuItem value="purchasable">Покупной персонаж</MenuItem>
                            <MenuItem value="hero_gov_1">Герой Правительство №1</MenuItem>
                            <MenuItem value="hero_gov_2">Герой Правительство №2</MenuItem>
                            <MenuItem value="hero_opp_1">Герой Оппозиция №1</MenuItem>
                            <MenuItem value="hero_opp_2">Герой Оппозиция №2</MenuItem>
                            <MenuItem value="agent_ptenec">Помощник Птенец</MenuItem>
                            <MenuItem value="agent_kostolom">Помощник Костолом</MenuItem>
                            <MenuItem value="agent_kenob">Помощник Кеноб</MenuItem>
                        </Select>
                    </FormControl>
                </Grid2>
                <Grid2 xs={3}>
                    {preset.special_type !== "default" && <Button variant="outlined" size="small" onClick={() => modal(id, preset)}>Параметры</Button>}
                </Grid2>
                <Grid2 xs={3}>
                    <Button variant="outlined" size="small" onClick={() => handleUpdatePreset(id)}>Сохранить</Button>
                </Grid2>
                <Grid2 xs={3}>
                    <Button variant="outlined" size="small" disabled>Удалить</Button>
                </Grid2>
            </Grid2>
        </Paper>
    );
};

export default CardPreset;