import React, {useEffect, useState} from 'react';
import {Box, Button, Modal, Typography} from "@mui/material";
import {useSizeableContext} from "../../../../app/providers/SizeableProvider";
import TableAccounts from "../../../admin/main_components/tables/ui/TableAccounts";

const ModalSelectionAccount = () => {
    const [isOpen, setIsOpen] = useState(false)
    const sizeableProvider = useSizeableContext()

    const handleOpen = () => {
        setIsOpen(true)
    }

    const handleClose = () => {
        setIsOpen(false)
    }

    return (
        <>
            <Button onClick={handleOpen}>Выберите аккаунт</Button>
            <Modal
                open={isOpen}
                onClose={handleClose}
            >
                <Box
                    position="absolute"
                    top="50%"
                    left="50%"
                    width={sizeableProvider.isSmall ? "80%" : "60%"}
                    bgcolor="background.default"
                    boxShadow={24}
                    p={4}
                    sx={{
                        transform: "translate(-50%, -50%)",
                        color: "text.disabled"
                    }}
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                >
                        <Typography>
                            Выберите аккаунт
                        </Typography>
                    <TableAccounts setIsModal={setIsOpen}/>
                </Box>
            </Modal>
        </>
    );
};

export default ModalSelectionAccount;