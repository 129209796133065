import React, {useEffect, useState} from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import {Box, Button, Typography} from "@mui/material";
import {useRequesting} from "../../../../../app/providers/RequestingProvider";
import {useAdminState} from "../../../providers/AdminStateProvider";
import {DataGrid, GridColDef, GridRowId} from "@mui/x-data-grid";
import {useTabs} from "../../../providers/TabsProvider";
import RaidPvpDetails from "../../../addable_components/RaidPvpDetails";

type AccountsPVPType = {
    id: string,
    type: string,
    attacker: string,
    defender: string,
    attack_time: number,
    process_time: number,
    status: string,
    result: string,
    actions: React.JSX.Element
}

const AccountPvp = () => {
    const tabsProvider = useTabs()
    const request = useRequesting()?.authorizeRequest!!
    const adminState = useAdminState()!!
    const account = adminState.getters.getAccount()
    const [raids, setRaids] = useState<AccountsPVPType[] | undefined>(undefined)

    const handleReveal = (id: string) => {
        request.post(`admin/pvp/${id}/force_reveal`).then(adminState.refreshers.refreshAccount)
    }

    const handleForceCalculate = (id: string) => {
        request.post(`admin/pvp/${id}/force_calculate`).then(adminState.refreshers.refreshAccount)
    }

    const openRaid = (id: string) => {
        tabsProvider.openTab(id, {
            name: id,
            path: id,
            component: <RaidPvpDetails pvpId={id}/>
        })
    }

    const columns: GridColDef[] = [
        {field: "id", headerName: "ID", width: 250, renderCell: (params) => <Button onClick={() => openRaid(params.id.toString())}>{params.id.toString()}</Button>},
        {field: "type", headerName: "Тип"},
        {field: "attacker", headerName: "Атака"},
        {field: "defender", headerName: "Защита"},
        {field: "attack_time", headerName: "Начало", width: 150},
        {field: "process_time", headerName: "Конец", width: 150},
        {field: "status", headerName: "Статус"},
        {field: "result", headerName: "Результат"},
        {field: "actions", headerName: "Действия", width: 220, renderCell: (params) =>
                <Box>
                    <Button size="small" onClick={() => handleReveal(params.id.toString())}>Обнаружить</Button>
                    <Button size="small" onClick={() => handleForceCalculate(params.id.toString())}>Рассчитать</Button>
                </Box>
        }
    ]

    useEffect(() => {
        request.get(`admin/accounts/${account?.selectedAcc}/pvp`)
            .then((res) => res.json())
            .then((res) => {
                const result = res.map((it: any) => ({...it, process_time: new Date(it.process_time).toString(), attack_time: new Date(it.attack_time).toString(),
                    actions: <Box>
                        <Button>Обнаружить</Button>
                        <Button>Рассчитать</Button>
                    </Box>
                }))
                setRaids(result)
            })
    }, [account?.selectedAcc])

    return (
        <Grid2 xs={12} container>
            <Grid2 xs={12}>
                <Typography variant="h5">ПВП</Typography>
                <DataGrid
                    sx={{width: "100%", color: "text.disabled"}}
                    columns={columns}
                    rows={raids ? raids : []}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 }
                        }
                    }}
                    pageSizeOptions={[10]}
                />
            </Grid2>
        </Grid2>
    );
};

export default AccountPvp;