import React, {useState} from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import ItemName from "./items_content/ItemName";
import ItemImage from "./items_content/ItemImage";
import ItemTypes from "./items_content/ItemTypes";
import ItemAddableParams from "./items_content/ItemAddableParams";
import ItemCostAndQuality from "./items_content/ItemCostAndQuality";
import ItemBelonging from "./items_content/ItemBelonging";
import ItemActions from "./items_content/ItemActions";
import {ItemTemplateType} from "../../types/ItemsTypes";
import {useRequesting} from "../../../../app/providers/RequestingProvider";

const ItemPaper = ({initialItem}: {initialItem: ItemTemplateType}) => {
    const [item, setItem] = useState<ItemTemplateType>(initialItem)
    const request = useRequesting()?.authorizeRequest!!

    const handleUpdateItem = () => {
        request.post(`admin/item_templates/${item.id}/update`, {}, item)
    }

    return (
        <>
            <Grid2 xs={12} container spacing={1}>
                <Grid2 xs={2}>
                    <ItemName name={item.title}/>
                    <ItemImage image={item.image}/>
                </Grid2>
                <Grid2 xs={2}>
                    <ItemTypes type={item.type} subtype={item.subtype} uniqueText={item.unique_text} setItem={setItem}/>
                </Grid2>
                <Grid2 xs={2}>
                    <ItemAddableParams affixes={item.affixes} requiredAttributes={item.required_attributes}/>
                </Grid2>
                <Grid2 xs={2}>
                    <ItemCostAndQuality costs={item.price} sell={item.price_sell} quality={item.quality} setItem={setItem}/>
                </Grid2>
                <Grid2 xs={2}>
                    <ItemBelonging belonging={item.fractions} setItem={setItem}/>
                </Grid2>
                <Grid2 xs={2}>
                    <ItemActions update={handleUpdateItem}/>
                </Grid2>
            </Grid2>
        </>
    );
};

export default ItemPaper;