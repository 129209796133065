import React, {useEffect, useState} from 'react';
import {Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {RewardType} from "../../../../pages/admin/addable_pages/RaidPage";
import RaidReward from "../../ui/RaidReward";

enum RewardsLootType {
    loot,
    loot_big
}

const ModalChangeRewards = ({loot, loot_big}: {loot: RewardType[], loot_big: RewardType[]}) => {
    const [open, setOpen] = useState(false)
    const [common, setCommon] = useState<{
        header: string,
        type: RewardsLootType
    } | undefined>(undefined)

    const handleOpen = (header: string, type: RewardsLootType) => {
        setCommon(prevState => ({...prevState, header: header, type: type}))
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        console.log(loot)
        console.log(loot_big)
    }, [loot, loot_big])

    return (
        <>
            <Button variant="outlined" onClick={() => handleOpen("Награда за частичный успех", RewardsLootType.loot)}>Награда за частичный успех</Button>
            <Button variant="outlined" onClick={() => handleOpen("Награда за победу", RewardsLootType.loot_big)}>Награда за победу</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: "80%",
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                    color: "text.primary"
                }}>
                    <Grid2 xs={12} container>
                        <Grid2 xs={12}>
                            <Typography textAlign="center" variant="h5">{common?.header}</Typography>
                        </Grid2>
                        <Grid2 xs={12} container spacing={2} justifyContent="center">
                            {
                                (common?.type === RewardsLootType.loot ? loot : loot_big)?.map((it, key) => <RaidReward key={key} reward={it}/>)
                            }
                            <Button variant="outlined" sx={{mt: 1}}>Добавить</Button>
                        </Grid2>
                    </Grid2>
                </Box>
            </Modal>
        </>
    );
};

export default ModalChangeRewards;