import React from 'react';
import {Box} from "@mui/material";

const UkassaPage = () => {
    return (
        <Box height="100vh" width="100%" overflow="hidden">
            <iframe style={{height: "100%", width: "100%"}} src="/ukassa/email.html"></iframe>
        </Box>
    );
};

export default UkassaPage;