import React, {createContext, Dispatch, SetStateAction, useContext, useState} from 'react';
import {useRequesting} from "../../../app/providers/RequestingProvider";
import {AccountListCommon, AccountStateType} from "../types/AccountTypes";
import {ItemTemplateType} from "../types/ItemsTypes";
import {RaidType} from "../types/RaidsTypes";
import {IapType} from "../types/IapTypes";
import {BalanceType} from "../types/BalanceTypes";
import {PoolAgentsType} from "../types/PoolAgentsTypes";
import {PurchaseType} from "../types/PurchaseTypes";

type AdminStateContext = {
    adminState: AdminStateType
    dispatch: Dispatch<AdminStateAction>
    getters: GettersStateType
    refreshers: RefreshersStateType
    setters: SettersStateType
}

type SettersStateType = {
    setComponentAccount: (val: string) => void,
    setComponentPoolAgents: (val: string) => void
}

type RefreshersStateType = {
    refreshAccount: () => void
    refreshBalance: () => void
}

type GettersStateType = {
    getAccount: () => AccountStateType | undefined
    getAccounts: () => AccountListCommon | undefined
    getItems: () => ItemTemplateType[] | undefined
    getRaids: () => RaidType[] | undefined
    getIaps: () => IapType[] | undefined
    getBalance: () => BalanceType | undefined
    getPoolAgents: () => PoolAgentsType | undefined
    getPurchaseIds: () => PurchaseType[] | undefined
}

const AdminStateContext = createContext<AdminStateContext | null>(null)

export enum AdminStateTypeActions {
    GetAccount,
    GetAccounts,
    GetItems,
    GetRaids,
    GetIaps,
    GetBalance,
    GetPoolAgents,
    GetPurchaseIds
}

type AdminStateAction = {
    type: AdminStateTypeActions,
    payload?: any
}

type AdminStateType = {
    accountState: AccountStateType | undefined
    accounts: AccountListCommon | undefined
    itemState: ItemTemplateType[] | undefined
    raids: RaidType[] | undefined
    iaps: IapType[] | undefined
    balance: BalanceType | undefined
    poolAgents: PoolAgentsType | undefined
    purchaseIds: PurchaseType[] | undefined
}

export const AdminStateProvider = ({children}: {children: any}) => {
    const request = useRequesting()?.authorizeRequest!!

    const AdminStateActions = {
        [AdminStateTypeActions.GetAccounts]: (setter: Dispatch<SetStateAction<AdminStateType>>, payload: undefined) => {
            request.get("admin/accounts_list?count=90000")
                .then((res) => res.json())
                .then((res) => {
                    setter(prevState => ({...prevState, accounts: res}))
                })
        },
        [AdminStateTypeActions.GetAccount]: (setter: Dispatch<SetStateAction<AdminStateType>>, payload: any) => {
            request.get(`admin/accounts/${payload.toString()}/details`)
                .then((res) => res.json())
                .then((res) => setter(prevState => ({...prevState,
                    accountState: {
                        ...prevState.accountState,
                        selectedAcc: payload.toString(),
                        accountDetail: res
                    }})))
        },
        [AdminStateTypeActions.GetItems]: (setter: Dispatch<SetStateAction<AdminStateType>>, payload: undefined) => {
            request.get(`admin/item_templates`)
                .then((res) => res.json())
                .then((res) => {
                    setter(prev => ({...prev, itemState: res.items}))
                })
        },
        [AdminStateTypeActions.GetRaids]: (setter: Dispatch<SetStateAction<AdminStateType>>, payload: undefined) => {
            request.get(`/admin/raids`)
                .then((res) => res.json())
                .then((res) => {
                    setter(prevState => ({...prevState, raids: res.raids}))
                })
        },
        [AdminStateTypeActions.GetIaps]: (setter: Dispatch<SetStateAction<AdminStateType>>, payload: undefined) => {
            request.get(`admin/iap/entries`)
                .then((res) => res.json())
                .then((res) => setter(prevState => ({...prevState, iaps: res})))
        },
        [AdminStateTypeActions.GetBalance]: (setter: Dispatch<SetStateAction<AdminStateType>>, payload: undefined) => {
            request.get(`admin/balance`)
                .then((res) => res.json())
                .then((res) => setter(prevState => ({...prevState, balance: res})))
        },
        [AdminStateTypeActions.GetPoolAgents]: (setter: Dispatch<SetStateAction<AdminStateType>>, payload: undefined) => {
            request.get(`admin/pools`)
                .then((res) => res.json())
                .then((res) => setter(prevState => ({...prevState, poolAgents: ({...prevState.poolAgents, pool: res})})))
        },
        [AdminStateTypeActions.GetPurchaseIds]: (setter: Dispatch<SetStateAction<AdminStateType>>, payload: undefined) => {
            request.get(`admin/purchases/ids`)
            // .then((res) => res.json())
            // .then((res) => setter(prevState => ({...prevState, purchaseIds: res})))
        }
    }

    const initialState: AdminStateType = {
        accountState: undefined,
        accounts: undefined,
        itemState: undefined,
        raids: undefined,
        iaps: undefined,
        balance: undefined,
        poolAgents: undefined,
        purchaseIds: undefined
    }

    const [adminState, setAdminState] = useState<AdminStateType>(initialState)

    const dispatch = (action: AdminStateAction) => {
        const {type, payload} = action
        AdminStateActions[type](setAdminState, payload)
    }

    const refreshers: RefreshersStateType = {
        refreshAccount: () => {
            dispatch({
                type: AdminStateTypeActions.GetAccount,
                payload: adminState.accountState?.selectedAcc
            })
        },
        refreshBalance: () => {
            dispatch({
                type: AdminStateTypeActions.GetBalance
            })
        }
    }

    const getters: GettersStateType = {
        getAccount: () => {
            return adminState.accountState
        },
        getAccounts: () => {
            if (!adminState.accounts)
                dispatch({
                    type: AdminStateTypeActions.GetAccounts
                })
            return adminState.accounts
        },
        getItems: () => {
            if(!adminState.itemState)
                dispatch({
                    type: AdminStateTypeActions.GetItems
                })
            return adminState.itemState
        },
        getRaids: () => {
            if(!adminState.raids)
                dispatch({
                    type: AdminStateTypeActions.GetRaids
                })
            return adminState.raids
        },
        getIaps: () => {
            if(!adminState.iaps)
                dispatch({
                    type: AdminStateTypeActions.GetIaps
                })
            return adminState.iaps
        },
        getBalance: () => {
            if(!adminState.balance)
                dispatch({
                    type: AdminStateTypeActions.GetBalance
                })
            return adminState.balance
        },
        getPoolAgents: () => {
            if(!adminState.poolAgents)
                dispatch({
                    type: AdminStateTypeActions.GetPoolAgents
                })
            return adminState.poolAgents
        },
        getPurchaseIds: () => {
            // if(!adminState.purchaseIds)
            //     dispatch({
            //         type: AdminStateTypeActions.GetPurchaseIds
            //     })
            return adminState.purchaseIds
        }
    }

    const setters: SettersStateType = {
        setComponentAccount: (val: string) => {
            setAdminState(prevState => ({...prevState, accountState: ({...prevState.accountState, openedComponent: val} as AccountStateType)}))
        },
        setComponentPoolAgents: (val: string) => {
            setAdminState(prevState => ({...prevState, poolAgents: ({...prevState.poolAgents, openedComponent: val} as PoolAgentsType)}))
        }
    }

    return (
        <AdminStateContext.Provider
            value={{
                adminState,
                dispatch,
                getters,
                refreshers,
                setters
            }}
        >
            {children}
        </AdminStateContext.Provider>
    );
};

export const useAdminState = () => useContext(AdminStateContext)

