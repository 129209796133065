import React, {useEffect, useState} from 'react';
import {useRequesting} from "../../../app/providers/RequestingProvider";
import {AgentAbilityType, AgentType} from "../main_components/content/accounts_content/AccountAgents";
import Grid2 from "@mui/material/Unstable_Grid2";
import {Divider, Typography} from "@mui/material";

type RaidDetailType = {
    accounts: string[]
    agents: AgentType[]
    difficulty: string
    start_time: number
    end_time: number
    id: string
    meta?: RaidDetailMetaType
    name: string
    personageCount: number
    state: string
    type: string
}

type RaidDetailMetaType = {
    agent_parameters: RaidDetailMetaAgentType[]
    fight: {
        difficulty: number
        pb: number
        resultDistribution: number[]
        team_power: number
        watk: number
        wstl: number
        wsur:number
    }
    full_power: number
    result: string
    pb: number
}

type RaidDetailMetaAgentType = {
    agent: RaidDetailMetaAgentParam
    items: {image: string, name: string}[]
    score: RaidDetailMetaAgentScoreType
    max_money: RaidDetailMetaAgentScoreApproachType
    min_money: RaidDetailMetaAgentScoreApproachType
    money: RaidDetailMetaAgentScoreApproachType
    gain_attack: RaidDetailMetaAgentScoreApproachType
    gain_defence: RaidDetailMetaAgentScoreApproachType
    gain_stealth: RaidDetailMetaAgentScoreApproachType
    gain_survival: RaidDetailMetaAgentScoreApproachType
    individualRoll: string
    reward_items: {
        template_data: {
            uniqueId: string
            image: string
        }[]
    }[]
}

type RaidDetailMetaAgentScoreType = {
    agent: RaidDetailMetaAgentParam
    attack: RaidDetailMetaAgentScoreApproachType
    defence: RaidDetailMetaAgentScoreApproachType
    stealth: RaidDetailMetaAgentScoreApproachType
    survival: RaidDetailMetaAgentScoreApproachType
    value: number
    damage: {
        item: {template_data: {uniqueId?: string, name: string, image: string}[]},
        damage: RaidDetailMetaAgentScoreApproachType
    }[]

}

type RaidDetailMetaAgentScoreApproachType = {
    base: number,
    percent_bonuses: RaidDetailMetaPercentScoreBonus[],
    scale_bonuses: RaidDetailMetaPercentScoreBonus[]
    total_value: number
}

type RaidDetailMetaPercentScoreBonus = {
    percent: number,
    label: string
}

type RaidDetailMetaAgentParam = {
    abilities: AgentAbilityType[]
    uniqueName?: string
    name: string
}

const RaidCommonDetails = ({raidId}: {raidId: string}) => {
    const request = useRequesting()!!.authorizeRequest!!
    const [raid, setRaid] = useState<undefined | RaidDetailType>(undefined)

    useEffect(() => {
        request.get(`admin/raid_instances/${raidId}`)
            .then((res) => res.json())
            .then((res) => setRaid(res))
    }, [])

    return (
        <Grid2 xs={12} container justifyContent="center">
            {raid &&
                <>
                    <Grid2 xs={12} container spacing={1} columnSpacing={1} alignItems="center">
                        <Grid2 xs={12}>
                            <Typography variant="h5" textAlign="center">Рейд на - {raid.name}</Typography>
                        </Grid2>

                        <Divider sx={{width: "100%", borderColor: "black", m:1}}/>

                        <Grid2 xs={6} display="flex" justifyContent="center">
                            Старт рейда - {raid.start_time}
                        </Grid2>
                        <Grid2 xs={6} display="flex" justifyContent="center">
                            Конец рейда - {raid.end_time}
                        </Grid2>

                        <Divider sx={{width: "100%", borderColor: "black", m:1}}/>

                        <Grid2 xs={12} container justifyContent="center" spacing={1} >
                            <Grid2 xs={12} display="flex" justifyContent="center">
                                Агенты
                            </Grid2>
                            {raid.meta?.agent_parameters.map((it, key) => <Grid2
                                key={key}
                                xs={12}
                                container
                                justifyContent="center"
                                sx={{p:1, backgroundColor: "gray", m:1}}
                            >
                                <Grid2 xs={12}>
                                    <Typography textAlign="center">Имя - {it.agent.uniqueName ? it.agent.uniqueName : raid.agents[key].name_original}</Typography>
                                </Grid2>

                                <Divider sx={{width: "100%", borderColor: "black", m:1}}/>

                                <Grid2 container justifyContent="center" xs={12}>
                                    <Grid2 xs={12}>
                                        <Typography textAlign="center">Способности:</Typography>
                                    </Grid2>
                                    {it.agent.abilities.map((ability, key) => <Grid2 key={key} xs={3} display="flex" justifyContent="center">
                                        {ability.ability}
                                    </Grid2>)}
                                </Grid2>

                                <Divider sx={{width: "100%", borderColor: "black", m:1}}/>

                                <Grid2 xs={12} container justifyContent="center" spacing={1}>
                                    <Grid2 xs={12}>
                                        <Typography textAlign="center">Предметы:</Typography>
                                    </Grid2>
                                    {it.items.map((item, key) => <Grid2 xs={3} display="flex" justifyContent="center">
                                        <img src={`/images/uploaded/${item.image}`} width="30%"/>
                                    </Grid2>)}
                                </Grid2>

                                <Divider sx={{width: "100%", borderColor: "black", m:1}}/>

                                <Grid2 xs={12} container justifyContent="center" spacing={1} columnSpacing={1}>
                                    <Grid2 xs={12}>
                                        <Typography textAlign="center">Score:</Typography>
                                    </Grid2>
                                    <Grid2 xs={6}>
                                        <Grid2 xs={1}>
                                            Атака
                                        </Grid2>
                                        <Grid2 xs={11}>
                                            <Grid2 xs={12}>
                                                База: {it.score.attack.base}
                                            </Grid2>
                                            <Grid2 xs={12}>
                                                Процент: {it.score.attack.percent_bonuses.map((it) => `${it.label}: ${it.percent}\n`)}
                                            </Grid2>
                                            <Grid2 xs={12}>
                                                Итого: {it.score.attack.total_value}
                                            </Grid2>
                                        </Grid2>
                                    </Grid2>
                                    <Grid2 xs={6}>
                                        <Grid2 xs={1}>
                                            Защита
                                        </Grid2>
                                        <Grid2 xs={11}>
                                            <Grid2 xs={12}>
                                                База: {it.score.defence.base}
                                            </Grid2>
                                            <Grid2 xs={12}>
                                                Процент: {it.score.defence.percent_bonuses.map((it) => `${it.label}: ${it.percent}\n`)}
                                            </Grid2>
                                            <Grid2 xs={12}>
                                                Итого: {it.score.defence.total_value}
                                            </Grid2>
                                        </Grid2>
                                    </Grid2>
                                    <Grid2 xs={6}>
                                        <Grid2 xs={1}>
                                            Выживание
                                        </Grid2>
                                        <Grid2 xs={11}>
                                            <Grid2 xs={12}>
                                                База: {it.score.survival.base}
                                            </Grid2>
                                            <Grid2 xs={12}>
                                                Процент: {it.score.survival.percent_bonuses.map((it) => `${it.label}: ${it.percent}\n`)}
                                            </Grid2>
                                            <Grid2 xs={12}>
                                                Итого: {it.score.survival.total_value}
                                            </Grid2>
                                        </Grid2>
                                    </Grid2>
                                    <Grid2 xs={6}>
                                        <Grid2 xs={1}>
                                            Скрытность
                                        </Grid2>
                                        <Grid2 xs={11}>
                                            <Grid2 xs={12}>
                                                База: {it.score.stealth.base}
                                            </Grid2>
                                            <Grid2 xs={12}>
                                                Процент: {it.score.stealth.percent_bonuses.map((it) => `${it.label}: ${it.percent}\n`)}
                                            </Grid2>
                                            <Grid2 xs={12}>
                                                Итого: {it.score.stealth.total_value}
                                            </Grid2>
                                        </Grid2>
                                    </Grid2>
                                    <Grid2 xs={12}>
                                        <Grid2 xs={1}>
                                            Урон
                                        </Grid2>
                                        <Grid2 xs={11}>
                                            <Grid2 xs={12} container>
                                                {it.score.damage.map((dmg, key) => <Grid2 key={key} xs={4}>
                                                    <Grid2 xs={6}>{dmg.item.template_data[0]?.uniqueId ? dmg.item.template_data[0]?.uniqueId : dmg.item.template_data[0]?.image?.slice(dmg.item.template_data[key].image.length - 4)} база : {dmg.damage.base} |</Grid2>
                                                    <Grid2 xs={6}>{dmg.item.template_data[0]?.uniqueId ? dmg.item.template_data[0]?.uniqueId : dmg.item.template_data[0]?.image?.slice(dmg.item.template_data[key].image.length - 4)} итого : {dmg.damage.total_value} |</Grid2>
                                                </Grid2>)}
                                            </Grid2>
                                        </Grid2>
                                    </Grid2>
                                </Grid2>
                            </Grid2>)}
                            <Grid2 xs={12} container justifyContent="center" textAlign="center">
                                <Grid2 xs={12}>Крутость: {raid.meta?.full_power}</Grid2>
                                <Grid2 xs={12}>Сложность рейда: {raid.meta?.fight.difficulty}</Grid2>
                                <Grid2 xs={12}>Сила команды: {raid.meta?.fight.team_power}</Grid2>
                                <Grid2 xs={12}>Pb: {raid.meta?.pb}</Grid2>
                                <Grid2 xs={12}>sv/v/s/r/w/d/sd: {raid.meta?.fight.resultDistribution.map((it) => `${it}/`)}</Grid2>
                                <Grid2 xs={12}>Результат: {raid.meta?.result}</Grid2>
                            </Grid2>
                        </Grid2>
                    </Grid2>


                    <Divider sx={{width: "100%", borderColor: "black", m:1}}/>


                    <Grid2 xs={12}>
                        <Grid2 xs={12} sx={{mb: 1}}><Typography variant="h5" textAlign="center">Результаты</Typography></Grid2>
                        {raid.meta?.agent_parameters.map((agentParam, key) =>
                            <Grid2
                                key={key}
                                xs={12}
                                container
                                justifyContent="center"
                                sx={{backgroundColor: "gray", p:1, m:1}}
                                rowSpacing={3}
                                columnSpacing={1}
                                textAlign='center'
                            >
                                <Grid2 xs={4} container rowSpacing={1}>
                                    <Grid2 xs={12}>Мин деньги</Grid2>
                                    <Grid2 xs={12} container flexDirection='column'>
                                        <Grid2 xs={12}>База: {agentParam.min_money.base}</Grid2>
                                        <Grid2 xs={12}>{agentParam.min_money.percent_bonuses[0]?.label}: {agentParam.min_money.percent_bonuses[0]?.percent}</Grid2>
                                        <Grid2 xs={12}>{agentParam.min_money.scale_bonuses[0]?.label}: {agentParam.min_money.scale_bonuses[0]?.percent}</Grid2>
                                        <Grid2 xs={12}>Итого: {agentParam.min_money.total_value}</Grid2>
                                    </Grid2>
                                </Grid2>
                                <Grid2 xs={4} container rowSpacing={1}>
                                    <Grid2 xs={12}>Макс деньги</Grid2>
                                    <Grid2 xs={12} container flexDirection='column'>
                                        <Grid2 xs={12}>База: {agentParam.max_money.base}</Grid2>
                                        <Grid2 xs={12}>{agentParam.max_money.percent_bonuses[0]?.label}: {agentParam.max_money.percent_bonuses[0]?.percent}</Grid2>
                                        <Grid2 xs={12}>{agentParam.max_money.scale_bonuses[0]?.label}: {agentParam.max_money.scale_bonuses[0]?.percent}</Grid2>
                                        <Grid2 xs={12}>Итого: {agentParam.max_money.total_value}</Grid2>
                                    </Grid2>
                                </Grid2>
                                <Grid2 xs={4} container rowSpacing={1}>
                                    <Grid2 xs={12}>Деньги</Grid2>
                                    <Grid2 xs={12} container flexDirection='column'>
                                        <Grid2 xs={12}>База: {agentParam.money.base}</Grid2>
                                        <Grid2 xs={12}>Итого: {agentParam.money.total_value}</Grid2>
                                    </Grid2>
                                </Grid2>
                                <Grid2 xs={4} container rowSpacing={1}>
                                    <Grid2 xs={12}>Атака</Grid2>
                                    <Grid2 xs={12} container flexDirection='column'>
                                        <Grid2 xs={12}>База: {agentParam.gain_attack.base}</Grid2>
                                        <Grid2 xs={12}>Итого: {agentParam.gain_attack.total_value}</Grid2>
                                    </Grid2>
                                </Grid2>
                                <Grid2 xs={4} container rowSpacing={1}>
                                    <Grid2 xs={12}>Защита</Grid2>
                                    <Grid2 xs={12} container flexDirection='column'>
                                        <Grid2 xs={12}>База: {agentParam.gain_defence.base}</Grid2>
                                        <Grid2 xs={12}>Итого: {agentParam.gain_defence.total_value}</Grid2>
                                    </Grid2>
                                </Grid2>
                                <Grid2 xs={4} container rowSpacing={1}>
                                    <Grid2 xs={12}>Выживание</Grid2>
                                    <Grid2 xs={12} container flexDirection='column'>
                                        <Grid2 xs={12}>База: {agentParam.gain_survival.base}</Grid2>
                                        <Grid2 xs={12}>Итого: {agentParam.gain_survival.total_value}</Grid2>
                                    </Grid2>
                                </Grid2>
                                <Grid2 xs={4} container rowSpacing={1}>
                                    <Grid2 xs={12}>Скрытность</Grid2>
                                    <Grid2 xs={12} container flexDirection='column'>
                                        <Grid2 xs={12}>База: {agentParam.gain_stealth.base}</Grid2>
                                        <Grid2 xs={12}>Итого: {agentParam.gain_stealth.total_value}</Grid2>
                                    </Grid2>
                                </Grid2>
                                <Grid2 xs={4}>
                                    <Grid2 xs={12}>Способности</Grid2>
                                    <Grid2 xs={12} container flexDirection='column'>

                                    </Grid2>
                                </Grid2>
                                <Divider sx={{width: "100%", borderColor: "black"}}/>
                                <Grid2 xs={12} container>
                                    <Grid2 xs={12}>Предметы</Grid2>
                                    <Grid2 xs={12} container>
                                        {agentParam.reward_items.map((item, key) => <Grid2 key={key} xs={3} justifyContent="center">
                                            <img width="50%" src={`/images/uploaded/${item.template_data[0]?.image}`}/>
                                        </Grid2>)}
                                    </Grid2>
                                </Grid2>
                                <Grid2 xs={12}>
                                    <Grid2 xs={12}>Инд. ролл: {agentParam.individualRoll}</Grid2>
                                    <Grid2 xs={12}>Триггеры: </Grid2>
                                </Grid2>
                            </Grid2>)}
                    </Grid2>
                </>
            }
        </Grid2>
    );
};

export default RaidCommonDetails;