import React from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import {IconButton} from "@mui/material";

const ItemImage = ({image}: {image: string}) => {
    return (
        <>
            <IconButton>
                <img width="100%" src={`/images/uploaded/${image}`}/>
            </IconButton>
        </>
    );
};

export default ItemImage;