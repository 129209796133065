import React, {useState} from 'react';
import {Box, Button, Collapse, Icon, IconButton, List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';
import {
    ItemListContent,
    listAdminBarItems
} from "../../../entities/admin/main_components/constants/AdminLayoutConstants";
import DehazeOutlinedIcon from '@mui/icons-material/DehazeOutlined';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {useTabs} from "../../admin/providers/TabsProvider";

const LeftAppBar = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const buttonListData: ItemListContent[] = listAdminBarItems
    const tabsProvider = useTabs()

    const getWidth = () => {
        return isOpen ? 250 : 55
    }

    const CollapsableItem = ({data, layer}: { data: ItemListContent, layer: number }) => {
        const [isOpenData, setIsOpenData] = useState<boolean>(false)

        const openChildren = () => {
            if(!isOpen)
                setIsOpen(true)
            setIsOpenData(!isOpenData)
        }

        return (
            <>
                <ListItemButton onClick={openChildren}>
                    <ListItemIcon>
                        {data.icon ? data.icon : <DehazeOutlinedIcon/>}
                    </ListItemIcon>
                    <ListItemText>
                        {isOpen ? data.name : undefined}
                    </ListItemText>
                    {isOpen ? isOpenData ? <ExpandLess/> : <ExpandMore/> : undefined}
                </ListItemButton>
                <Collapse in={isOpenData} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {data.children?.data.map((it, id) => it.children
                            ? <CollapsableItem key={layer*100 + id} data={it} layer={layer+1}/>
                            : <UsualItem key={layer*100 + id} data={it} pl={layer*4}/>
                        )}
                    </List>
                </Collapse>
            </>
        )
    }

    const UsualItem = ({data, pl}: {data: ItemListContent, pl?: number}) => <ListItemButton onClick={() => tabsProvider.openTab(data.path)} sx={{pl: pl}}>
        <ListItemIcon>
            {data.icon ? data.icon : <LayersOutlinedIcon/>}
        </ListItemIcon>
        <ListItemText>
            {isOpen ? data.name : undefined}
        </ListItemText>
    </ListItemButton>

    const buttonList = buttonListData.map((it, id) => it.children ? <CollapsableItem key={id} data={it} layer={1}/> : <UsualItem key={id} data={it}/>)

    return (
        <Box
            component="nav"
            sx={{bgcolor: "background.default", width: getWidth(), height: "100vh", transition: "1s"}}
            border="1px solid black"
            position="fixed"
            zIndex={1}
        >
            <IconButton
                sx={{
                    position:"fixed",
                    bottom: "50%",
                    rotate: isOpen ? "-270deg" : "270deg",
                    left: getWidth() - 20,
                    transition: "1s",
                    color: "info.main",
                }}
                onClick={() => setIsOpen(!isOpen)}
            >
                <ArrowDropDownCircleOutlinedIcon
                    fontSize="large"
                />
            </IconButton>
            {buttonList}
        </Box>
    );
};

export default LeftAppBar;