import React, {useEffect} from 'react';
import {Box, CircularProgress, Typography} from "@mui/material";
import {useLocation, useSearchParams} from "react-router-dom";
import {useRequesting} from "../../app/providers/RequestingProvider";

const UkassaRedirect = () => {
    const request = useRequesting()?.request!!
    const [searchParams, setSearchParams] = useSearchParams()
    const accountId = searchParams.get("account_id")
    const iapId = searchParams.get("iap_id")
    const email = searchParams.get("email")

    useEffect(() => {
        request.get(`iap/ukassa/email?account_id=${accountId}&iap_id=${iapId}&email=${email}`, {mode: "cors"})
            .then((res) => res.json()).then((res) => window.location.replace(res.payload))
    }, [])

    return (
        <Box display="flex" height="100vh" width="100%" overflow="hidden" justifyContent="center" alignItems="center">
            <CircularProgress/>
            <Typography variant="h5">Redirect to Ukassa</Typography>
        </Box>
    );
};

export default UkassaRedirect;