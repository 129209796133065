import React from 'react';
import {Button} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";

const ItemActions = ({update}: {update: () => void}) => {
    return (
        <Grid2 height="100%" xs={12} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Button onClick={update}>Обновить</Button>
            {/*<Button>Удалить</Button>*/}
        </Grid2>
    );
};

export default ItemActions;