import React from 'react';
import {Button, Typography} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {AgentType} from "../AccountAgents";
import {useRequesting} from "../../../../../../app/providers/RequestingProvider";
import {useAdminState} from "../../../../providers/AdminStateProvider";

const AgentStatus = ({agent}: {agent: AgentType}) => {
    const adminState = useAdminState()!!
    const request = useRequesting()?.authorizeRequest!!

    const reviveAgent = () => {
        request.post(`admin/agents/${agent.id}/ressurect`).then(adminState.refreshers.refreshAccount)
    }

    const healAgent = () => {
        request.post(`admin/agents/${agent.id}/heal`).then(adminState.refreshers.refreshAccount)
    }

    const woundAgent = () => {
        request.post(`admin/agents/${agent.id}/wound`).then(adminState.refreshers.refreshAccount)
    }

    const killAgent = () => {
        request.post(`admin/agents/${agent.id}/kill`).then(adminState.refreshers.refreshAccount)
    }


    return (
        <>
            <Typography mb={1}>Статус</Typography>
            <Grid2 xs={12} mb={1}>{agent.status}</Grid2>
            {
                agent.status === "dead" ?
                    <Grid2 xs={12}>
                        <Button size="small" variant="outlined" onClick={reviveAgent}>Воскресить</Button>
                    </Grid2> : <Grid2 xs={12}>
                        {
                            agent.status === "alive" ?
                                <Grid2 xs={12}>
                                    <Button size="small" fullWidth variant="outlined" onClick={woundAgent}>Ранить</Button>
                                </Grid2> : agent.status === "wound" ?
                                    <Grid2 xs={12}>
                                        <Button size="small" fullWidth variant="outlined" onClick={healAgent}>Вылечить</Button>
                                    </Grid2> : undefined
                        }
                        <Grid2 xs={12}>
                            <Button size="small" fullWidth variant="outlined" onClick={killAgent}>Убить</Button>
                        </Grid2>
                    </Grid2>
            }
        </>
    );
};

export default AgentStatus;