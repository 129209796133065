import React from 'react';
import {Container} from "@mui/material";

const SeparationComponentSample = ({isActive, children}: {isActive: boolean, children: any}) => {
    return (
        <Container sx={{display: isActive ? "" : "none", textAlign: "center"}}>
            {children}
        </Container>
    );
};

export default SeparationComponentSample;