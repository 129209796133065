import React from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import {Button, Divider, Typography} from "@mui/material";

const SubscriptionSubPage = () => {

    return (
        <Grid2 xs={12} container>
            <Grid2 xs={12}>
                <Typography variant="h4">Подписки</Typography>
            </Grid2>
            <Divider sx={{width: "100%", m: 1}}/>
            <Grid2 xs={12} container>
                <Grid2 xs={12}>

                </Grid2>
                <Divider sx={{width: "100%", m: 1}}/>
                <Grid2 xs={12}>
                    <Button variant="outlined">Добавить</Button>
                </Grid2>
            </Grid2>
        </Grid2>
    );
};

export default SubscriptionSubPage;