import React from 'react'
import {isBrowser, isMobile} from 'react-device-detect';
import classnames from 'classnames';
import img from '../../../../assets/hgp.svg'

export const HGP = (props) => {
    return (
        <a className={classnames({
            button: true,
            hgp: true,
            mobile: isMobile,
            browser: isBrowser
        })} target="_blank" href='http://htp.kg/' rel="noreferrer">
            <img src={img} alt="high technology pack"/>
        </a>
    );
}