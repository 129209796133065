import React from 'react'
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect'
import classnames from 'classnames'
import img1 from '../../../../../assets/img_info_1.webp'
import img2 from '../../../../../assets/img_info_2.webp'
import img3 from '../../../../../assets/img_info_3.webp'

export const Info = (props) => {

    return (
        <section className={classnames({
            info: true,
            mobile: isMobile,
            browser: isBrowser
        })}>
            <div className={classnames({
                container: true,
                mobile: isMobile,
                browser: isBrowser
            })}>
                <InfoItem
                    image={img1}
                    title='Every life is important'
                    description='Agents are the most important part of the game. Levelling them up takes a long time, but the injury or death of each agent can greatly weaken the team.'
                />
                <InfoItem
                    image={img2}
                    title='Severe conditions'
                    description='Raids are not as simple as they seem at first glance. The base is regularly attacked by looters (NPC) and other players.'
                />
                <InfoItem
                    image={img3}
                    title='Every action is important'
                    description='For each Raid and PvP, you need to carefully select the appropriate equipment and agents. An error can lead to the death of a fighter or the entire group.'
                />
            </div>
        </section>
    )
}

const InfoItem = (props) => {
    return (
        <div>
            <img src={props.image} alt={props.title} />
            <h4>{props.title}</h4>
            <p>{props.description}</p>
        </div>
    )
} 