import React, {useRef} from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import AccountStats from "./account_control/AccountStats";
import {useAdminState} from "../../../providers/AdminStateProvider";
import {Button, Divider, TextField} from "@mui/material";
import {useRequesting} from "../../../../../app/providers/RequestingProvider";

const AccountControl = () => {
    const adminState = useAdminState()!!
    const account = adminState.getters.getAccount()?.accountDetail
    const request = useRequesting()?.authorizeRequest!!
    const pushText = useRef<any>()

    const handleRefreshCounters = () => {
        request.post(`admin/accounts/${account?.id}/refresh_counters`).then(adminState.refreshers.refreshAccount)
    }

    const handleUnlockAccount = () => {
        request.post(`admin/accounts/${account?.id}/unlock`).then(adminState.refreshers.refreshAccount)
    }

    const handleLockAccount = () => {
        request.post(`admin/accounts/${account?.id}/lock`).then(adminState.refreshers.refreshAccount)
    }

    const handleCompleteTutorial = () => {
        request.post(`admin/accounts/${account?.id}/complete_tutorial`).then(adminState.refreshers.refreshAccount)
    }

    const handleUpdateMarket = () => {
        request.post(`admin/accounts/${account?.id}/refresh_item_shop`).then(adminState.refreshers.refreshAccount)
    }

    const handleForceMaradeurs = () => {
        request.post(`admin/pvp/force_maradeur/${account?.id}`).then(adminState.refreshers.refreshAccount)
    }

    const handleUpdateBarracks = () => {
        request.post(`admin/accounts/${account?.id}/refresh_agent_shop`).then(adminState.refreshers.refreshAccount)
    }

    const handleForceTax = () => {
        request.post(`admin/accounts/${account?.id}/force_tax`).then(adminState.refreshers.refreshAccount)
    }

    const handleDeveloperPush = () => {
        request.post(`admin/accounts/${account?.id}/dev_push`, {}, {
            text: pushText.current.value
        }).then(() => pushText.current.value = "")
    }

    const handleDeleteAccount = () => {
        request.post(`admin/accounts/${account?.id}/delete`).then(adminState.refreshers.refreshAccount)
    }

    return (
        <Grid2 xs={12} container textAlign="left">
            <Grid2 xs={12} textAlign="left" container justifyContent="center">
                <AccountStats/>
            </Grid2>
            <Grid2 xs={12}>
                Account name: {account?.hero_name}
            </Grid2>
            <Grid2 xs={12}>
                accountUid: {account?.id}
            </Grid2>
            <Grid2 xs={12} container justifyContent="center" sx={{m: 1}}>
                <Divider sx={{width: "100%"}}/>
                <Grid2 xs={12} sx={{mb: 1}} textAlign="center">Authorizations</Grid2>
                {
                    account?.authorizations.map((it) => <>
                        <Grid2 xs={3} textAlign="center">
                            {it.platform}
                        </Grid2>
                        <Grid2 xs={3} textAlign="center">
                            {it.id}
                        </Grid2>
                        <Grid2 xs={3} textAlign="center">
                            {it.firstAuthorize}
                        </Grid2>
                        <Grid2 xs={3} textAlign="center">
                            {it.lastAuthorize}
                        </Grid2>
                    </>)
                }
                <Divider sx={{width: "100%"}}/>
            </Grid2>
            <Grid2 xs={12}>
                Состояние аккаунта: {account?.state}
                <Button size="small" variant="outlined" onClick={handleLockAccount}>Заблокировать до списания
                    налогов</Button>
                <Button size="small" variant="outlined" onClick={handleUnlockAccount}>Разблокировать</Button>
                {account?.state === "onboarding" &&
                    <Button size="small" variant="outlined" onClick={handleCompleteTutorial}>Туториал пройден</Button>}
            </Grid2>
            <Grid2 xs={12}>
                Firebase Token: {account?.push_token}
            </Grid2>
            <Grid2 xs={12}>
                Аккаунт создан: {account ? new Date(account?.created_at).toString(): undefined}
            </Grid2>
            <Grid2 xs={12}>
                Последняя сессия: {account ? new Date(account?.last_login).toString(): undefined}
            </Grid2>
            <Grid2 xs={12}>
                Богатство базы: {account?.wealth}
            </Grid2>
            <Grid2 xs={12}>
                Тиер: {account?.wealth_tier}
            </Grid2>
            <Grid2 xs={12}>
                Предметы: {account?.wealth_items}
            </Grid2>
            <Grid2 xs={12}>
                Деньги: {account?.wealth_money}
            </Grid2>
            <Grid2 xs={12}>
                Строения: {account?.wealth_buildings}
            </Grid2>
            <Grid2 xs={12}>
                Осталось атак: {account?.attack_limit}
            </Grid2>
            <Grid2 xs={12}>
                Осталось защит: {account?.defence_limit}
            </Grid2>
            <Grid2 xs={12}>
                Винрейт: {account?.winrate}
                <Button size="small" variant="outlined" onClick={handleRefreshCounters}>Сбросить лимиты</Button>
            </Grid2>
            <Grid2 xs={12} margin="dense">
                Последняя сессия: {account?.last_session_version} | {account?.last_session_platform}
            </Grid2>
            <Grid2 xs={12} container margin="dense" flexDirection="column">
                <Button size="small" variant="outlined" sx={{mt: 1}} onClick={handleUpdateMarket}>Обновить рынок</Button>
                <Button size="small" variant="outlined" sx={{mt: 1}} onClick={handleUpdateBarracks}>Обновить казармы</Button>
                <Button size="small" variant="outlined" sx={{mt: 1}} onClick={handleForceMaradeurs}>Напасть марадерами</Button>
                <Button size="small" variant="outlined" sx={{mt: 1}} onClick={handleForceTax}>Списать обеспечение</Button>
                <Button size="small" variant="outlined" sx={{mt: 1}} onClick={handleForceTax}>Сбросить токен Firebase</Button>
                <Grid2 xs={12} mt={1}>
                    <TextField size="small" label="Пуш" inputRef={pushText}></TextField>
                    <Button size="medium" variant="outlined" onClick={handleDeveloperPush}>Отправить пуш</Button>
                </Grid2>
            </Grid2>
            <Grid2 xs={12} mt={1}>
                <Button size="small" variant="outlined" onClick={handleDeleteAccount}>Удалить аккаунт</Button>
            </Grid2>
        </Grid2>
    );
};

export default AccountControl;