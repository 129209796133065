import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import {Checkbox, FormControlLabel, FormGroup} from "@mui/material";
import {ItemTemplateType} from "../../../types/ItemsTypes";

const ItemBelonging = ({belonging, setItem}: {belonging: string[], setItem: Dispatch<SetStateAction<ItemTemplateType>>}) => {
    const [neutral, setNeutral] = useState<boolean>(belonging.includes('neutral'))
    const [government, setGovernment] = useState<boolean>(belonging.includes('government'))
    const [opposition, setOpposition] = useState<boolean>(belonging.includes('opposition'))


    useEffect(() => {
        if(neutral)
            setItem(prevState => {
                const newFrac = prevState.fractions
                newFrac.push('neutral')
                return ({...prevState, fractions: newFrac})
            })
        else
            setItem(prevState => {
                const newFrac = prevState.fractions.filter((it) => it !== 'neutral')
                return ({...prevState, fractions: newFrac})
            })
    }, [neutral])

    useEffect(() => {
        if(government)
            setItem(prevState => {
                const newFrac = prevState.fractions
                newFrac.push('government')
                return ({...prevState, fractions: newFrac})
            })
        else
            setItem(prevState => {
                const newFrac = prevState.fractions.filter((it) => it !== 'government')
                return ({...prevState, fractions: newFrac})
            })
    }, [government])

    useEffect(() => {
        if(opposition)
            setItem(prevState => {
                const newFrac = prevState.fractions
                newFrac.push('opposition')
                return ({...prevState, fractions: newFrac})
            })
        else
            setItem(prevState => {
                const newFrac = prevState.fractions.filter((it) => it !== 'opposition')
                return ({...prevState, fractions: newFrac})
            })
    }, [opposition])

    return (
        <Grid2 height="100%" xs={12} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <FormGroup>
                <FormControlLabel control={<Checkbox
                    checked={neutral}
                    onClick={() => setNeutral(prevState => !prevState)}
                />}
                    label={"Нейтрал"}/>
                <FormControlLabel control={<Checkbox
                    checked={government}
                    onClick={() => setGovernment(prevState => !prevState)}
                />} label={"Правительство"}/>
                <FormControlLabel control={<Checkbox
                    checked={opposition}
                    onClick={() => setOpposition(prevState => !prevState)}
                />} label={"Оппозиция"}/>
            </FormGroup>
        </Grid2>
    );
};

export default ItemBelonging;