import React from 'react';
import {Typography} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";

const OfferSubPage = () => {
    return (
        <Grid2 xs={12} container>
            <Grid2 xs={12}>
                <Typography variant="h4">Офферы</Typography>
            </Grid2>

        </Grid2>
    );
};

export default OfferSubPage;