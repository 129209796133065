import React, {useEffect} from 'react';
import {Box} from "@mui/material";

const TermsOfUse = () => {

    return (<Box height="100vh" width="100%" overflow="hidden">
            <iframe style={{height: "100%", width: "100%"}} src="/env_pages/terms_of_use.html"></iframe>
        </Box>
        );
};

export default TermsOfUse;