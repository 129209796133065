import React from 'react'
import { isBrowser, isMobile } from 'react-device-detect';
import classnames from 'classnames';
import btn_app_store from '../../../../assets/btn_app_store.png'

export const AppStore = (props) => {
    return (
        <a className={classnames({
            button: true,
            mobile: isMobile,
            browser: isBrowser
        })} style={props.style} target="_blank" href='https://apps.apple.com/app/id6444133590' rel="noreferrer">
            <img src={btn_app_store} alt="App Store" />
        </a>
    );
}