import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    MenuItem,
    MenuList,
    Paper,
    Stack
} from '@mui/material';
import AccountStorage from "./accounts_content/AccountStorage";
import {componentOfAccounts} from "./Constants";
import {useAdminState} from "../../providers/AdminStateProvider";

const AccountsContent = () => {
    const adminState = useAdminState()!!
    const account = adminState.getters.getAccount()
    const listComponents = componentOfAccounts

    const selectComponent = (value: string) => {
        adminState.setters.setComponentAccount(value)
    }

    return (
        <>
            <Box width="100%" display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center">
                <List
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "center"
                    }}
                >
                    {
                        listComponents.map((it, key) => <ListItemButton
                            key={key}
                            id={it.id}
                            onClick={() => selectComponent(it.id)}
                        >
                            <ListItemText>{it.label}</ListItemText>
                        </ListItemButton>)
                    }
                </List>
            </Box>
            {listComponents.map((it) => {
                    return it.component!!(account?.openedComponent === it.id && account?.selectedAcc !== undefined)
            })}
        </>
    );
};

export default AccountsContent;