import React, {useEffect} from 'react';
import {Container, Divider, Paper, Typography} from "@mui/material";
import LeftAppBar from "../../abstract_components/ui/LeftAppBar";
import {TabsProvider} from "../providers/TabsProvider";
import {Outlet} from "react-router-dom";
import AdminHeader from '../../../entities/admin/main_components/ui/AdminHeader';
import TabsLayout from "./TabsLayout";
import {AdminStateProvider} from "../providers/AdminStateProvider";
import {useRequesting} from "../../../app/providers/RequestingProvider";

const ContentLayoutAdmin = () => {
    const request = useRequesting()?.authorizeRequest!!
    useEffect(() => {
        request.get("admin_auth")
    }, [])

    return (
        <Container
            maxWidth={false}
            disableGutters
            sx={{
                display: "flex",
                bgcolor: "background.paper"
            }}
        >
            <AdminStateProvider>
                <TabsProvider>
                    <LeftAppBar/>
                    <Container sx={{
                        border: "1px solid black",
                        boxShadow: "0px 0px 13px 5px rgba(34, 60, 80, 0.2)",
                        bgcolor: "background.default"
                    }}>
                        <Typography textAlign="center" variant="h3" marginTop={1} marginBottom={3}>
                            <AdminHeader/>
                        </Typography>
                        <Divider/>
                        <TabsLayout/>
                    </Container>
                </TabsProvider>
            </AdminStateProvider>
        </Container>
    );
};

export default ContentLayoutAdmin;