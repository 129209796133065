import React, {ChangeEvent, useEffect} from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import {useAdminState} from "../../../widgets/admin/providers/AdminStateProvider";
import {
    AbilityMapType,
    AbilityObjType,
    AbilityValuesType,
    BalanceType
} from "../../../widgets/admin/types/BalanceTypes";
import {Button, Divider} from "@mui/material";
import {useRequesting} from "../../../app/providers/RequestingProvider";

const BalancePage = () => {
    const request = useRequesting()?.authorizeRequest!!
    const adminState = useAdminState()!!
    const state: BalanceType | undefined = adminState.getters.getBalance()

    const updateIntParameter = (e: ChangeEvent<HTMLInputElement>) => {
        if(state) {
            const id: keyof BalanceType = e.target.name as keyof BalanceType
            (state[id] as number) = parseInt(e.target.value)
        }
    }

    const updateStringParameter = (e: ChangeEvent<HTMLInputElement>) => {
        if(state) {
            const id: keyof BalanceType = e.target.name as keyof BalanceType
            (state[id] as string) = e.target.value
        }
    }

    const updateFloatArrayParameter = (e: ChangeEvent<HTMLInputElement>) => {
        if(state) {
            const parts = e.target.name.split("$")
            const index = parseInt(parts.pop()!!)
            const name: keyof BalanceType = parts.pop() as keyof BalanceType
            ((state[name] as number[])[index] as number) = parseFloat(e.target.value)
        }
    }

    const updateDifficulty = (e: ChangeEvent<HTMLInputElement>) => {
        if(state) {
            const parts = e.target.name.split("$")
            const index = parseInt(parts.pop()!!)
            const name: keyof BalanceType = parts.pop() as keyof BalanceType
            ((state[name] as number[])[index] as number) = parseInt(e.target.value)
        }
    }

    const updateRaidResult = (e: ChangeEvent<HTMLInputElement>) => {
        if(state) {
            const parts: string[] = e.target.name.split("$")
            const secondI: number = parseInt(parts.pop()!!)!!
            const firstI: number = parseInt(parts.pop()!!)!!
            state.raid_tier_results[firstI][secondI] = parseInt(e.target.value)
        }
    }

    const updateAttributes = (e: ChangeEvent<HTMLInputElement>) => {
        if(state) {
            const parts: string[] = e.target.name.split("$")
            const secondI: number = parseInt(parts.pop()!!)
            const firstI: number = parseInt(parts.pop()!!)
            const name: keyof BalanceType = parts.pop() as keyof BalanceType
            (state[name] as number[][])[firstI][secondI] = parseInt(e.target.value)
        }
    }

    const updateAbilityMap = (e: ChangeEvent<HTMLInputElement>) => {
        if(state) {
            const value = parseInt(e.target.value)
            const parts = e.target.name.split("$")
            const mod: keyof AbilityValuesType = parts.pop() as keyof AbilityValuesType
            const buff: keyof AbilityObjType = parts.pop() as keyof AbilityObjType
            const ability: keyof AbilityMapType = parts.pop() as keyof AbilityMapType
            (((state.abilityMap[ability] as AbilityObjType)[buff] as AbilityValuesType)[mod] as number) = value
        }
    }

    const updateMoney = (e: ChangeEvent<HTMLInputElement>) => {
        if(state) {
            const parts = e.target.name.split("$")
            const secondI = parseInt(parts.pop()!!)
            const firstI = parseInt(parts.pop()!!)
            state.raids_reward_money[firstI][secondI] = parseInt(e.target.value)
        }
    }

    const updatePvpTier = (e: ChangeEvent<HTMLInputElement>) => {
        if(state){
            const index = parseInt(e.target.name)
            state.pvp_tiers[index] = parseInt(e.target.value)
        }
    }

    const updateArray = (e: ChangeEvent<HTMLInputElement>) => {
        if(state) {
            const parts = e.target.name.split("$")
            const index = parseInt(parts.pop()!!)
            const name: keyof BalanceType = parts.pop()!! as keyof BalanceType
            ((state[name] as number[])[index] as number) = parseInt(e.target.value)
        }
    }

    const updateState = () => {
            request.post(`admin/balance`, {}, state).then((res) => {
                adminState.refreshers.refreshBalance()
            })
    }

    return (
        <Grid2 xs={12} container justifyContent="center">
            <table>
                <thead>
                <tr>
                    <td>Описание</td>
                    <td>Значение</td>
                </tr>
                </thead>
                <tbody>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Сложность рейдов</td></tr>
                <tr>
                    <table>
                        <tr><td /><td>Очень легкий</td><td>Легкий</td><td>Обычный</td><td>Сложный</td><td>Экстремальный</td></tr>
                        <tr>
                            <td>Атака</td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raids_difficulty_attack[0]} name='raids_difficulty_attack$0' onChange={updateDifficulty} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raids_difficulty_attack[1]} name='raids_difficulty_attack$1' onChange={updateDifficulty} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raids_difficulty_attack[2]} name='raids_difficulty_attack$2' onChange={updateDifficulty} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raids_difficulty_attack[3]} name='raids_difficulty_attack$3' onChange={updateDifficulty} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raids_difficulty_attack[4]} name='raids_difficulty_attack$4' onChange={updateDifficulty} /></td>
                        </tr>
                        <tr>
                            <td>Шпионаж</td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raids_difficulty_stealth[0]} name='raids_difficulty_stealth$0' onChange={updateDifficulty} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raids_difficulty_stealth[1]} name='raids_difficulty_stealth$1' onChange={updateDifficulty} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raids_difficulty_stealth[2]} name='raids_difficulty_stealth$2' onChange={updateDifficulty} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raids_difficulty_stealth[3]} name='raids_difficulty_stealth$3' onChange={updateDifficulty} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raids_difficulty_stealth[4]} name='raids_difficulty_stealth$4' onChange={updateDifficulty} /></td>
                        </tr>
                        <tr>
                            <td>Разведка</td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raids_difficulty_survival[0]} name='raids_difficulty_survival$0' onChange={updateDifficulty} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raids_difficulty_survival[1]} name='raids_difficulty_survival$1' onChange={updateDifficulty} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raids_difficulty_survival[2]} name='raids_difficulty_survival$2' onChange={updateDifficulty} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raids_difficulty_survival[3]} name='raids_difficulty_survival$3' onChange={updateDifficulty} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raids_difficulty_survival[4]} name='raids_difficulty_survival$4' onChange={updateDifficulty} /></td>
                        </tr>
                        <tr>
                            <td>Длительность, минуты</td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raids_length_minutes[0]} name='raids_length_minutes$0' onChange={updateDifficulty} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raids_length_minutes[1]} name='raids_length_minutes$1' onChange={updateDifficulty} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raids_length_minutes[2]} name='raids_length_minutes$2' onChange={updateDifficulty} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raids_length_minutes[3]} name='raids_length_minutes$3' onChange={updateDifficulty} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raids_length_minutes[4]} name='raids_length_minutes$4' onChange={updateDifficulty} /></td>
                        </tr>
                    </table>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Исход рейдов</td></tr>
                <tr>
                    <table>
                        <tr><td>Pb</td><td>VIC+</td><td>VIC</td><td>SUC</td><td>RET</td><td>WND</td><td>DTH</td><td>DTH+</td></tr>
                        <tr>
                            <td>12 - ∞</td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[6][0]} name='raid_tier_results$6$0' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[6][1]} name='raid_tier_results$6$1' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[6][2]} name='raid_tier_results$6$2' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[6][3]} name='raid_tier_results$6$3' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[6][4]} name='raid_tier_results$6$4' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[6][5]} name='raid_tier_results$6$5' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[6][6]} name='raid_tier_results$6$6' onChange={updateRaidResult} /></td>
                        </tr>
                        <tr>
                            <td>10 - 11</td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[5][0]} name='raid_tier_results$5$0' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[5][1]} name='raid_tier_results$5$1' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[5][2]} name='raid_tier_results$5$2' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[5][3]} name='raid_tier_results$5$3' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[5][4]} name='raid_tier_results$5$4' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[5][5]} name='raid_tier_results$5$5' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[5][6]} name='raid_tier_results$5$6' onChange={updateRaidResult} /></td>

                        </tr>
                        <tr>
                            <td>8 - 9</td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[4][0]} name='raid_tier_results$4$0' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[4][1]} name='raid_tier_results$4$1' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[4][2]} name='raid_tier_results$4$2' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[4][3]} name='raid_tier_results$4$3' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[4][4]} name='raid_tier_results$4$4' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[4][5]} name='raid_tier_results$4$5' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[4][6]} name='raid_tier_results$4$6' onChange={updateRaidResult} /></td>

                        </tr>
                        <tr>
                            <td>6 - 7</td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[3][0]} name='raid_tier_results$3$0' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[3][1]} name='raid_tier_results$3$1' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[3][2]} name='raid_tier_results$3$2' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[3][3]} name='raid_tier_results$3$3' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[3][4]} name='raid_tier_results$3$4' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[3][5]} name='raid_tier_results$3$5' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[3][6]} name='raid_tier_results$3$6' onChange={updateRaidResult} /></td>

                        </tr>
                        <tr>
                            <td>4 - 5</td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[2][0]} name='raid_tier_results$2$0' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[2][1]} name='raid_tier_results$2$1' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[2][2]} name='raid_tier_results$2$2' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[2][3]} name='raid_tier_results$2$3' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[2][4]} name='raid_tier_results$2$4' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[2][5]} name='raid_tier_results$2$5' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[2][6]} name='raid_tier_results$2$6' onChange={updateRaidResult} /></td>

                        </tr>
                        <tr>
                            <td>2 - 3</td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[1][0]} name='raid_tier_results$1$0' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[1][1]} name='raid_tier_results$1$1' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[1][2]} name='raid_tier_results$1$2' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[1][3]} name='raid_tier_results$1$3' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[1][4]} name='raid_tier_results$1$4' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[1][5]} name='raid_tier_results$1$5' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[1][6]} name='raid_tier_results$1$6' onChange={updateRaidResult} /></td>

                        </tr>
                        <tr>
                            <td>0 - 1</td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[0][0]} name='raid_tier_results$0$0' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[0][1]} name='raid_tier_results$0$1' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[0][2]} name='raid_tier_results$0$2' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[0][3]} name='raid_tier_results$0$3' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[0][4]} name='raid_tier_results$0$4' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[0][5]} name='raid_tier_results$0$5' onChange={updateRaidResult} /></td>
                            <td><input type='number' style={{width: "40%"}} defaultValue = {state?.raid_tier_results[0][6]} name='raid_tier_results$0$6' onChange={updateRaidResult} /></td>

                        </tr>
                    </table>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Прибавки характеристик в рейдах (основные/вторичные)</td></tr>
                <tr>
                    <table>
                        <tr><td /><td>Оч легкий</td><td>Легкий</td><td>Обычный</td><td>Сложный</td><td>Экстремальный</td></tr>
                        <tr>
                            <td>Супер-Бомж</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_primary[0][0]} name='raids_reward_chars_primary$0$0' onChange={updateAttributes} />/<input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_secondary[0][0]} name='raids_reward_chars_secondary$0$0' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_primary[0][1]} name='raids_reward_chars_primary$0$1' onChange={updateAttributes} />/<input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_secondary[0][1]} name='raids_reward_chars_secondary$0$1' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_primary[0][2]} name='raids_reward_chars_primary$0$2' onChange={updateAttributes} />/<input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_secondary[0][2]} name='raids_reward_chars_secondary$0$2' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_primary[0][3]} name='raids_reward_chars_primary$0$3' onChange={updateAttributes} />/<input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_secondary[0][3]} name='raids_reward_chars_secondary$0$3' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_primary[0][4]} name='raids_reward_chars_primary$0$4' onChange={updateAttributes} />/<input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_secondary[0][4]} name='raids_reward_chars_secondary$0$4' onChange={updateAttributes} /></td>
                        </tr>
                        <tr>
                            <td>Бомж</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_primary[1][0]} name='raids_reward_chars_primary$1$0' onChange={updateAttributes} />/<input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_secondary[1][0]} name='raids_reward_chars_secondary$1$0' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_primary[1][1]} name='raids_reward_chars_primary$1$1' onChange={updateAttributes} />/<input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_secondary[1][1]} name='raids_reward_chars_secondary$1$1' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_primary[1][2]} name='raids_reward_chars_primary$1$2' onChange={updateAttributes} />/<input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_secondary[1][2]} name='raids_reward_chars_secondary$1$2' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_primary[1][3]} name='raids_reward_chars_primary$1$3' onChange={updateAttributes} />/<input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_secondary[1][3]} name='raids_reward_chars_secondary$1$3' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_primary[1][4]} name='raids_reward_chars_primary$1$4' onChange={updateAttributes} />/<input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_secondary[1][4]} name='raids_reward_chars_secondary$1$4' onChange={updateAttributes} /></td>
                        </tr>
                        <tr>
                            <td>Специалист</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_primary[2][0]} name='raids_reward_chars_primary$2$0' onChange={updateAttributes} />/<input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_secondary[2][0]} name='raids_reward_chars_secondary$2$0' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_primary[2][1]} name='raids_reward_chars_primary$2$1' onChange={updateAttributes} />/<input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_secondary[2][1]} name='raids_reward_chars_secondary$2$1' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_primary[2][2]} name='raids_reward_chars_primary$2$2' onChange={updateAttributes} />/<input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_secondary[2][2]} name='raids_reward_chars_secondary$2$2' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_primary[2][3]} name='raids_reward_chars_primary$2$3' onChange={updateAttributes} />/<input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_secondary[2][3]} name='raids_reward_chars_secondary$2$3' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_primary[2][4]} name='raids_reward_chars_primary$2$4' onChange={updateAttributes} />/<input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_secondary[2][4]} name='raids_reward_chars_secondary$2$4' onChange={updateAttributes} /></td>
                        </tr>
                        <tr>
                            <td>Эксперт</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_primary[3][0]} name='raids_reward_chars_primary$3$0' onChange={updateAttributes} />/<input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_secondary[3][0]} name='raids_reward_chars_secondary$3$0' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_primary[3][1]} name='raids_reward_chars_primary$3$1' onChange={updateAttributes} />/<input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_secondary[3][1]} name='raids_reward_chars_secondary$3$1' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_primary[3][2]} name='raids_reward_chars_primary$3$2' onChange={updateAttributes} />/<input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_secondary[3][2]} name='raids_reward_chars_secondary$3$2' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_primary[3][3]} name='raids_reward_chars_primary$3$3' onChange={updateAttributes} />/<input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_secondary[3][3]} name='raids_reward_chars_secondary$3$3' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_primary[3][4]} name='raids_reward_chars_primary$3$4' onChange={updateAttributes} />/<input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_secondary[3][4]} name='raids_reward_chars_secondary$3$4' onChange={updateAttributes} /></td>
                        </tr>
                        <tr>
                            <td>Мастер</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_primary[4][0]} name='raids_reward_chars_primary$4$0' onChange={updateAttributes} />/<input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_secondary[4][0]} name='raids_reward_chars_secondary$4$0' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_primary[4][1]} name='raids_reward_chars_primary$4$1' onChange={updateAttributes} />/<input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_secondary[4][1]} name='raids_reward_chars_secondary$4$1' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_primary[4][2]} name='raids_reward_chars_primary$4$2' onChange={updateAttributes} />/<input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_secondary[4][2]} name='raids_reward_chars_secondary$4$2' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_primary[4][3]} name='raids_reward_chars_primary$4$3' onChange={updateAttributes} />/<input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_secondary[4][3]} name='raids_reward_chars_secondary$4$3' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_primary[4][4]} name='raids_reward_chars_primary$4$4' onChange={updateAttributes} />/<input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_secondary[4][4]} name='raids_reward_chars_secondary$4$4' onChange={updateAttributes} /></td>
                        </tr>
                        <tr>
                            <td>Супер-Мастер</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_primary[5][0]} name='raids_reward_chars_primary$5$0' onChange={updateAttributes} />/<input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_secondary[5][0]} name='raids_reward_chars_secondary$5$0' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_primary[5][1]} name='raids_reward_chars_primary$5$1' onChange={updateAttributes} />/<input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_secondary[5][1]} name='raids_reward_chars_secondary$5$1' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_primary[5][2]} name='raids_reward_chars_primary$5$2' onChange={updateAttributes} />/<input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_secondary[5][2]} name='raids_reward_chars_secondary$5$2' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_primary[5][3]} name='raids_reward_chars_primary$5$3' onChange={updateAttributes} />/<input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_secondary[5][3]} name='raids_reward_chars_secondary$5$3' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_primary[5][4]} name='raids_reward_chars_primary$5$4' onChange={updateAttributes} />/<input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_chars_secondary[5][4]} name='raids_reward_chars_secondary$5$4' onChange={updateAttributes} /></td>
                        </tr>
                    </table>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Награда денег за частичный успех (победа х2)</td></tr>
                <tr>
                    <table>
                        <tr><td /><td>Оч легкий</td><td>Легкий</td><td>Обычный</td><td>Сложный</td><td>Экстремальный</td></tr>
                        <tr>
                            <td>Супер-Бомж</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_money[0][0]} name='raids_reward_money$0$0' onChange={updateMoney} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_money[0][1]} name='raids_reward_money$0$1' onChange={updateMoney} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_money[0][2]} name='raids_reward_money$0$2' onChange={updateMoney} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_money[0][3]} name='raids_reward_money$0$3' onChange={updateMoney} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_money[0][4]} name='raids_reward_money$0$4' onChange={updateMoney} /></td>
                        </tr>
                        <tr>
                            <td>Бомж</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_money[1][0]} name='raids_reward_money$1$0' onChange={updateMoney} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_money[1][1]} name='raids_reward_money$1$1' onChange={updateMoney} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_money[1][2]} name='raids_reward_money$1$2' onChange={updateMoney} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_money[1][3]} name='raids_reward_money$1$3' onChange={updateMoney} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_money[1][4]} name='raids_reward_money$1$4' onChange={updateMoney} /></td>
                        </tr>
                        <tr>
                            <td>Специалист</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_money[2][0]} name='raids_reward_money$2$0' onChange={updateMoney} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_money[2][1]} name='raids_reward_money$2$1' onChange={updateMoney} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_money[2][2]} name='raids_reward_money$2$2' onChange={updateMoney} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_money[2][3]} name='raids_reward_money$2$3' onChange={updateMoney} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_money[2][4]} name='raids_reward_money$2$4' onChange={updateMoney} /></td>
                        </tr>
                        <tr>
                            <td>Эксперт</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_money[3][0]} name='raids_reward_money$3$0' onChange={updateMoney} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_money[3][1]} name='raids_reward_money$3$1' onChange={updateMoney} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_money[3][2]} name='raids_reward_money$3$2' onChange={updateMoney} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_money[3][3]} name='raids_reward_money$3$3' onChange={updateMoney} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_money[3][4]} name='raids_reward_money$3$4' onChange={updateMoney} /></td>
                        </tr>
                        <tr>
                            <td>Мастер</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_money[4][0]} name='raids_reward_money$4$0' onChange={updateMoney} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_money[4][1]} name='raids_reward_money$4$1' onChange={updateMoney} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_money[4][2]} name='raids_reward_money$4$2' onChange={updateMoney} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_money[4][3]} name='raids_reward_money$4$3' onChange={updateMoney} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_money[4][4]} name='raids_reward_money$4$4' onChange={updateMoney} /></td>
                        </tr>
                        <tr>
                            <td>Супер-Мастер</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_money[5][0]} name='raids_reward_money$5$0' onChange={updateMoney} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_money[5][1]} name='raids_reward_money$5$1' onChange={updateMoney} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_money[5][2]} name='raids_reward_money$5$2' onChange={updateMoney} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_money[5][3]} name='raids_reward_money$5$3' onChange={updateMoney} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_reward_money[5][4]} name='raids_reward_money$5$4' onChange={updateMoney} /></td>
                        </tr>
                    </table>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Генерация ботов для кооп</td></tr>
                <tr>
                    <table>
                        <tr><td /><td>Оч легкий</td><td>Легкий</td><td>Обычный</td><td>Сложный</td><td>Экстремальный</td></tr>
                        <tr>
                            <td>Шанс выпадения основного оружия</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_weapon_chance[0]} name='raids_bots_weapon_chance$0' onChange={updateDifficulty} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_weapon_chance[1]} name='raids_bots_weapon_chance$1' onChange={updateDifficulty} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_weapon_chance[2]} name='raids_bots_weapon_chance$2' onChange={updateDifficulty} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_weapon_chance[3]} name='raids_bots_weapon_chance$3' onChange={updateDifficulty} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_weapon_chance[4]} name='raids_bots_weapon_chance$4' onChange={updateDifficulty} /></td>
                        </tr>
                        <tr>
                            <td>Шанс выпадения дополнительного предмета</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_additional_chance[0]} name='raids_bots_additional_chance$0' onChange={updateDifficulty} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_additional_chance[1]} name='raids_bots_additional_chance$1' onChange={updateDifficulty} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_additional_chance[2]} name='raids_bots_additional_chance$2' onChange={updateDifficulty} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_additional_chance[3]} name='raids_bots_additional_chance$3' onChange={updateDifficulty} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_additional_chance[4]} name='raids_bots_additional_chance$4' onChange={updateDifficulty} /></td>
                        </tr>
                        <tr>
                            <td>Шанс выпадения брони</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_armor_chance[0]} name='raids_bots_armor_chance$0' onChange={updateDifficulty} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_armor_chance[1]} name='raids_bots_armor_chance$1' onChange={updateDifficulty} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_armor_chance[2]} name='raids_bots_armor_chance$2' onChange={updateDifficulty} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_armor_chance[3]} name='raids_bots_armor_chance$3' onChange={updateDifficulty} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_armor_chance[4]} name='raids_bots_armor_chance$4' onChange={updateDifficulty} /></td>
                        </tr>
                        <tr>
                            <td>Шанс выпадения рюкзака</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_bag_chance[0]} name='raids_bots_bag_chance$0' onChange={updateDifficulty} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_bag_chance[1]} name='raids_bots_bag_chance$1' onChange={updateDifficulty} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_bag_chance[2]} name='raids_bots_bag_chance$2' onChange={updateDifficulty} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_bag_chance[3]} name='raids_bots_bag_chance$3' onChange={updateDifficulty} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_bag_chance[4]} name='raids_bots_bag_chance$4' onChange={updateDifficulty} /></td>
                        </tr>
                        <tr>
                            <td>ОСН. ОР. Плохое качество</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance[0][0]} name='raids_bots_quality_chance$0$0' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance[0][1]} name='raids_bots_quality_chance$0$1' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance[0][2]} name='raids_bots_quality_chance$0$2' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance[0][3]} name='raids_bots_quality_chance$0$3' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance[0][4]} name='raids_bots_quality_chance$0$4' onChange={updateAttributes} /></td>
                        </tr>
                        <tr>
                            <td>ОСН. ОР. Обычное качество</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance[1][0]} name='raids_bots_quality_chance$1$0' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance[1][1]} name='raids_bots_quality_chance$1$1' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance[1][2]} name='raids_bots_quality_chance$1$2' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance[1][3]} name='raids_bots_quality_chance$1$3' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance[1][4]} name='raids_bots_quality_chance$1$4' onChange={updateAttributes} /></td>
                        </tr>
                        <tr>
                            <td>ОСН. ОР. Хорошее качество</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance[2][0]} name='raids_bots_quality_chance$2$0' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance[2][1]} name='raids_bots_quality_chance$2$1' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance[2][2]} name='raids_bots_quality_chance$2$2' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance[2][3]} name='raids_bots_quality_chance$2$3' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance[2][4]} name='raids_bots_quality_chance$2$4' onChange={updateAttributes} /></td>
                        </tr>
                        <tr>
                            <td>ОСН. ОР. Отличное качество</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance[3][0]} name='raids_bots_quality_chance$3$0' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance[3][1]} name='raids_bots_quality_chance$3$1' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance[3][2]} name='raids_bots_quality_chance$3$2' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance[3][3]} name='raids_bots_quality_chance$3$3' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance[3][4]} name='raids_bots_quality_chance$3$4' onChange={updateAttributes} /></td>
                        </tr>
                        <tr>
                            <td>ДОП. ОР. Плохое качество</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_sec[0][0]} name='raids_bots_quality_chance_sec$0$0' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_sec[0][1]} name='raids_bots_quality_chance_sec$0$1' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_sec[0][2]} name='raids_bots_quality_chance_sec$0$2' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_sec[0][3]} name='raids_bots_quality_chance_sec$0$3' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_sec[0][4]} name='raids_bots_quality_chance_sec$0$4' onChange={updateAttributes} /></td>
                        </tr>
                        <tr>
                            <td>ДОП. ОР. Обычное качество</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_sec[1][0]} name='raids_bots_quality_chance_sec$1$0' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_sec[1][1]} name='raids_bots_quality_chance_sec$1$1' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_sec[1][2]} name='raids_bots_quality_chance_sec$1$2' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_sec[1][3]} name='raids_bots_quality_chance_sec$1$3' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_sec[1][4]} name='raids_bots_quality_chance_sec$1$4' onChange={updateAttributes} /></td>
                        </tr>
                        <tr>
                            <td>ДОП. ОР. Хорошее качество</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_sec[2][0]} name='raids_bots_quality_chance_sec$2$0' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_sec[2][1]} name='raids_bots_quality_chance_sec$2$1' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_sec[2][2]} name='raids_bots_quality_chance_sec$2$2' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_sec[2][3]} name='raids_bots_quality_chance_sec$2$3' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_sec[2][4]} name='raids_bots_quality_chance_sec$2$4' onChange={updateAttributes} /></td>
                        </tr>
                        <tr>
                            <td>ДОП. ОР. Отличное качество</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_sec[3][0]} name='raids_bots_quality_chance_sec$3$0' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_sec[3][1]} name='raids_bots_quality_chance_sec$3$1' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_sec[3][2]} name='raids_bots_quality_chance_sec$3$2' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_sec[3][3]} name='raids_bots_quality_chance_sec$3$3' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_sec[3][4]} name='raids_bots_quality_chance_sec$3$4' onChange={updateAttributes} /></td>
                        </tr>
                        <tr>
                            <td>БРОНЯ Плохое качество</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_armor[0][0]} name='raids_bots_quality_chance_armor$0$0' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_armor[0][1]} name='raids_bots_quality_chance_armor$0$1' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_armor[0][2]} name='raids_bots_quality_chance_armor$0$2' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_armor[0][3]} name='raids_bots_quality_chance_armor$0$3' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_armor[0][4]} name='raids_bots_quality_chance_armor$0$4' onChange={updateAttributes} /></td>
                        </tr>
                        <tr>
                            <td>БРОНЯ Обычное качество</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_armor[1][0]} name='raids_bots_quality_chance_armor$1$0' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_armor[1][1]} name='raids_bots_quality_chance_armor$1$1' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_armor[1][2]} name='raids_bots_quality_chance_armor$1$2' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_armor[1][3]} name='raids_bots_quality_chance_armor$1$3' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_armor[1][4]} name='raids_bots_quality_chance_armor$1$4' onChange={updateAttributes} /></td>
                        </tr>
                        <tr>
                            <td>БРОНЯ Хорошее качество</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_armor[2][0]} name='raids_bots_quality_chance_armor$2$0' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_armor[2][1]} name='raids_bots_quality_chance_armor$2$1' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_armor[2][2]} name='raids_bots_quality_chance_armor$2$2' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_armor[2][3]} name='raids_bots_quality_chance_armor$2$3' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_armor[2][4]} name='raids_bots_quality_chance_armor$2$4' onChange={updateAttributes} /></td>
                        </tr>
                        <tr>
                            <td>БРОНЯ Отличное качество</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_armor[3][0]} name='raids_bots_quality_chance_armor$3$0' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_armor[3][1]} name='raids_bots_quality_chance_armor$3$1' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_armor[3][2]} name='raids_bots_quality_chance_armor$3$2' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_armor[3][3]} name='raids_bots_quality_chance_armor$3$3' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_armor[3][4]} name='raids_bots_quality_chance_armor$3$4' onChange={updateAttributes} /></td>
                        </tr>
                        <tr>
                            <td>СУМКА Плохое качество</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_bag[0][0]} name='raids_bots_quality_chance_bag$0$0' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_bag[0][1]} name='raids_bots_quality_chance_bag$0$1' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_bag[0][2]} name='raids_bots_quality_chance_bag$0$2' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_bag[0][3]} name='raids_bots_quality_chance_bag$0$3' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_bag[0][4]} name='raids_bots_quality_chance_bag$0$4' onChange={updateAttributes} /></td>
                        </tr>
                        <tr>
                            <td>СУМКА Обычное качество</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_bag[1][0]} name='raids_bots_quality_chance_bag$1$0' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_bag[1][1]} name='raids_bots_quality_chance_bag$1$1' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_bag[1][2]} name='raids_bots_quality_chance_bag$1$2' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_bag[1][3]} name='raids_bots_quality_chance_bag$1$3' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_bag[1][4]} name='raids_bots_quality_chance_bag$1$4' onChange={updateAttributes} /></td>
                        </tr>
                        <tr>
                            <td>СУМКА Хорошее качество</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_bag[2][0]} name='raids_bots_quality_chance_bag$2$0' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_bag[2][1]} name='raids_bots_quality_chance_bag$2$1' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_bag[2][2]} name='raids_bots_quality_chance_bag$2$2' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_bag[2][3]} name='raids_bots_quality_chance_bag$2$3' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_bag[2][4]} name='raids_bots_quality_chance_bag$2$4' onChange={updateAttributes} /></td>
                        </tr>
                        <tr>
                            <td>СУМКА Отличное качество</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_bag[3][0]} name='raids_bots_quality_chance_bag$3$0' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_bag[3][1]} name='raids_bots_quality_chance_bag$3$1' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_bag[3][2]} name='raids_bots_quality_chance_bag$3$2' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_bag[3][3]} name='raids_bots_quality_chance_bag$3$3' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_quality_chance_bag[3][4]} name='raids_bots_quality_chance_bag$3$4' onChange={updateAttributes} /></td>
                        </tr>
                        <tr>
                            <td>Без модификаций</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_modif_count_chance[0][0]} name='raids_bots_modif_count_chance$0$0' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_modif_count_chance[0][1]} name='raids_bots_modif_count_chance$0$1' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_modif_count_chance[0][2]} name='raids_bots_modif_count_chance$0$2' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_modif_count_chance[0][3]} name='raids_bots_modif_count_chance$0$3' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_modif_count_chance[0][4]} name='raids_bots_modif_count_chance$0$4' onChange={updateAttributes} /></td>
                        </tr>
                        <tr>
                            <td>Одна модификация</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_modif_count_chance[1][0]} name='raids_bots_modif_count_chance$1$0' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_modif_count_chance[1][1]} name='raids_bots_modif_count_chance$1$1' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_modif_count_chance[1][2]} name='raids_bots_modif_count_chance$1$2' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_modif_count_chance[1][3]} name='raids_bots_modif_count_chance$1$3' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_modif_count_chance[1][4]} name='raids_bots_modif_count_chance$1$4' onChange={updateAttributes} /></td>
                        </tr>
                        <tr>
                            <td>Две модификации</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_modif_count_chance[2][0]} name='raids_bots_modif_count_chance$2$0' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_modif_count_chance[2][1]} name='raids_bots_modif_count_chance$2$1' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_modif_count_chance[2][2]} name='raids_bots_modif_count_chance$2$2' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_modif_count_chance[2][3]} name='raids_bots_modif_count_chance$2$3' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_modif_count_chance[2][4]} name='raids_bots_modif_count_chance$2$4' onChange={updateAttributes} /></td>
                        </tr>
                        <tr>
                            <td>Три модификации</td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_modif_count_chance[3][0]} name='raids_bots_modif_count_chance$3$0' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_modif_count_chance[3][1]} name='raids_bots_modif_count_chance$3$1' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_modif_count_chance[3][2]} name='raids_bots_modif_count_chance$3$2' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_modif_count_chance[3][3]} name='raids_bots_modif_count_chance$3$3' onChange={updateAttributes} /></td>
                            <td><input type='number' style={{width: "40%"}} className='input_short' defaultValue={state?.raids_bots_modif_count_chance[3][4]} name='raids_bots_modif_count_chance$3$4' onChange={updateAttributes} /></td>
                        </tr>
                    </table>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>PvP тиры</td></tr>
                <tr>
                    <td>
                        <p className="borderless"> 1: <input type="text" style={{width: "40%"}} name="0" onChange={updatePvpTier} defaultValue={state?.pvp_tiers[0]}/></p>
                        <p className="borderless"> 2: <input type="text" style={{width: "40%"}} name="1" onChange={updatePvpTier} defaultValue={state?.pvp_tiers[1]}/></p>
                        <p className="borderless"> 3: <input type="text" style={{width: "40%"}} name="2" onChange={updatePvpTier} defaultValue={state?.pvp_tiers[2]}/></p>
                        <p className="borderless"> 4: <input type="text" style={{width: "40%"}} name="3" onChange={updatePvpTier} defaultValue={state?.pvp_tiers[3]}/></p>
                        <p className="borderless"> 5: <input type="text" style={{width: "40%"}} name="4" onChange={updatePvpTier} defaultValue={state?.pvp_tiers[4]}/></p>
                        <p className="borderless"> 6: <input type="text" style={{width: "40%"}} name="5" onChange={updatePvpTier} defaultValue={state?.pvp_tiers[5]}/></p>
                        <p className="borderless"> 7: <input type="text" style={{width: "40%"}} name="6" onChange={updatePvpTier} defaultValue={state?.pvp_tiers[6]}/></p>
                        <p className="borderless"> 8: <input type="text" style={{width: "40%"}} name="7" onChange={updatePvpTier} defaultValue={state?.pvp_tiers[7]}/></p>
                        <p className="borderless"> 9: <input type="text" style={{width: "40%"}} name="8" onChange={updatePvpTier} defaultValue={state?.pvp_tiers[8]}/></p>
                        <p className="borderless">10: <input type="text" style={{width: "40%"}} name="9" onChange={updatePvpTier} defaultValue={state?.pvp_tiers[9]}/></p>
                        <p className="borderless">11: <input type="text" style={{width: "40%"}} name="10" onChange={updatePvpTier} defaultValue={state?.pvp_tiers[10]}/></p>
                    </td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>PvP Боты</td></tr>
                <tr>
                    <table><tr>
                        <td>
                            <p>Богатство</p>
                            <p className="borderless"> 1: <input type="text" style={{width: "40%"}} name="pvp_bot_wealth$0" onChange={updateArray} defaultValue={state?.pvp_bot_wealth[0]}/></p>
                            <p className="borderless"> 2: <input type="text" style={{width: "40%"}} name="pvp_bot_wealth$1" onChange={updateArray} defaultValue={state?.pvp_bot_wealth[1]}/></p>
                            <p className="borderless"> 3: <input type="text" style={{width: "40%"}} name="pvp_bot_wealth$2" onChange={updateArray} defaultValue={state?.pvp_bot_wealth[2]}/></p>
                            <p className="borderless"> 4: <input type="text" style={{width: "40%"}} name="pvp_bot_wealth$3" onChange={updateArray} defaultValue={state?.pvp_bot_wealth[3]}/></p>
                            <p className="borderless"> 5: <input type="text" style={{width: "40%"}} name="pvp_bot_wealth$4" onChange={updateArray} defaultValue={state?.pvp_bot_wealth[4]}/></p>
                            <p className="borderless"> 6: <input type="text" style={{width: "40%"}} name="pvp_bot_wealth$5" onChange={updateArray} defaultValue={state?.pvp_bot_wealth[5]}/></p>
                            <p className="borderless"> 7: <input type="text" style={{width: "40%"}} name="pvp_bot_wealth$6" onChange={updateArray} defaultValue={state?.pvp_bot_wealth[6]}/></p>
                            <p className="borderless"> 8: <input type="text" style={{width: "40%"}} name="pvp_bot_wealth$7" onChange={updateArray} defaultValue={state?.pvp_bot_wealth[7]}/></p>
                            <p className="borderless"> 9: <input type="text" style={{width: "40%"}} name="pvp_bot_wealth$8" onChange={updateArray} defaultValue={state?.pvp_bot_wealth[8]}/></p>
                            <p className="borderless"> 10: <input type="text" style={{width: "40%"}} name="pvp_bot_wealth$9" onChange={updateArray} defaultValue={state?.pvp_bot_wealth[9]}/></p>
                            <p className="borderless"> 11: <input type="text" style={{width: "40%"}} name="pvp_bot_wealth$10" onChange={updateArray} defaultValue={state?.pvp_bot_wealth[10]}/></p>
                        </td>
                        <td>
                            <p>Оборона</p>
                            <p className="borderless"> 1: <input type="text" style={{width: "40%"}} name="pvp_bot_defence$0" onChange={updateArray} defaultValue={state?.pvp_bot_defence[0]}/></p>
                            <p className="borderless"> 2: <input type="text" style={{width: "40%"}} name="pvp_bot_defence$1" onChange={updateArray} defaultValue={state?.pvp_bot_defence[1]}/></p>
                            <p className="borderless"> 3: <input type="text" style={{width: "40%"}} name="pvp_bot_defence$2" onChange={updateArray} defaultValue={state?.pvp_bot_defence[2]}/></p>
                            <p className="borderless"> 4: <input type="text" style={{width: "40%"}} name="pvp_bot_defence$3" onChange={updateArray} defaultValue={state?.pvp_bot_defence[3]}/></p>
                            <p className="borderless"> 5: <input type="text" style={{width: "40%"}} name="pvp_bot_defence$4" onChange={updateArray} defaultValue={state?.pvp_bot_defence[4]}/></p>
                            <p className="borderless"> 6: <input type="text" style={{width: "40%"}} name="pvp_bot_defence$5" onChange={updateArray} defaultValue={state?.pvp_bot_defence[5]}/></p>
                            <p className="borderless"> 7: <input type="text" style={{width: "40%"}} name="pvp_bot_defence$6" onChange={updateArray} defaultValue={state?.pvp_bot_defence[6]}/></p>
                            <p className="borderless"> 8: <input type="text" style={{width: "40%"}} name="pvp_bot_defence$7" onChange={updateArray} defaultValue={state?.pvp_bot_defence[7]}/></p>
                            <p className="borderless"> 9: <input type="text" style={{width: "40%"}} name="pvp_bot_defence$8" onChange={updateArray} defaultValue={state?.pvp_bot_defence[8]}/></p>
                            <p className="borderless"> 10: <input type="text" style={{width: "40%"}} name="pvp_bot_defence$9" onChange={updateArray} defaultValue={state?.pvp_bot_defence[9]}/></p>
                            <p className="borderless"> 11: <input type="text" style={{width: "40%"}} name="pvp_bot_defence$10" onChange={updateArray} defaultValue={state?.pvp_bot_defence[10]}/></p>
                        </td>
                        <td>
                            <p>Атака</p>
                            <p className="borderless"> 1: <input type="text" style={{width: "40%"}} name="pvp_bot_attack$0" onChange={updateArray} defaultValue={state?.pvp_bot_attack[0]}/></p>
                            <p className="borderless"> 2: <input type="text" style={{width: "40%"}} name="pvp_bot_attack$1" onChange={updateArray} defaultValue={state?.pvp_bot_attack[1]}/></p>
                            <p className="borderless"> 3: <input type="text" style={{width: "40%"}} name="pvp_bot_attack$2" onChange={updateArray} defaultValue={state?.pvp_bot_attack[2]}/></p>
                            <p className="borderless"> 4: <input type="text" style={{width: "40%"}} name="pvp_bot_attack$3" onChange={updateArray} defaultValue={state?.pvp_bot_attack[3]}/></p>
                            <p className="borderless"> 5: <input type="text" style={{width: "40%"}} name="pvp_bot_attack$4" onChange={updateArray} defaultValue={state?.pvp_bot_attack[4]}/></p>
                            <p className="borderless"> 6: <input type="text" style={{width: "40%"}} name="pvp_bot_attack$5" onChange={updateArray} defaultValue={state?.pvp_bot_attack[5]}/></p>
                            <p className="borderless"> 7: <input type="text" style={{width: "40%"}} name="pvp_bot_attack$6" onChange={updateArray} defaultValue={state?.pvp_bot_attack[6]}/></p>
                            <p className="borderless"> 8: <input type="text" style={{width: "40%"}} name="pvp_bot_attack$7" onChange={updateArray} defaultValue={state?.pvp_bot_attack[7]}/></p>
                            <p className="borderless"> 9: <input type="text" style={{width: "40%"}} name="pvp_bot_attack$8" onChange={updateArray} defaultValue={state?.pvp_bot_attack[8]}/></p>
                            <p className="borderless"> 10: <input type="text" style={{width: "40%"}} name="pvp_bot_attack$9" onChange={updateArray} defaultValue={state?.pvp_bot_attack[9]}/></p>
                            <p className="borderless"> 11: <input type="text" style={{width: "40%"}} name="pvp_bot_attack$10" onChange={updateArray} defaultValue={state?.pvp_bot_attack[10]}/></p>
                        </td>
                    </tr></table>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Влияние абилок на характеристики в зависимости от типа пушки</td></tr>
                <tr>
                    <table>
                        <tr><td></td>
                            <td className="td_center">Атк</td>
                            <td className="td_center">Выж</td>
                            <td className="td_center">Скр</td>
                            <td className="td_center">Обр</td>
                            <td className="td_center">Атк</td>
                            <td className="td_center">Выж</td>
                            <td className="td_center">Скр</td>
                            <td className="td_center">Обр</td>
                        </tr>
                        <tr>
                            <td>Автоматчик (штурмовое оружие)</td>
                            <td className="cell_buff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.gunner.buff.atk} name='abilityMap$gunner$buff$atk' className={'input_small'} onChange={updateAbilityMap} /></td>
                            <td className="cell_buff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.gunner.buff.sur} name='abilityMap$gunner$buff$sur' className={'input_small'} onChange={updateAbilityMap} /></td>
                            <td className="cell_buff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.gunner.buff.stl} name='abilityMap$gunner$buff$stl' className={'input_small'} onChange={updateAbilityMap} /></td>
                            <td className="cell_buff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.gunner.buff.def} name='abilityMap$gunner$buff$def' className={'input_small'} onChange={updateAbilityMap} /></td>
                            <td className="cell_debuff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.gunner.debuff.atk} name='abilityMap$gunner$debuff$atk' className={'input_small'} onChange={updateAbilityMap} /></td>
                            <td className="cell_debuff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.gunner.debuff.sur} name='abilityMap$gunner$debuff$sur' className={'input_small'} onChange={updateAbilityMap} /></td>
                            <td className="cell_debuff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.gunner.debuff.stl} name='abilityMap$gunner$debuff$stl' className={'input_small'} onChange={updateAbilityMap} /></td>
                            <td className="cell_debuff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.gunner.debuff.def} name='abilityMap$gunner$debuff$def' className={'input_small'} onChange={updateAbilityMap} /></td>
                        </tr>
                        <tr>
                            <td>Охотник (дробовик)</td>
                            <td className="cell_buff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.hunter.buff.atk} name='abilityMap$hunter$buff$atk' className={'input_small'} onChange={updateAbilityMap} /></td>
                            <td className="cell_buff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.hunter.buff.sur} name='abilityMap$hunter$buff$sur' className={'input_small'} onChange={updateAbilityMap} /></td>
                            <td className="cell_buff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.hunter.buff.stl} name='abilityMap$hunter$buff$stl' className={'input_small'} onChange={updateAbilityMap} /></td>
                            <td className="cell_buff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.hunter.buff.def} name='abilityMap$hunter$buff$def' className={'input_small'} onChange={updateAbilityMap} /></td>
                            <td className="cell_debuff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.hunter.debuff.atk} name='abilityMap$hunter$debuff$atk' className={'input_small'} onChange={updateAbilityMap} /></td>
                            <td className="cell_debuff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.hunter.debuff.sur} name='abilityMap$hunter$debuff$sur' className={'input_small'} onChange={updateAbilityMap} /></td>
                            <td className="cell_debuff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.hunter.debuff.stl} name='abilityMap$hunter$debuff$stl' className={'input_small'} onChange={updateAbilityMap} /></td>
                            <td className="cell_debuff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.hunter.debuff.def} name='abilityMap$hunter$debuff$def' className={'input_small'} onChange={updateAbilityMap} /></td>
                        </tr>
                        <tr>
                            <td>Снайпер (винтовка)</td>
                            <td className="cell_buff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.sniper.buff.atk} name='abilityMap$sniper$buff$atk' className={'input_small'} onChange={updateAbilityMap} /></td>
                            <td className="cell_buff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.sniper.buff.sur} name='abilityMap$sniper$buff$sur' className={'input_small'} onChange={updateAbilityMap} /></td>
                            <td className="cell_buff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.sniper.buff.stl} name='abilityMap$sniper$buff$stl' className={'input_small'} onChange={updateAbilityMap} /></td>
                            <td className="cell_buff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.sniper.buff.def} name='abilityMap$sniper$buff$def' className={'input_small'} onChange={updateAbilityMap} /></td>
                            <td className="cell_debuff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.sniper.debuff.atk} name='abilityMap$sniper$debuff$atk' className={'input_small'} onChange={updateAbilityMap} /></td>
                            <td className="cell_debuff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.sniper.debuff.sur} name='abilityMap$sniper$debuff$sur' className={'input_small'} onChange={updateAbilityMap} /></td>
                            <td className="cell_debuff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.sniper.debuff.stl} name='abilityMap$sniper$debuff$stl' className={'input_small'} onChange={updateAbilityMap} /></td>
                            <td className="cell_debuff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.sniper.debuff.def} name='abilityMap$sniper$debuff$def' className={'input_small'} onChange={updateAbilityMap} /></td>
                        </tr>
                        <tr>
                            <td>Ковбой (пистолет)</td>
                            <td className="cell_buff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.cowboy.buff.atk} name='abilityMap$cowboy$buff$atk' className={'input_small'} onChange={updateAbilityMap} /></td>
                            <td className="cell_buff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.cowboy.buff.sur} name='abilityMap$cowboy$buff$sur' className={'input_small'} onChange={updateAbilityMap} /></td>
                            <td className="cell_buff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.cowboy.buff.stl} name='abilityMap$cowboy$buff$stl' className={'input_small'} onChange={updateAbilityMap} /></td>
                            <td className="cell_buff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.cowboy.buff.def} name='abilityMap$cowboy$buff$def' className={'input_small'} onChange={updateAbilityMap} /></td>
                            <td className="cell_debuff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.cowboy.debuff.atk} name='abilityMap$cowboy$debuff$atk' className={'input_small'} onChange={updateAbilityMap} /></td>
                            <td className="cell_debuff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.cowboy.debuff.sur} name='abilityMap$cowboy$debuff$sur' className={'input_small'} onChange={updateAbilityMap} /></td>
                            <td className="cell_debuff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.cowboy.debuff.stl} name='abilityMap$cowboy$debuff$stl' className={'input_small'} onChange={updateAbilityMap} /></td>
                            <td className="cell_debuff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.cowboy.debuff.def} name='abilityMap$cowboy$debuff$def' className={'input_small'} onChange={updateAbilityMap} /></td>
                        </tr>
                        <tr>
                            <td>Тяжеловес (тяжелое оружие)</td>
                            <td className="cell_buff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.heavy.buff.atk} name='abilityMap$heavy$buff$atk' className={'input_small'} onChange={updateAbilityMap} /></td>
                            <td className="cell_buff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.heavy.buff.sur} name='abilityMap$heavy$buff$sur' className={'input_small'} onChange={updateAbilityMap} /></td>
                            <td className="cell_buff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.heavy.buff.stl} name='abilityMap$heavy$buff$stl' className={'input_small'} onChange={updateAbilityMap} /></td>
                            <td className="cell_buff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.heavy.buff.def} name='abilityMap$heavy$buff$def' className={'input_small'} onChange={updateAbilityMap} /></td>
                            <td className="cell_debuff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.heavy.debuff.atk} name='abilityMap$heavy$debuff$atk' className={'input_small'} onChange={updateAbilityMap} /></td>
                            <td className="cell_debuff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.heavy.debuff.sur} name='abilityMap$heavy$debuff$sur' className={'input_small'} onChange={updateAbilityMap} /></td>
                            <td className="cell_debuff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.heavy.debuff.stl} name='abilityMap$heavy$debuff$stl' className={'input_small'} onChange={updateAbilityMap} /></td>
                            <td className="cell_debuff"><input type='number' style={{width: "40%"}} defaultValue = {state?.abilityMap.heavy.debuff.def} name='abilityMap$heavy$debuff$def' className={'input_small'} onChange={updateAbilityMap} /></td>
                        </tr>
                    </table>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Шансы PVP</td></tr>
                <tr>
                    <td>Шанс получить способность в PvP</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.pvp_ability_chance} name='pvp_ability_chance' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Стартовый баланс</td></tr>
                <tr>
                    <td>Стартовые деньги, монеты</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.initial_money} name='initial_money' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Стартовые деньги, крипта</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.initial_crypto} name='initial_crypto' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Жадность</td></tr>
                <tr>
                    <td>Бонус денег в награде, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.greed_money} name='greed_money' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Увеличение времени, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.greed_time} name='greed_time' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Легкоатлет</td></tr>
                <tr>
                    <td>Уменьшение денег в награде, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.athlete_money} name='athlete_money' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Бонус времени, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.athlete_time} name='athlete_time' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Правая рука</td></tr>
                <tr>
                    <td>Бонус на статы, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.right_hand_bonus} name='right_hand_bonus' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Душа компании</td></tr>
                <tr>
                    <td>Бонус на статы союзникам в групповом рейде, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.soul_bonus} name='soul_bonus' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Дебаф на статы себе, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.soul_debuff} name='soul_debuff' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Одинокий Волк</td></tr>
                <tr>
                    <td>Бонус на статы, если рейд в одиночку, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.wolf_bonus} name='wolf_bonus' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Дебаф на статы, если групповой рейд, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.wolf_debuff} name='wolf_debuff' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Задира</td></tr>
                <tr>
                    <td>Бонус на статы, в атаке, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.zadira_bonus} name='zadira_bonus' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Дебаф на статы, в защите базы, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.zadira_debuff} name='zadira_debuff' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Перестраховщик</td></tr>
                <tr>
                    <td>Бонус на статы, в обороне базы, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.strah_bonus} name='strah_bonus' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Дебаф на статы, в атаке, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.strah_debuff} name='strah_debuff' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Механик</td></tr>
                <tr>
                    <td>Бонус скорости строительства, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.mechanik_buff} name='mechanik_buff' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Умник</td></tr>
                <tr>
                    <td>Бонус к получаемым прибавкам к характеристикам, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.smart_buff} name='smart_buff' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Фельдшер</td></tr>
                <tr>
                    <td>Бонус к скорости лечения, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.medic_buff} name='medic_buff' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Бухгалтер</td></tr>
                <tr>
                    <td>Бонус к деньгам с рейдов, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.accountant_buff} name='accountant_buff' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Ничего лишнего</td></tr>
                <tr>
                    <td>Бонус на скрытность в рейдах, если нет рюкзака, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.lightweight_buff} name='lightweight_buff' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Ленивый</td></tr>
                <tr>
                    <td>Дебаф на получаемые прибавки к характеристикам, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.lazy_debuff} name='lazy_debuff' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Клаустрофоб</td></tr>
                <tr>
                    <td>Дебаф на характеристики в закрытой местности, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.claus_debuff} name='claus_debuff' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Агорафоб</td></tr>
                <tr>
                    <td>Дебаф на характеристики в открытой местности, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.agora_debuff} name='agora_debuff' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Ниндзя</td></tr>
                <tr>
                    <td>Увеличение скрытности (одет только нож) множитель</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.ninja_bonus} name='ninja_bonus' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Мастер Обороны</td></tr>
                <tr>
                    <td>Увеличение характеристик себя в обороне базы, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.master_defense_buff_0} name='master_defense_buff_0' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Бонус к обороне базы, если не учавствует в обороне базы %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.master_defense_buff_1} name='master_defense_buff_1' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Мастер скрытности</td></tr>
                <tr>
                    <td>Уменьшение штрафа скрытности снаряжения, во сколько раз</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.master_stealth_buff} name='master_stealth_buff' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Мастер атаки</td></tr>
                <tr>
                    <td>Увеличение урона одетого оружия, во сколько раз</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.master_attack_buff} name='master_attack_buff' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Мастер выживания</td></tr>
                <tr>
                    <td>Шанс забрать плохое оружие, оч легкий рейд, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.master_survival_very_easy} name='master_survival_very_easy' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Шанс забрать обычное оружие, легкий рейд, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.master_survival_easy} name='master_survival_easy' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Шанс забрать обычное оружие, обычный рейд, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.master_survival_normal} name='master_survival_normal' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Шанс забрать хорошее оружие, тяжелый рейд, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.master_survival_hard} name='master_survival_hard' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Шанс забрать хорошее оружие, экстремальный рейд, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.master_survival_extreme} name='master_survival_extreme' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Дипломат</td></tr>
                <tr>
                    <td>Уменьшение суммы откупа от мародеров, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.diplomat_buff} name='diplomat_buff' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Коеффициенты стоимости найма агентов</td></tr>
                <tr>
                    <td>1й слот</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.slot_cost_koef[0]} name='slot_cost_koef$0' onChange={updateFloatArrayParameter} /></td>
                </tr>
                <tr>
                    <td>2й слот</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.slot_cost_koef[1]} name='slot_cost_koef$1' onChange={updateFloatArrayParameter} /></td>
                </tr>
                <tr>
                    <td>3й слот</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.slot_cost_koef[2]} name='slot_cost_koef$2' onChange={updateFloatArrayParameter} /></td>
                </tr>
                <tr>
                    <td>4й слот</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.slot_cost_koef[3]} name='slot_cost_koef$3' onChange={updateFloatArrayParameter} /></td>
                </tr>
                <tr>
                    <td>5й слот</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.slot_cost_koef[4]} name='slot_cost_koef$4' onChange={updateFloatArrayParameter} /></td>
                </tr>
                <tr>
                    <td>6й слот</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.slot_cost_koef[5]} name='slot_cost_koef$5' onChange={updateFloatArrayParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Казармы</td></tr>
                <tr>
                    <td>Бонус лечения Казармы 2-го уровня %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.barracks_lvl_2_heal_bonus} name='barracks_lvl_2_heal_bonus' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Количество бонусных слотов Казармы 2-го уровня</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.barracks_lvl_2_slots_bonus} name='barracks_lvl_2_slots_bonus' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Штаб</td></tr>
                <tr>
                    <td>Бонус защиты Штаб 1-го уровня %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.hq_lvl_1_defence_bonus} name='hq_lvl_1_defence_bonus' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Бонус защиты Штаб 2-го уровня % (по сравнению с 1м уровнем)</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.hq_lvl_2_defence_bonus} name='hq_lvl_2_defence_bonus' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Бонусные слоты предметов Штаб 2-го уровня</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.hq_lvl_2_bonus_max_items} name='hq_lvl_2_bonus_max_items' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Бонусные слоты агентов Штаб 2-го уровня</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.hq_lvl_2_bonus_agents} name='hq_lvl_2_bonus_agents' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Стены и Вышки</td></tr>
                <tr>
                    <td>Вышка 1 уровня: бонус обороны персонажа в дозоре</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.tower_def_buff} name='tower_def_buff' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Вышка 1 уровня: бонус обороны базы</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.tower_base_def_buff} name='tower_base_def_buff' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Стена 1 уровня: бонус обороны базы</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.wall_base_def_buff} name='wall_base_def_buff' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Обеспечение базы</td></tr>
                <tr>
                    <td>Штаб ур. 1</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.tax_hq_lvl_1} name='tax_hq_lvl_1' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Штаб ур. 2</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.tax_hq_lvl_2} name='tax_hq_lvl_2' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Рынок ур. 1</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.tax_market_lvl_1} name='tax_market_lvl_1' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Мастерская ур. 1</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.tax_workshop_lvl_1} name='tax_workshop_lvl_1' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Казармы ур. 1</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.tax_barracks_lvl_1} name='tax_barracks_lvl_1' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Казармы ур. 2</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.tax_barracks_lvl_2} name='tax_barracks_lvl_2' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Стена ур. 1</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.tax_walls_lvl_1} name='tax_walls_lvl_1' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Башня ур. 1</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.tax_tower_lvl_1} name='tax_tower_lvl_1' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Агент, класс Супер Бомж</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.tax_agent_basic} name='tax_agent_basic' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Агент, класс Бомж</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.tax_agent_rookie} name='tax_agent_rookie' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Агент, класс Специалист</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.tax_agent_specialist} name='tax_agent_specialist' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Агент, класс Эксперт</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.tax_agent_expert} name='tax_agent_expert' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Агент, класс Мастер</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.tax_agent_master} name='tax_agent_master' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Агент, класс Супер Мастер</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.tax_agent_supermaster} name='tax_agent_supermaster' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Агент, класс Супер Бомж, лечение</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.tax_agent_heal_basic} name='tax_agent_heal_basic' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Агент, класс Бомж, лечение</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.tax_agent_heal_rookie} name='tax_agent_heal_rookie' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Агент, класс Специалист, лечение</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.tax_agent_heal_specialist} name='tax_agent_heal_specialist' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Агент, класс Эксперт, лечение</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.tax_agent_heal_expert} name='tax_agent_heal_expert' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Агент, класс Мастер, лечение</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.tax_agent_heal_master} name='tax_agent_heal_master' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Агент, класс Супер Мастер, лечение</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.tax_agent_heal_supermaster} name='tax_agent_heal_supermaster' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Штурмовые винтовки</td></tr>
                <tr>
                    <td>Модификатор атаки, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.assault_rifle_atk_mod} name='assault_rifle_atk_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор выживаемости, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.assault_rifle_sur_mod} name='assault_rifle_sur_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор обороны, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.assault_rifle_def_mod} name='assault_rifle_def_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор скрытности, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.assault_rifle_stl_mod} name='assault_rifle_stl_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Дробовики</td></tr>
                <tr>
                    <td>Модификатор атаки, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.shotgun_atk_mod} name='shotgun_atk_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор выживаемости, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.shotgun_sur_mod} name='shotgun_sur_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор обороны, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.shotgun_def_mod} name='shotgun_def_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор скрытности, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.shotgun_stl_mod} name='shotgun_stl_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Винтовки</td></tr>
                <tr>
                    <td>Модификатор атаки, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.rifle_atk_mod} name='rifle_atk_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор выживаемости, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.rifle_sur_mod} name='rifle_sur_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор обороны, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.rifle_def_mod} name='rifle_def_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор скрытности, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.rifle_stl_mod} name='rifle_stl_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Тяжелое вооружение</td></tr>
                <tr>
                    <td>Модификатор атаки, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.heavy_atk_mod} name='heavy_atk_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор выживаемости, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.heavy_sur_mod} name='heavy_sur_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор обороны, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.heavy_def_mod} name='heavy_def_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор скрытности, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.heavy_stl_mod} name='heavy_stl_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Пистолеты</td></tr>
                <tr>
                    <td>Модификатор атаки, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.pistol_atk_mod} name='pistol_atk_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор выживаемости, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.pistol_sur_mod} name='pistol_sur_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор обороны, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.pistol_def_mod} name='pistol_def_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор скрытности, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.pistol_stl_mod} name='pistol_stl_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Глушители</td></tr>
                <tr>
                    <td>Модификатор атаки, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.muffler_atk_mod} name='muffler_atk_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор выживаемости, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.muffler_sur_mod} name='muffler_sur_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор обороны, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.muffler_def_mod} name='muffler_def_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор скрытности, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.muffler_stl_mod} name='muffler_stl_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Прицелы</td></tr>
                <tr>
                    <td>Модификатор атаки, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.scope_atk_mod} name='scope_atk_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор выживаемости, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.scope_sur_mod} name='scope_sur_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор обороны, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.scope_def_mod} name='scope_def_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор скрытности, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.scope_stl_mod} name='scope_stl_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Экстра боезапас</td></tr>
                <tr>
                    <td>Модификатор атаки, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.extra_ammo_atk_mod} name='extra_ammo_atk_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор выживаемости, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.extra_ammo_sur_mod} name='extra_ammo_sur_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор обороны, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.extra_ammo_def_mod} name='extra_ammo_def_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор скрытности, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.extra_ammo_stl_mod} name='extra_ammo_stl_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Бронебойный боезапас</td></tr>
                <tr>
                    <td>Модификатор атаки, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.bb_ammo_atk_mod} name='bb_ammo_atk_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор выживаемости, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.bb_ammo_sur_mod} name='bb_ammo_sur_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор обороны, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.bb_ammo_def_mod} name='bb_ammo_def_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор скрытности, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.bb_ammo_stl_mod} name='bb_ammo_stl_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Ножи</td></tr>
                <tr>
                    <td>Модификатор атаки, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.knife_atk_mod} name='knife_atk_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор выживаемости, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.knife_sur_mod} name='knife_sur_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор обороны, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.knife_def_mod} name='knife_def_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор скрытности, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.knife_stl_mod} name='knife_stl_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Бинокли</td></tr>
                <tr>
                    <td>Модификатор атаки, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.binoculus_atk_mod} name='binoculus_atk_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор выживаемости, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.binoculus_sur_mod} name='binoculus_sur_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор обороны, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.binoculus_def_mod} name='binoculus_def_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор скрытности, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.binoculus_stl_mod} name='binoculus_stl_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Легкая Броня</td></tr>
                <tr>
                    <td>Модификатор атаки, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.armor_light_atk_mod} name='armor_light_atk_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор выживаемости, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.armor_light_sur_mod} name='armor_light_sur_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор обороны, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.armor_light_def_mod} name='armor_light_def_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор скрытности, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.armor_light_stl_mod} name='armor_light_stl_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Средняя Броня</td></tr>
                <tr>
                    <td>Модификатор атаки, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.armor_middle_atk_mod} name='armor_middle_atk_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор выживаемости, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.armor_middle_sur_mod} name='armor_middle_sur_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор обороны, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.armor_middle_def_mod} name='armor_middle_def_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор скрытности, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.armor_middle_stl_mod} name='armor_middle_stl_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Тяжелая Броня</td></tr>
                <tr>
                    <td>Модификатор атаки, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.armor_heavy_atk_mod} name='armor_heavy_atk_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор выживаемости, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.armor_heavy_sur_mod} name='armor_heavy_sur_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор обороны, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.armor_heavy_def_mod} name='armor_heavy_def_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Модификатор скрытности, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.armor_heavy_stl_mod} name='armor_heavy_stl_mod' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Рюкзаки</td></tr>
                <tr>
                    <td>Легкий: модификатор наград, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.backpack_small_reward_bonus} name='backpack_small_reward_bonus' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Средний: модификатор наград, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.backpack_middle_reward_bonus} name='backpack_middle_reward_bonus' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Тяжелый: модификатор наград, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.backpack_big_reward_bonus} name='backpack_big_reward_bonus' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Тяжелый: модификатор скрытности, %</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.backpack_big_stealth_bonus} name='backpack_big_stealth_bonus' onChange={updateIntParameter} /></td>
                </tr>
                <tr><td style={{border: "1px solid black", backgroundColor: "black", color: "white"}}>Разное</td></tr>
                <tr>
                    <td>Обновление рынка, часы</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.market_refresh_hours} name='market_refresh_hours' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Множитель урона</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.weapon_damage_multiplier} name='weapon_damage_multiplier' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Удаление старых уведомлений, рейдов и пвп, сутки (по умолчанию 7)</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.clean_threshold} name='clean_threshold' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Увеличение налоговой ставки</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.extra_tax_percent} name='extra_tax_percent' onChange={updateIntParameter} /></td>
                </tr>
                <tr>
                    <td>Время списания налога, в формате ЧЧ:ММ (например 03:40), по GMT (-3 Мск)</td>
                    <td><input type='text' defaultValue = {state?.tax_time} name='tax_time' onChange={updateStringParameter} /></td>
                </tr>
                <tr>
                    <td>Таймер до первого нападения мародеров (ч.)</td>
                    <td><input type='number' style={{width: "40%"}} defaultValue = {state?.hours_till_first_marauder} name='hours_till_first_marauder' onChange={updateIntParameter} /></td>
                </tr>
                </tbody>
            </table>
            <Divider sx={{width: "100%", m: 1}}/>
            <Button variant="outlined" onClick={updateState}>Обновить</Button>
        </Grid2>
    );
};

export default BalancePage;