import React, {Dispatch, SetStateAction, useEffect} from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {useAdminState} from "../../../providers/AdminStateProvider";
import {ItemTemplateType} from "../../../types/ItemsTypes";

const ItemTypes = ({type, subtype, uniqueText, setItem}: {type: string, subtype: string, uniqueText: string, setItem: Dispatch<SetStateAction<ItemTemplateType>>}) => {
    const adminState = useAdminState()!!
    const itemTemplates = adminState.getters.getItems()?.filter((it) => it.type === "unique")

    const handleUpdateSubtype = (value: string) => {
        setItem(prevState => ({...prevState, subtype: value}))
    }

    const handleUpdateType = (value: string) => {
        setItem(prevState => ({...prevState, type: value}))
    }

    const getSubtypesByType = {
        ["main_weapon"]: <Select size="small" label="Под-тип" defaultValue={subtype} onChange={(e) => handleUpdateSubtype(e.target.value)}>
            <MenuItem value="pistol">Pistol</MenuItem>
            <MenuItem value="assault_rifle">Assault Rifle</MenuItem>
            <MenuItem value="shotgun">Shotgun</MenuItem>
            <MenuItem value="rifle">Rifle</MenuItem>
            <MenuItem value="heavy_gun">Heavy Gun</MenuItem>
        </Select>,
        ["mod"]: <Select size="small" label="Под-тип" defaultValue={subtype} onChange={(e) => handleUpdateSubtype(e.target.value)}>
            <MenuItem value="scope_l">Scope (L)</MenuItem>
            <MenuItem value="scope_l2">Scope (L2)</MenuItem>
            <MenuItem value="muffler">Muffler</MenuItem>
            <MenuItem value="muffler_2">Muffler (2)</MenuItem>
            <MenuItem value="ammo">Ammo</MenuItem>
            <MenuItem value="ammo_2">Ammo (2)</MenuItem>
            <MenuItem value="scope_k">Scope (K)</MenuItem>
            <MenuItem value="scope_k2">Scope (K2)</MenuItem>
            <MenuItem value="heavy_ammo">Heavy Ammo</MenuItem>
            <MenuItem value="heavy_ammo_2">Heavy Ammo (2)</MenuItem>
            <MenuItem value="double_ammo">Double Ammo</MenuItem>
            <MenuItem value="scope_o">Scope (O)</MenuItem>
            <MenuItem value="scope_o2">Scope (O2)</MenuItem>
        </Select>,
        ["additional_item"]: <Select size="small" label="Под-тип" defaultValue={subtype} onChange={(e) => handleUpdateSubtype(e.target.value)}>
            <MenuItem value="knife">Knife</MenuItem>
            <MenuItem value="binoculus">Binoculus</MenuItem>
        </Select>,
        ["backpack"]: <Select size="small" label="Под-тип" defaultValue={subtype} onChange={(e) => handleUpdateSubtype(e.target.value)}>
            <MenuItem value="backpack_small">Small</MenuItem>
            <MenuItem value="backpack_medium">Medium</MenuItem>
            <MenuItem value="backpack_large">Large</MenuItem>
        </Select>,
        ["armor"]: <Select size="small" label="Под-тип" defaultValue={subtype} onChange={(e) => handleUpdateSubtype(e.target.value)}>
            <MenuItem value="armor_light">Light</MenuItem>
            <MenuItem value="armor_medium">Medium</MenuItem>
            <MenuItem value="armor_heavy">Heavy</MenuItem>
        </Select>,
        ["unique"]: <Select size="small" label="Под-тип" defaultValue={uniqueText} onChange={(e) => handleUpdateSubtype(e.target.value)}>
            {itemTemplates?.map((it, key) => <MenuItem key={key} value={it.unique_text}>{it.unique_text}</MenuItem>)}
        </Select>
    }

    return (
        <Grid2 xs={12} height="100%" justifyContent="center" display="flex" flexDirection="column">
            <FormControl variant="standard">
                <InputLabel>Тип</InputLabel>
                <Select size="small" label="Тип" defaultValue={type} onChange={(e) => handleUpdateType(e.target.value)}>
                    <MenuItem value="main_weapon">Main Weapon</MenuItem>
                    <MenuItem value="armor">Armour</MenuItem>
                    <MenuItem value="backpack">Backpack</MenuItem>
                    <MenuItem value="additional_item">Additional Item</MenuItem>
                    <MenuItem value="mod">Mod</MenuItem>
                    <MenuItem value="unique">Unique</MenuItem>
                </Select>
            </FormControl>
            <FormControl variant="standard">
                <InputLabel>Под-тип</InputLabel>
                {getSubtypesByType[type as keyof typeof getSubtypesByType]}
            </FormControl>
        </Grid2>
    );
};

export default ItemTypes;