import React from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import {useAdminState} from "../../../providers/AdminStateProvider";
import {Paper} from "@mui/material";

const PoolPlaces = () => {
    const adminState = useAdminState()!!
    const places = adminState.getters.getPoolAgents()?.pool.birth_places


    return (
        <>
            {places?.map((it, key) => <Paper key={key} sx={{m: 1, minHeight: "30px"}}>
                <Grid2 xs={12} container>
                    <Grid2 xs={12}>{it.title}</Grid2>
                </Grid2>
            </Paper>)}
        </>
    );
};

export default PoolPlaces;