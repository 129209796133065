import React, {createContext, useContext, useEffect, useState} from 'react';
import {
    ItemListContent,
    listAdminBarItems,
    PageComponentEnum
} from "../../../entities/admin/main_components/constants/AdminLayoutConstants";
import AccountsPage from "../../../pages/admin/subpages/AccountsPage";
import ItemsPage from "../../../pages/admin/subpages/ItemsPage";
import AdbPage from "../../../pages/admin/subpages/AdbPage";
import ControlPage from "../../../pages/admin/subpages/ControlPage";
import PushPage from "../../../pages/admin/subpages/PushPage";
import TranslatesPage from "../../../pages/admin/subpages/TranslatesPage";
import RaidsPage from '../../../pages/admin/subpages/RaidsPage';
import IapPage from "../../../pages/admin/subpages/IapPage";
import BalancePage from "../../../pages/admin/subpages/BalancePage";
import PoolsAgentsPage from "../../../pages/admin/subpages/PoolsAgentsPage";

const TabsContext = createContext<any>(null)

export type PageDataType = {
    name: string,
    path: string,
    component: React.JSX.Element
}



export const TabsProvider = ({children}: {children: any}) => {
    const [tab, setTab] = useState<string>("")
    const [tabs, setTabs] = useState<PageDataType[]>([])

    const getPageById = {
        [PageComponentEnum.accounts]: <AccountsPage/>,
        [PageComponentEnum.items]: <ItemsPage/>,
        [PageComponentEnum.adb]: <AdbPage/>,
        [PageComponentEnum.control]: <ControlPage/>,
        [PageComponentEnum.push]: <PushPage/>,
        [PageComponentEnum.translates]: <TranslatesPage/>,
        [PageComponentEnum.raids]: <RaidsPage/>,
        [PageComponentEnum.iap]: <IapPage/>,
        [PageComponentEnum.balance]: <BalancePage/>,
        [PageComponentEnum.poolsAgent]: <PoolsAgentsPage/>,
    }


    const mapListPages = (list: ItemListContent[]): PageDataType[] => {
        const finalList: PageDataType[] = []

        list.forEach((it) => {
            if(it.component !== undefined && it.children === undefined)
                finalList.push({name: it.name, path: it.path, component: getPageById[it.component!!]})
            if(it.children !== undefined)
                finalList.push(...mapListPages(it.children.data))
        })
        return finalList
    }

    const listPages: PageDataType[] = mapListPages(listAdminBarItems)

    const openTab = (tabName: string, unique?: PageDataType) => {
        if(unique){
            setTabs(prevState => [...prevState, unique])
            setTab(unique.path)
            return;
        }
        const openedTab = tabs.find((it) => it.path === tabName)
        if(openedTab) {
            setTab(openedTab.path)
            return
        }

        const newTab = listPages.find((it) => it.path === tabName)
        if(newTab) {
            setTabs([...tabs, newTab])
            setTab(newTab.path)
        }
    }

    const closeTab = (tabName: string) => {
        setTabs(tabs.filter((it) => it.path !== tabName))
    }

    return (
        <TabsContext.Provider
            value={{
                tab,
                setTab,
                tabs,
                setTabs,
                openTab,
                closeTab
            }}>
            {children}
        </TabsContext.Provider>
    );
};

export const useTabs = () => useContext(TabsContext)