import React, {createContext, useContext, useEffect} from 'react';
import {redirect, useNavigate} from "react-router-dom";
import {getAppUrl} from "../AppCommonParams";

const RequestingContext = createContext<RequestingContextType | null>(null)

export type RequestingContextType = {
    request: {
        get: (url: string, addableOptions?: RequestOption, body?: any) => Promise<Response>,
        post: (url: string, addableOptions?: RequestOption, body?: any) => Promise<Response>
    }
    authorizeRequest: {
        get: (url: string, addableOptions?: RequestOption, body?: any) => Promise<Response>,
        post: (url: string, addableOptions?: RequestOption, body?: any) => Promise<Response>
    }
}

type RequestOption = {
    mode?: RequestMode,
    redirect?: RequestRedirect,
    requestType?: RequestType,
    headers?: {
        contentType?: string,
        authorization?: boolean,
        accept?: string
    }
}

enum RequestType {
    POST = 'POST',
    GET = 'GET',
    PUT = 'PUT',
    DELETE = 'DELETE'
}

export const RequestingProvider = ({children}: {children: any}) => {
    const navigate = useNavigate()
    const urlApp = getAppUrl()

    const getValueFromCookie = (name: string) => {
        const cookiesPair = document.cookie.split(";")
        let value
        cookiesPair.find((it) => {
            const pair = it.split("=")
            const key = pair[0].replace(" ", "")
            if(key === name){
                value = pair[1]
            }
        })
        return value
    }

    const requestSample = (url: string, options: RequestOption, body?: any) => {
        const headers = new Headers({
            // "Access-Control-Allow-Origin": "*",
            'Accept': options.headers?.accept ? options.headers.accept : 'application/json',
            'Content-Type': options.headers?.contentType ? options.headers.contentType : 'application/json;charset=utf-8', //;charset=UTF-8;
        })

        if(options.headers?.authorization)
            headers.append('Authorization', `Bearer ${getValueFromCookie('token')}`)

        return fetch(`${urlApp}${url}` , {
            mode: options.mode ? options.mode : "cors",
            method: options.requestType?.valueOf(),
            redirect: options.redirect ? options.redirect : "follow",
            headers: headers,
            body: JSON.stringify(body)
        }).then((res: Response) => {
            if (res.status === 401 && options.headers?.authorization === true) {
                navigate("/login")
                return new Response()
            }
            return res
        })
    }

    const request = {
        post: (url: string, addableOptions?: RequestOption, body?: any) =>
            requestSample(
                url,
                {
                    requestType: RequestType.POST,
                    ...addableOptions
                },
                body
            ),
        get: (url: string, addableOptions?: RequestOption, body?: any) =>
            requestSample(
                url,
                {
                    requestType: RequestType.GET,
                    ...addableOptions
                },
                body
            )
    }

    const authorizeRequest = {
        post: (url: string, addableOptions?: RequestOption, body?: any) => request.post(url,
            {
                ...addableOptions,
                headers: {
                    ...addableOptions?.headers,
                    authorization: true
                }
            },
            body
        ),
        get: (url: string, addableOptions?: RequestOption, body?: any) => request.get(url,
            {
                ...addableOptions,
                headers: {
                    ...addableOptions?.headers,
                    authorization: true
                }
            },
            body
        )
    }





    return (
        <RequestingContext.Provider
            value={{
                request,
                authorizeRequest
            }}
        >
            {children}
        </RequestingContext.Provider>
    );
};

export const useRequesting = () => useContext(RequestingContext)

