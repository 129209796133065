import AccountStorage from "./accounts_content/AccountStorage";
import SeparationComponentSample from "./SeparationComponentSample";
import AccountRaids from "./accounts_content/AccountRaids";
import AccountAgents from "./accounts_content/AccountAgents";
import AccountBase from "./accounts_content/AccountBase";
import AccountControl from "./accounts_content/AccountControl";
import AccountPvp from "./accounts_content/AccountPvp";
import PoolNames from "./pool_agents_content/PoolNames";
import PoolPlaces from "./pool_agents_content/PoolPlaces";
import PoolBios from "./pool_agents_content/PoolBios";
import PoolPresets from "./pool_agents_content/PoolPresets";
import AccountPurchases from "./accounts_content/AccountPurchases";

export const componentOfAccounts: ComponentOfSeparationType[] = [
    {
        id: "storage",
        label: "Склад",
        component: (val) => <SeparationComponentSample isActive={val} children={<AccountStorage/>}/>
    },
    {
        id: "raids",
        label: "Рейды",
        component: (val) => <SeparationComponentSample isActive={val} children={<AccountRaids/>}/>
    },
    {
        id: "pvp",
        label: "PVP",
        component: (val) => <SeparationComponentSample isActive={val} children={<AccountPvp/>}/>
    },
    {
        id: "agents",
        label: "Агенты",
        component: (val) => <SeparationComponentSample isActive={val} children={<AccountAgents/>}/>
    },
    {
        id: "control",
        label: "Контроль",
        component: (val) => <SeparationComponentSample isActive={val} children={<AccountControl/>}/>
    },
    {
        id: "base",
        label: "База",
        component: (val) => <SeparationComponentSample isActive={val} children={<AccountBase/>}/>
    },
    {
        id: "purchases",
        label: "Покупки",
        component: (val) => <SeparationComponentSample isActive={val} children={<AccountPurchases/>}/>
    }
    // {
    //     id: "offers",
    //     label: "Офферы"
    // } todo
]

export const componentOfPoolAgents: ComponentOfSeparationType[] = [
    {
        id: "names",
        label: "Имена",
        component: (val) => <SeparationComponentSample isActive={val} children={<PoolNames/>}/>
    },
    {
        id: "places",
        label: "Места",
        component: (val) => <SeparationComponentSample isActive={val} children={<PoolPlaces/>}/>
    },
    {
        id: "bio",
        label: "Биографии",
        component: (val) => <SeparationComponentSample isActive={val} children={<PoolBios/>}/>
    },
    {
        id: "presets",
        label: "Пресеты",
        component: (val) => <SeparationComponentSample isActive={val} children={<PoolPresets/>}/>
    }
]



export type ComponentOfSeparationType = {
    id: string
    label: string,
    component?: (val: boolean) => React.JSX.Element
}

