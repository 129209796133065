import React, {useEffect, useState} from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import AgentСharacteristics from "./account_agents/AgentСharacteristics";
import AgentAbilities from "./account_agents/AgentAbilities";
import {Button, Paper, Typography} from "@mui/material";
import AgentStatus from "./account_agents/AgentStatus";
import {useAdminState} from "../../../providers/AdminStateProvider";

export type AgentAbilityType = {
    id: string,
    title: string,
    ability?: string
}

export type AgentAttributesType = {
    attack: number,
    defence: number,
    stealth: number,
    survival: number
}

export type AgentWithDetails = {
    agent: AgentType,
    details: any
}

export type AgentType = {
    id: string,
    abilities: AgentAbilityType[],
    attributes_base: AgentAttributesType,
    name: string,
    name_original: string,
    portrait_big: string,
    portrait_small: string,
    status: string,
    type: string
}

const AccountAgents = () => {
    const [agents, setAgents] = useState<AgentWithDetails[]>([])
    const adminState = useAdminState()!!
    const account = adminState.getters.getAccount()

    useEffect(() => {
        if(account?.accountDetail){
            setAgents(account.accountDetail.agents)
        }
    }, [account?.accountDetail])

    return (
        <>
            {agents.map((it, key) => <Paper key={key} sx={{padding: 2, backgroundColor: "background.paper"}} elevation={2}>
                <Grid2 container xs={12} spacing={1}>
                    <Grid2 xs={3}>
                        <img width="80%" src={`/images/uploaded/${it.agent.portrait_big}`}/>
                    </Grid2>
                    <Grid2 xs={3}>
                        <AgentСharacteristics data={it.agent.attributes_base} agent={it.agent}/>
                    </Grid2>
                    <Grid2 xs={2}>
                        <Grid2 xs={12}>Предмет</Grid2>
                        {it.details.items[0] && <Grid2 xs={12}><img width="50%" src={`/images/uploaded/${it.details.items[0].image}`}/></Grid2>}
                    </Grid2>
                    <Grid2 xs={2}>
                        <AgentAbilities abilities={it.agent.abilities} agentId={it.agent.id}/>
                    </Grid2>
                    <Grid2 xs={2}>
                        <AgentStatus agent={it.agent}/>
                    </Grid2>
                </Grid2>
            </Paper>)}
        </>
    );
};

export default AccountAgents;