import React from 'react'
import { BrowserView, MobileView, isBrowser, isMobile, isWindows } from 'react-device-detect';
import YoutubeBackground from 'react-youtube-background'
import classnames from 'classnames';
import VideoBg from "reactjs-videobg";
import bg from '../../../../../assets/bg_header.webp'
import mp4_1920 from '../../../../../assets/video/1920x1080.mp4';
import mp4_1600 from '../../../../../assets/video/1600x1200.mp4';
import webm_1920 from '../../../../../assets/video/1920x1080.webm';
import webm_1600 from '../../../../../assets/video/1600x1200.webm';
import ogg_1920 from '../../../../../assets/video/1920x1080.ogg';
import ogg_1600 from '../../../../../assets/video/1600x1200.ogg';
import mov_1920 from '../../../../../assets/video/1920x1080.mov';
import mov_1600 from '../../../../../assets/video/1600x1200.mov';
import logo from '../../../../../assets/logo.svg'
import logo_mobile from '../../../../../assets/logo_mobile.svg'
import { AppStore } from '../../buttons/AppStore';
import { GooglePlay } from '../../buttons/GooglePlay';

export const Header = (props) => {
    const aspectRatio = 1920/1080;
    const containerWidth = window.innerWidth
    const containerHeight = window.innerHeight
    const containerAspectRatio = containerWidth / containerHeight

    let videoHeight = containerHeight
    let videoWidth = containerWidth + ( isWindows ? - 17 : 0 )
    let videoY = 0
    let videoX = 0

    if (containerAspectRatio > aspectRatio) {
        videoHeight = (videoWidth / aspectRatio)
        videoY = (videoHeight - containerHeight) / -2
    } else {
        videoHeight = videoWidth / aspectRatio
        videoX = (videoWidth - containerWidth) / -2
    }

    console.log(containerAspectRatio, aspectRatio, videoWidth, videoHeight, videoX, videoY);

    return (
        (
            props.VideoBg
                ?
                    <div style={{
                        position: 'relative'
                    }}>
                        {/* <VideoBg> */}
                            {/* <VideoBg.Source src={ogg_1600} type="video/ogg" /> */}
                            {/* { isBrowser && <VideoBg.Source src={webm_1600} type="video/webm" /> } */}
                            {/* <VideoBg.Source src={mp4_1600} type="video/mp4" /> */}
                        {/* </VideoBg> */}

                        <video autoPlay playsInline loop muted style={{
                            position: 'absolute',
                            width: `${videoWidth}px`,
                            height: `${videoHeight}px`
                        }}>
                            <source src={webm_1920} type="video/webm" />
                            <source src={mp4_1920} type="video/mp4" />
                            <source src={mov_1920} type="video/mp4" />
                        </video>


                        <header style={{
                            position: 'relative',
                            height: `${videoHeight}px`,
                            backgroundColor: 'rgba(0,0,0,.4)'
                        }}>
                            <div className="container video">
                                <img
                                    style={{
                                        position: 'absolute',
                                        top: isBrowser ? '37px' : '18px',
                                        left: isBrowser ? '52px' : '26px'
                                    }} src={isBrowser ? logo : logo_mobile}
                                    width={isBrowser ? 204.54 : 24}
                                    height={isBrowser ? 57.17 : 24}
                                    alt='Five Plus Games. LCC' />
                                {isBrowser && <div style={{
                                    position: 'absolute',
                                    top: '37px',
                                    right: '52px',
                                    display: 'flex',
                                    flexDirection: 'row'
                                }} >
                                    <AppStore />
                                    <GooglePlay />
                                </div>}
                            </div>
                        </header>
                    </div>
                : <YoutubeBackground
                    videoId="SiQdqt3KJew"
                    overlay="rgba(0,0,0,.4)"
                >
                    <header style={{
                        position: 'relative',
                        height: `${videoHeight}px`
                    }}>
                        <div className="container video">
                            <img style={{
                                position: 'absolute',
                                top: '37px',
                                left: '52px'
                            }} src={logo} width={204.54} height={57.17} alt='Five Plus Games. LCC' />
                            <div style={{
                                position: 'absolute',
                                top: '37px',
                                right: '52px',
                                display: 'flex',
                                flexDirection: 'row'
                            }} >
                                <AppStore />
                                <GooglePlay />
                            </div>
                        </div>
                    </header>
                </YoutubeBackground>
        //     :
        // <>
        //     <BrowserView>
        //         <HeaderBrowserView>
        //             <HeaderContent />
        //         </HeaderBrowserView>
        //     </BrowserView>

        //     <MobileView>
        //         <HeaderMobileView>
        //             <HeaderContent />
        //         </HeaderMobileView>
        //     </MobileView>
        // </>
        )
    );
}

const HeaderBrowserView = (props) => {

    return (
        <header style={{
            position: 'relative',
        }}>
            <img src={bg} width='100%' alt='Five Plus Games. LCC' />
            <img style={{
                position: 'absolute',
                top: '37px',
                left: '52px'
            }} src={logo} width={204.54} height={57.17} alt='Five Plus Games. LCC' />
            <div className="container">
                {props.children}
            </div>
        </header>
    )
}

const HeaderMobileView = (props) => {

    return (
        <header style={{
            position: 'relative',
            backgroundImage: `url(${bg})`,
            minHeight: '300px',
            backgroundSize: 'cover',
            backgroundPosition: 'center'
        }}>
            <img style={{
                position: 'absolute',
                top: '18px',
                left: '26px'
            }} src={logo_mobile} width={42} height={42} alt='Five Plus Games. LCC' />
            {props.children}
        </header>
    )
}

const HeaderContent = (props) => {
    return (
        <div className={classnames({
            content: true,
            mobile: isMobile,
            browser: isBrowser
        })} style={props.style}>
            <h1 className={classnames({
                mobile: isMobile,
                browser: isBrowser
            })}>Battle for life</h1>
            <div className={classnames({
                'button-container': true,
                mobile: isMobile,
                browser: isBrowser
            })}>
                <AppStore />
                <GooglePlay />
            </div>
        </div>
    )
}