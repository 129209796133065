import React from 'react'
import { isBrowser, isMobile } from 'react-device-detect'
import classnames from 'classnames'
import bg from '../../../../../assets/bg_description.webp'
import { AppStore } from '../../buttons/AppStore'
import { GooglePlay } from '../../buttons/GooglePlay'

export const Description = (props) => {

    return (
        <section className={classnames({
            description: true,
            mobile: isMobile,
            browser: isBrowser
        })} style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
        }}>
            <div className={classnames({
                container: true,
                mobile: isMobile,
                browser: isBrowser
            })}>
                <h1 className={classnames({
                    mobile: isMobile,
                    browser: isBrowser
                })}>Battle for life</h1>
                <p>Panic and devastation are everywhere,<br />there is no way out!</p>
                <p style={{
                    paddingLeft: isBrowser ? '144px' : '42px',
                }}>The supplies are limited and the area is overcrowded with hungry and armed people.</p>
                <p style={{
                    margin: isBrowser ? '50px 0' : '20px 0'
                }}>Exactly in these circumstances the protagonist finds himself and decides to survive at all costs.</p>
                <p>Whose side are you on: government troops or resistance forces?</p>
                <p style={{
                    paddingLeft: isBrowser ? '144px' : '42px',
                }}>Will you be able to hold out and unite like-minded people in difficult conditions of the civil war?</p>
                {isMobile && <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    gap: '20px',
                    margin: '20px'
                }} >
                    <AppStore />
                    <GooglePlay />
                </div>}
            </div>
        </section>
    )
}