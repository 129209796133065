import React from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";

const ItemName = ({name}: {name: string}) => {
    return (
        <Grid2 xs={12} textAlign="center">
            {name}
        </Grid2>
    );
};

export default ItemName;