import React from 'react'
import { isBrowser, isMobile } from 'react-device-detect';
import classnames from 'classnames';
import btn_google_play from '../../../../assets/btn_google_play.png'

export const GooglePlay = (props) => {
    return (
        <a className={classnames({
            button: true,
            mobile: isMobile,
            browser: isBrowser
        })} style={props.style} target="_blank" href='https://play.google.com/store/apps/details?id=games.fiveplus.bfl' rel="noreferrer">
            <img src={btn_google_play} alt='Google Play' />
        </a>
    );
}