import React, {useEffect, useState} from 'react';
import {
    Button,
    Container,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {useRequesting} from "../../../../../app/providers/RequestingProvider";
import {useAdminState} from "../../../providers/AdminStateProvider";

type ItemTemplate = {title: string, id: string}

export type Item = {title: string, image: string, id: string}

const AccountStorage = () => {
    const request = useRequesting()?.authorizeRequest!!
    const [item, setItem] = useState<string | undefined>("")

    const [storageItems, setStorageItems] = useState<Item[]>([])
    const [templatesItem, setTemplatesItem] = useState<ItemTemplate[]>([])

    const account = useAdminState()?.getters.getAccount()
    const adminState = useAdminState()!!


    const addItem = () => {
        request.post(`admin/accounts/${account?.selectedAcc}/create_item/${item}`)
            .then((res) => res.json())
            .then(adminState.refreshers.refreshAccount)
    }

    const deleteItem = (id: string) => {
        request.post(`admin/items/${id}/delete`).then(adminState.refreshers.refreshAccount)
    }

    useEffect(() => {
        request.get(`admin/item_templates`)
            .then((res) => res.json())
            .then((res) => {
                setTemplatesItem(res.items.map((it: ItemTemplate) => ({title: it.title, id: it.id})))
            })
    }, [])


    useEffect(() => {
        if(account?.accountDetail)
            setStorageItems(account?.accountDetail.items.map((it: any) => ({title: it.title, image: it.image, id: it.id})))
    }, [account?.accountDetail])

    return (
        <>
            <FormControl variant="standard" sx={{width: "50%"}}>
                <InputLabel sx={{color: "text.disabled"}}>Предмет</InputLabel>
                <Select label="Предмет" onChange={(it) => setItem(it.target.value as string)}>
                    {templatesItem.map((it, key) => <MenuItem key={key} value={it.id}>{it.title}</MenuItem>)}
                </Select>
            </FormControl>
            <TextField variant="standard" label="Количество"/>
            <Button variant="outlined" size="small" sx={{marginTop: 2}} onClick={addItem}>Добавить</Button>
            <Typography variant="h4" m={2}>Склад</Typography>
            <Grid2 container xs={12} spacing={1}>
                {storageItems?.map((it, key) =>
                    <Grid2 key={key} xs={2}>
                        <Paper sx={{padding: 1}}>
                            <img width="100%" src={`/images/uploaded/${it.image}`}/>
                            <Typography variant="h6">{it.title}</Typography>
                            <Button variant="outlined" color="error" onClick={() => deleteItem(it.id)}>Удалить</Button>
                        </Paper>
                    </Grid2>
                )}
            </Grid2>
        </>
    );
};

export default AccountStorage;