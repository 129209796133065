import React from 'react';
import './App.css';
import './landing/index.scss'
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {SizeableProvider} from "./app/providers/SizeableProvider";
import ContentLayoutAdmin from "./widgets/admin/main_components/ContentLayoutAdmin";
import {createTheme, ThemeOptions, ThemeProvider} from "@mui/material";
import LoginPage from "./pages/auth/LoginPage";
import {AuthenticateProvider} from './app/providers/AuthenticateProvider';
import {RequestingProvider} from "./app/providers/RequestingProvider";
import PrivacyPolicy from './pages/landing_page/PrivacyPolicy';
import TermsOfUse from "./pages/landing_page/TermsOfUse";
import UkassaPage from "./pages/landing_page/UkassaPage";
import UkassaRedirect from "./pages/landing_page/UkassaRedirect";
import {Home} from "./landing/areas/shared/pages";
import SubscriptionTerms from "./pages/landing_page/SubscriptionTerms";

function App() {
    const themeOptions: ThemeOptions = {
        palette: {
            primary: {
                main: '#646464'
            },
            secondary: {
                main: '#000000',
            },
            info: {
                main: '#723a3a',
            },
            text: {
                primary: 'rgba(255,255,255)',
                secondary: 'rgb(108,108,108)',
                disabled: '#000000',
            },
            background: {
                default: '#a9a9a9',
                paper: '#232323',
            }
        },
    };
    const theme = createTheme(themeOptions)

    return (
        <ThemeProvider theme={theme}>
        <BrowserRouter>
            <SizeableProvider>
                <RequestingProvider>
                <AuthenticateProvider>
                <Routes>
                    <Route path="/">
                        <Route path="" element={<Home/>}/>
                        <Route path="/login" element={<LoginPage/>}/>
                        <Route path="/admin" element={<ContentLayoutAdmin/>}/>
                        <Route path="/policy">
                            <Route path="terms_of_use" element={<TermsOfUse/>}/>
                            <Route path="privacy_policy" element={<PrivacyPolicy/>}/>
                            <Route path="subscription_terms" element={<SubscriptionTerms/>}/>
                        </Route>
                        <Route path="/iap">
                            <Route path="ukassa" element={<UkassaPage/>}/>
                            <Route path="redirect_ukassa" element={<UkassaRedirect/>}/>
                        </Route>
                    </Route>
                </Routes>
                </AuthenticateProvider>
                </RequestingProvider>
            </SizeableProvider>
        </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
