import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Box, BoxProps, Button, Grid, IconButton} from "@mui/material";
import Grid2 from '@mui/material/Unstable_Grid2';
import {useAdminState} from "../../../widgets/admin/providers/AdminStateProvider";
import {useTabs} from "../../../widgets/admin/providers/TabsProvider";
import {RaidType} from "../../../widgets/admin/types/RaidsTypes";
import RaidPage from "../addable_pages/RaidPage";

const RaidsPage = () => {
    const [backgroundPosition, setBackgroundPosition] = useState({x: 0, y: 0})
    const [mousePos, setMousePos] = useState<undefined | {x: number, y: number}>(undefined)
    const imageSize = {
        x: 3021,
        y: 1917
    }

    const adminState = useAdminState()!!
    const raids = adminState.getters.getRaids()
    const tabProvider = useTabs()

    const parent = useRef<HTMLDivElement>()
    const map = useRef<HTMLDivElement>()

    const mouseMoveListener = useCallback((e: MouseEvent) => {
        if(mousePos) {
            setBackgroundPosition(prevState => ({y: prevState.y + (e.pageY - parent.current?.offsetTop!! - mousePos.y)/100, x: prevState.x + (e.pageX - parent.current?.offsetLeft!! - mousePos.x)/100}))
        }
    }, [mousePos])

    useEffect(() => {
        if (mousePos){
            map.current?.addEventListener('mousemove', mouseMoveListener)
        }
    }, [mousePos])

    // @ts-ignore
    const onMouseDown = (event: MouseEvent<HTMLDivElement>) => {
        if(event.target === map.current)
        setMousePos({x: event.pageX - parent.current?.offsetLeft!!, y: event.pageY - parent.current?.offsetTop!!})
    }

    const onMouseUp = () => {
        map.current?.removeEventListener('mousemove', mouseMoveListener)
        setMousePos(undefined)
    }

    const openRaid = (raid: RaidType) => {
        const name = new Map(Object.entries(raid.title.value)).get("en")

        tabProvider.openTab("Some name", {
            name: name,
            path: name,
            component: <RaidPage initialRaid={raid}/>
        })
    }

    // @ts-ignore
    const handleScroll = (e: UIEvent<HTMLDivElement>) => {
        console.log(e)
    }

    return (
        <Box height="700px" ref={parent} sx={{border: '1px solid black', position: "relative", overflow: "hidden"}}>
            <Box
                ref={map}
                onMouseUp={() => onMouseUp()}
                onMouseLeave={() => onMouseUp()}
                onMouseDown={(e) => onMouseDown(e)}
                onScroll={handleScroll}
                style={{
                    left: backgroundPosition.x,
                    top: backgroundPosition.y,
                    backgroundImage: `url("/admin/img/Map_bg.png")`,
                    position: "absolute",
                    height: imageSize.y + "px",
                    width: imageSize.x + "px",
                    backgroundRepeat: "no-repeat",
                }}
            >

                {raids?.map((it, key) =><IconButton
                    onClick={() => openRaid(it)}
                    sx={{
                        width: "auto",
                        heigth: "auto",
                        m: 0,
                        p: 0,
                        position: "absolute",
                        top: it.y + "px",
                        left: it.x + "px",
                        backgroundSize: "cover",
                    }}
                    >
                    <Box key={key}>
                        <img  src={`/images/uploaded/${it.image}`}/>
                    </Box>
                    </IconButton>
                )}
            </Box>
        </Box>
    );
};

export default RaidsPage;