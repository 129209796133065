import React, {useEffect, useState} from 'react';
import {AgentAbilityType} from "../AccountAgents";
import Grid2 from "@mui/material/Unstable_Grid2";
import {Box, Button, Divider, IconButton, Modal, Typography} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import {useRequesting} from "../../../../../../app/providers/RequestingProvider";
import {useSizeableContext} from "../../../../../../app/providers/SizeableProvider";
import {AdminStateTypeActions, useAdminState} from "../../../../providers/AdminStateProvider";

const AgentAbilities = ({abilities, agentId}: {abilities: AgentAbilityType[], agentId: string}) => {
    const request = useRequesting()?.authorizeRequest!!
    const sizeableProvider = useSizeableContext()
    const adminState = useAdminState()!!
    const account = adminState.getters.getAccount()

    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [templateAbilities, setTemplateAbilities] = useState<AgentAbilityType[]>([])

    useEffect(() => {
        request.get(`admin/strings`).then((res) => res.json())
            .then((res) => setTemplateAbilities(res))
    }, [])

    const deleteAbility = (id: string) => {
        request.post(`admin/agents/${agentId}/remove_ability/${id}`).then(adminState?.refreshers.refreshAccount)
    }

    const addAbility = (id: string) => {
        request.post(`admin/agents/${agentId}/add_ability/${id}`).then(adminState?.refreshers.refreshAccount)
        handleCloseModal()
    }

    const handleOpenModal = () => {
        setIsOpen(true)
    }

    const handleCloseModal = () => {
        setIsOpen(false)
    }

    return (
        <>
            <Grid2 xs={12} container>
                <Grid2 xs={12}>
                    <Typography>Способности</Typography>
                </Grid2>
                {abilities.map((it, key) => <Grid2 key={key} xs={4}>
                    <img width="80%" src={`/images_abilities/a_${it.id}.png`}/>
                    <IconButton sx={{border: '1px solid gray'}}>
                        <DeleteIcon color="error" onClick={() => deleteAbility(it.id)}/>
                    </IconButton>
                </Grid2>)}
            </Grid2>
            <Divider sx={{margin: 1}}/>
            <Grid2 xs={12}>
                <Button size="small" variant="outlined" onClick={handleOpenModal}>Добавить</Button>
                <Modal open={isOpen} onClose={handleCloseModal}>
                    <Box
                        position="absolute"
                         top="50%"
                         left="50%"
                         width={sizeableProvider.isSmall ? "80%" : "60%"}
                        height="80%"
                         bgcolor="background.default"
                         boxShadow={24}
                         p={4}
                         sx={{
                             transform: "translate(-50%, -50%)",
                             color: "text.disabled"
                         }}
                         display="flex"
                         alignItems="center"
                         flexDirection="column"
                        overflow="scroll"
                    >
                        <Typography>Способности</Typography>
                        <Grid2 textAlign="center" container xs={12}>
                            {templateAbilities.map((it, key) => <Grid2 key={key} xs={3}>
                                <Button onClick={() => addAbility(it.id)} sx={{padding: 0, margin: 0, width: "100%", display: "flex", flexDirection: "column"}}>
                                    <Grid2 xs={12}>
                                        <Typography>{it.title}</Typography>
                                    </Grid2>
                                    <Grid2 xs={12}>
                                        <img width="100%" src={`/images_abilities/a_${it.id}.png`} alt={it.title}/>
                                    </Grid2>
                                </Button>
                            </Grid2>)}
                        </Grid2>
                    </Box>
                </Modal>
            </Grid2>
        </>
    );
};

export default AgentAbilities;