import React, { useState} from 'react';
import {useRequesting} from "../../../app/providers/RequestingProvider";
import {useAdminState} from "../../../widgets/admin/providers/AdminStateProvider";
import {
    Box,
    FormControl,
    InputLabel,
    List,
    ListItemButton,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    TextField
} from "@mui/material";
import {ComponentOfSeparationType} from "../../../widgets/admin/main_components/content/Constants";
import SeparationComponentSample from "../../../widgets/admin/main_components/content/SeparationComponentSample";
import OfferSubPage from "../../../widgets/admin/main_components/content/purchases_content/OfferSubPage";
import PurchaseSubPage from "../../../widgets/admin/main_components/content/purchases_content/PurchaseSubPage";
import SubscriptionSubPage from "../../../widgets/admin/main_components/content/purchases_content/SubscriptionSubPage";

const IapPage = () => {
    const request = useRequesting()?.authorizeRequest!!
    const adminState = useAdminState()!!
    const [selectedComponent, setSelectedComponent] = useState<undefined | string>(undefined)
    const listComponents: ComponentOfSeparationType[] = [
        {
            id: "purchases",
            label: "Покупки",
            component: (val) => <SeparationComponentSample isActive={val} children={<PurchaseSubPage/>}/>
        },
        {
            id: "offers",
            label: "Офферы",
            component: (val) => <SeparationComponentSample isActive={val} children={<OfferSubPage/>}/>
        },
        {
            id: "subscriptions",
            label: "Подписки",
            component: (val) => <SeparationComponentSample isActive={val} children={<SubscriptionSubPage/>}/>
        }
    ]

    const selectComponent = (value: string | undefined) => {
        setSelectedComponent(value)
    }

    return (
        <>
            <Box width="100%" display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center">
                <List
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "center"
                    }}
                >
                    {
                        listComponents.map((it, key) => <ListItemButton
                            key={key}
                            id={it.id}
                            onClick={() => selectComponent(it.id)}
                        >
                            <ListItemText>{it.label}</ListItemText>
                        </ListItemButton>)
                    }
                </List>
            </Box>
            {listComponents.map((it) => {
                return it.component!!(selectedComponent === it.id && selectedComponent !== undefined)
            })}
    </>
    );
};

export default IapPage;