import React from 'react'
import {isBrowser, isMobile} from 'react-device-detect'
import classnames from 'classnames'
import map from '../../../../../assets/map.jpg'
import map_mobile from '../../../../../assets/map_mobile.jpg'
import discord from '../../../../../assets/discord.svg'
import email from '../../../../../assets/email.svg'
import telegram from '../../../../../assets/telegram.svg'

export const Connections = (props) => {
    return (
        <section className={classnames({
            connections: true,
            mobile: isMobile,
            browser: isBrowser
        })}>
            <div className={classnames({
                container: true,
                mobile: isMobile,
                browser: isBrowser
            })}>
                <div>
                    <img className='map' src={isBrowser ? map : map_mobile} alt='connections' />
                </div>
                <div className='info-container'>
                    <h2>Connections</h2>
                    <p>Five Plus Games. LLC</p>
                    <p>
                        <span></span>
                        <span>Technopark Building, 1/2 Gorky St, Bishkek, 720082, Kyrgyz Republic</span>
                    </p>
                    <div className='link'>
                        <a href='mailto:info@5plus.games'><img src={email} alt='email'/></a>
                        <a href='https://discord.gg/7znJ5XekdJ' target='_blank'><img src={discord} alt='discord' /></a>
                        <a href='https://t.me/+FioY4lfZc1thZGY0' target='_blank'><img src={telegram} alt='telegram' /></a>
                    </div>
                </div>
            </div>
        </section>
    )
}