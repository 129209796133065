import React, {useEffect, useRef} from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import {Button, TextField} from "@mui/material";
import {useRequesting} from "../../../../../../app/providers/RequestingProvider";
import {useAdminState} from "../../../../providers/AdminStateProvider";

const AccountStats = () => {
    const request = useRequesting()?.authorizeRequest!!
    const adminState = useAdminState()!!
    const account = adminState.getters.getAccount()

    const bucks = useRef<number | any>()
    const crypts = useRef<number | any>()
    const government = useRef<number | any>()
    const opposition = useRef<number | any>()

    const updateBalance = () => {
        request.post(`admin/accounts/${account?.selectedAcc}/refresh_balances`, {}, {
            balance_money: bucks.current.value,
            balance_crypto: crypts.current.value,
            reputation_government: government.current.value,
            reputation_opposition: opposition.current.value
        })
    }

    useEffect(() => {
        if(account?.accountDetail){
            bucks.current.value = account?.accountDetail.balance_money
            crypts.current.value = account?.accountDetail.balance_crypto
            government.current.value = account?.accountDetail.reputation_government
            opposition.current.value = account?.accountDetail.reputation_opposition
        }
    }, [account?.accountDetail])

    return (
        <>
            <Grid2 xs={12} container>
                <Grid2 xs={6}>
                    Деньги:
                </Grid2>
                <Grid2 xs={6}>
                    <TextField inputRef={bucks} type="number" size="small"/>
                </Grid2>
            </Grid2>
            <Grid2 xs={12} container>
                <Grid2 xs={6}>
                    Крипта:
                </Grid2>
                <Grid2 xs={6}>
                    <TextField inputRef={crypts} type="number" size="small"/>
                </Grid2>
            </Grid2>
            <Grid2 xs={12} container>
                <Grid2 xs={6}>
                    Правительство:
                </Grid2>
                <Grid2 xs={6}>
                    <TextField inputRef={government} type="number" size="small"/>
                </Grid2>
            </Grid2>
            <Grid2 xs={12} container>
                <Grid2 xs={6}>
                    Сопротивление:
                </Grid2>
                <Grid2 xs={6}>
                    <TextField inputRef={opposition} type="number" size="small"/>
                </Grid2>
            </Grid2>
            <Button variant="outlined" sx={{mt: 1}} onClick={updateBalance}>Обновить</Button>
        </>
    );
};

export default AccountStats;