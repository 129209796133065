import React, {useRef} from 'react';
import {Button, Grid, TextField} from "@mui/material";
import {useRequesting} from "../../../app/providers/RequestingProvider";

const PushPage = () => {
    const request = useRequesting()?.authorizeRequest!!
    const ru = useRef<any>()
    const en = useRef<any>()

    const handleSendPush = () => {
        request.post(`admin/accounts/mass_push`, {}, {ru: ru.current.value, en: en.current.value}).then((res) => {
            ru.current.value = ""
            en.current.value = ""
        })
    }

    return (
        <Grid xs={12} container flexDirection="column" alignItems="center">
            <TextField inputRef={ru} label="ru" fullWidth margin="dense"/>
            <TextField inputRef={en} label="en" fullWidth margin="dense"/>
            <Button variant="outlined" onClick={handleSendPush} sx={{margin: "dense"}}>Отправить</Button>
        </Grid>
    );
};

export default PushPage;