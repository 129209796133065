import React, {useState} from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import {Alert, Button, Snackbar} from "@mui/material";
import {useRequesting} from "../../../app/providers/RequestingProvider";

const ControlPage = () => {
    const request = useRequesting()?.authorizeRequest!!
    const [alert, setAlert] = useState<{
        isOpen: boolean,
        element: React.JSX.Element | undefined,
        time?: number | undefined
    }>({isOpen: false, element: undefined, time: undefined})

    const collectTaxes = () => {
        setAlert({isOpen: true, element: responseMapping.waiting, time: 15000})
        request.post(`admin/auth/collect_taxes`).then((res) => handleAlertMessages(res.status))
    }

    const cleanData = () => {
        setAlert({isOpen: true, element: responseMapping.waiting, time: 15000})
        request.post(`admin/auth/clean_data"`).then((res) => handleAlertMessages(res.status))
    }

    const handleAlertMessages = (status: number) => {
        setAlert({isOpen: true, element: status === 200 ? responseMapping.success : responseMapping.error})
    }

    const responseMapping = {
        success: <Alert severity="success">Успешно!</Alert>,
        error: <Alert severity="error">Произошла ошибка!</Alert>,
        waiting: <Alert severity="info">Ожидайте, идет обработка.</Alert>
    }

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if(reason === 'clickaway')
            return
        setAlert({isOpen: false, element: undefined})
    }

    return (
        <Grid2 xs={12} container justifyContent="space-evenly">
            <Button variant="outlined" onClick={collectTaxes}>
                Списать ежедневный налог
            </Button>
            <Button variant="outlined" onClick={cleanData}>
                Почистить старые данные
            </Button>
            <Snackbar open={alert.isOpen} autoHideDuration={alert.time ? alert.time : 6000} onClose={handleCloseAlert}>
                {alert.element}
            </Snackbar>
        </Grid2>
    );
};

export default ControlPage;