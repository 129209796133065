import React, {useEffect, useRef} from 'react';
import {Box, Button, TextField} from "@mui/material";
import {AgentAttributesType, AgentType} from "../AccountAgents";
import {useRequesting} from "../../../../../../app/providers/RequestingProvider";

const AgentСharacteristics = ({data, agent}: {data: AgentAttributesType, agent: AgentType}) => {
    const request = useRequesting()?.authorizeRequest!!

    const attackRef = useRef<any>()
    const stealthRef = useRef<any>()
    const defenceRef = useRef<any>()
    const survivalRef = useRef<any>()

    useEffect(() => {
        attackRef.current.value = data.attack
        stealthRef.current.value = data.stealth
        defenceRef.current.value = data.defence
        survivalRef.current.value = data.survival
    }, [])

    const updateAttributes = () => {
        const newAgent = agent
        newAgent.attributes_base = {
            attack: attackRef.current.value,
            stealth: stealthRef.current.value,
            defence: defenceRef.current.value,
            survival: survivalRef.current.value
        }

        request.post(`admin/agents/${agent.id}/modify`, {}, agent)
    }

    return (
        <>
            <Box display="flex" justifyContent="space-between">
                Атака :
                <Box width="50%">
                    <TextField type="number" size="small" inputRef={attackRef}></TextField>
                </Box>
            </Box>
            <Box display="flex" justifyContent="space-between">
                Скрытность :
                <Box width="50%">
                    <TextField type="number" size="small" inputRef={stealthRef}></TextField>
                </Box>
            </Box>
            <Box display="flex" justifyContent="space-between">
                Оборона :
                <Box width="50%">
                    <TextField type="number" size="small" inputRef={defenceRef}></TextField>
                </Box>
            </Box>
            <Box display="flex" justifyContent="space-between">
                Выживание :
                <Box width="50%">
                    <TextField type="number" size="small" inputRef={survivalRef}></TextField>
                </Box>
            </Box>
            <Button sx={{marginTop: 1}} variant="outlined" onClick={() => updateAttributes()}>Обновить</Button>
        </>
    );
};

export default AgentСharacteristics;