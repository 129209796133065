import React from 'react'
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect'
import classnames from 'classnames'
import bg from '../../../../../assets/bg_our_team.webp'
import artem from '../../../../../assets/teams/artem.jpg'
import egor from '../../../../../assets/teams/egor.png'
import ilya from '../../../../../assets/teams/ilya.jpg'
import ivan from '../../../../../assets/teams/ivan.png'
import nikolay from '../../../../../assets/teams/nikolay.png'
import oleg from '../../../../../assets/teams/oleg.jpg'
import svetlana from '../../../../../assets/teams/svetlana.png'

export const OurTeam = (props) => {

    return (
        <section className={classnames({
            'our-team': true,
            mobile: isMobile,
            browser: isBrowser
        })} style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
        }}>
            <h2>our team</h2>
            <div className={classnames({
                container: true,
                mobile: isMobile,
                browser: isBrowser
            })}>
                <OurTeamItem
                    image={ivan}
                    name='Stepanov Ivan'
                    role='Founder / CEO'
                />
                <OurTeamItem
                    image={egor}
                    name='Rabkov Egor'
                    role='Founder / CMO'
                />
                <OurTeamItem
                    image={svetlana}
                    name='Zaitseva Svetlana'
                    role='Project manager / Art Lead'
                />
                <OurTeamItem
                    image={artem}
                    name='Glazkov Artem'
                    role='Producer / Senior Game Designer'
                />
                <OurTeamItem
                    image={oleg}
                    name='Oleg Kolcov'
                    role='Back-End Developer'
                />
                <OurTeamItem
                    image={nikolay}
                    name='Smirnov Nikolay'
                    role='Front-End Developer'
                />
                <OurTeamItem
                    image={ilya}
                    name='Kukushkin Ilya'
                    role='QA / Game Designer'
                />
            </div>
        </section>
    )
}

const OurTeamItem = (props) => {
    return (
        <div>
            <img src={props.image} alt={props.name} />
            <h4>{props.name}</h4>
            <p>{props.role}</p>
        </div>
    )
} 