import React, {MutableRefObject, useRef, useState} from 'react';
import {Alert, Box, Button, Divider, Modal, Snackbar, TextareaAutosize, TextField} from "@mui/material";
import {CreateTranslateModalType} from "../../../admin/main_components/content/translates_content/TranslateCollection";
import Grid2 from "@mui/material/Unstable_Grid2";
import {useRequesting} from "../../../../app/providers/RequestingProvider";

const ModalCreateTranslate = ({
                                  collection,
                                  modalOpen,
                                  handleClose,
                                  modalData
}: {
    collection: string,
    modalOpen: boolean,
    handleClose: () => void,
    modalData: CreateTranslateModalType
}) => {
    const request = useRequesting()!!.authorizeRequest!!
    const tag = useRef<any>(modalData.tag)
    const [snackBarOpen, setSnackBarOpen] = useState(false)

    const handleCloseSnackBar = () => {
        setSnackBarOpen(false)
    }

    const handleAddTranslate = () => {
        if(tag.current.value.includes(".")){
            setSnackBarOpen(true)
            return
        }

        const result = Object.entries(modalData.values)
            .map(([id, ref], key) => ({[id]: ref.current.value}))
            .reduce((obj, item) => Object.assign(obj, item))
        request.post("admin/languages/add-translate", {}, {
            collection: collection,
            newTag: tag.current.value,
            value: result
        }).then((res) => handleClose())  //todo Дохрена переделать добавление обьектов в Translate
    }

    return (
        <Modal
            open={modalOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: "80%",
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
                color: "text.primary"
            }}>
                <Snackbar open={snackBarOpen} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                    <Alert onClose={handleCloseSnackBar} severity="error" sx={{ width: '100%' }}>
                        Тег не может содержать точки.
                    </Alert>
                </Snackbar>
                {modalData && <Grid2 xs={12} container justifyContent="center">
                    <Grid2 xs={12} container justifyContent="center">
                        <TextField label="Тег" inputRef={tag} fullWidth/>
                    </Grid2>
                    <Divider sx={{m: 1, width: "100%"}}/>
                    <Grid2 xs={12} container>
                        {Object.entries(modalData.values).map(([id, value]: [string, MutableRefObject<any>], key) => <Grid2 key={key} xs={12} container>
                            <Grid2 xs={1} textAlign="right">{id}</Grid2>
                            <Grid2 xs={11}>
                                <TextareaAutosize
                                    style={{fontSize: "15px", minWidth: "100%", maxWidth: "100%", maxHeight: "50vh"}}
                                    placeholder="value"
                                    ref={value}
                                />
                            </Grid2>
                        </Grid2>)}
                        <Divider sx={{width: "100%", borderColor: "white", m:1}}/>
                        <Grid2 xs={12} container justifyContent="center">
                            <Button variant="outlined" onClick={() => handleAddTranslate()}>Добавить</Button>
                        </Grid2>
                    </Grid2>
                </Grid2>}
            </Box>
        </Modal>
    );
};

export default ModalCreateTranslate;