import React from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import {Typography} from "@mui/material";

const PurchaseSubPage = () => {
    return (
        <Grid2 xs={12} container>
            <Grid2 xs={12}>
                <Typography variant="h4">Покупки</Typography>
            </Grid2>

        </Grid2>
    );
};

export default PurchaseSubPage;