import React, {createContext, useContext, useEffect} from 'react';
import {useRequesting} from "./RequestingProvider";
import {Link, useNavigate} from "react-router-dom";

const AuthenticateContext = createContext<any>(null);

export const AuthenticateProvider = ({children}: {children: any}) => {
    const request = useRequesting()?.request!!
    const navigate = useNavigate()

    const authenticate = (name: string, pass: string) => {
        request.post("login", {
            headers: {
                accept: 'application/json'
            }
        }, {
            username: name,
            password: pass
        }).then((res) => res.text()).then((token) => {
            document.cookie = "token=" + token + "; expires = " + new Date().getTime() + (5*60*60*1000)
            return navigate("/admin")
        })
    }

    const refreshAuth = () => {

    }

    return (
        <AuthenticateContext.Provider
            value={{
                authenticate,
                refreshAuth
            }}
        >
            {children}
        </AuthenticateContext.Provider>
    );
};

export const useAuth = () => useContext(AuthenticateContext)