import React from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import {Button} from "@mui/material";
import {ItemAffixesType, ItemAttributesType} from "../../../types/ItemsTypes";

const ItemAddableParams = ({affixes, requiredAttributes}: {affixes: ItemAffixesType, requiredAttributes: ItemAttributesType}) => {
    return (
        <Grid2 xs={12} height="100%" display="flex" flexDirection="column" justifyContent="space-evenly">
            <Button size="small" variant="outlined">
                Affixes
            </Button>
            <Button size="small" variant="outlined">
                Required Attributes
            </Button>
        </Grid2>
    );
};

export default ItemAddableParams;