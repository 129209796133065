import React, {useEffect, useState} from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import {Button, CircularProgress, Paper, Typography} from "@mui/material";
import {useRequesting} from "../../../app/providers/RequestingProvider";
import ItemPaper from "../../../widgets/admin/main_components/content/ItemPaper";
import {useAdminState} from "../../../widgets/admin/providers/AdminStateProvider";
import {ItemTemplateType} from "../../../widgets/admin/types/ItemsTypes";

const ItemsPage = () => {
    const [items, setItems] = useState<React.JSX.Element[] | undefined>(undefined)
    const adminState = useAdminState()!!
    const itemsState = adminState.getters.getItems()

    useEffect(() => {
        if(itemsState)
            setItems(itemsState.map((it, id) => <Paper key={id} elevation={4} sx={{mb: 1}}>
                <ItemPaper initialItem={it}/>
            </Paper>))
    }, itemsState)

    return (
        <Grid2 xs={12} container>
            <Grid2 xs={12} mb={1} container alignItems="center" flexDirection="column">
                <Button variant="outlined" sx={{ maxWidth: 200}}>Добавить предмет</Button>
                <Typography mt={1}>Здесь будет поиск</Typography>
            </Grid2>
            <Grid2 xs={12}>
                {items}
            </Grid2>
        </Grid2>
    );
};

export default ItemsPage;