import React from 'react'
import {BrowserView, isBrowser, isMobile, MobileView} from 'react-device-detect';
import classnames from 'classnames';
import bg from '../../../../../assets/bg_footer.webp'
import logo from '../../../../../assets/logo.svg'
import {AppStore} from '../../buttons/AppStore';
import {GooglePlay} from '../../buttons/GooglePlay';
import {HGP} from '../../buttons/HGP';

export const Footer = (props) => {
    return (
        <footer
            style={{
                position: 'relative',
                backgroundImage: `url(${bg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}
            className={classnames({
                mobile: isMobile,
                browser: isBrowser
            })}
        >

            <BrowserView className={classnames({
                container: true,
                mobile: isMobile,
                browser: isBrowser
            })}>
                <div className='footer-item'>
                    <img src={logo} style={{marginBottom: "63.5px"}} alt='Five Plus Games. LCC'/>
                    <a>© 2024 Five Plus Games. LLC. All rights reserved.</a>
                </div>
                <div className='footer-item'>
                    <AppStore />
                    <a href='https://5plus.games/policy/privacy_policy'>Legal & Privacy</a>
                </div>
                <div className='footer-item'>
                    <GooglePlay />
                    <a href='https://5plus.games/policy/terms_of_use'>User Agreement</a>
                </div>
                <div className='footer-item' style={{
                    marginLeft: 'auto'
                }}>
                    <HGP />
                </div>
            </BrowserView>

            <MobileView className={classnames({
                container: true,
                mobile: isMobile,
                browser: isBrowser
            })}>
                <a>© 2024 Five Plus Games. LLC. All rights reserved.</a>
                <div className='footer-item'>
                    <img src={logo} alt='Five Plus Games. LCC'/>
                    <a href='https://5plus.games/policy/privacy_policy'>Legal & Privacy</a>
                    <a href='https://5plus.games/policy/terms_of_use'>User Agreement</a>
                </div>
                <div className='footer-item'>
                    <AppStore/>
                </div>
                <div className='footer-item'>
                    <GooglePlay/>
                </div>
                <div className='footer-item' style={{
                    marginLeft: 'auto'
                }}>
                    <HGP/>
                </div>
            </MobileView>
        </footer>
    );
}