import React from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import {PvpRaidDetailType} from "./RaidPvpDetails";
import {Typography} from "@mui/material";

function RaidPvpResult({state}: { state: PvpRaidDetailType }) {

    const getAgentItems = (target: string) => {
        console.log(state.result[target])
        return state.result[target].map((it: any) => <Grid2 xs={3}>{it.items.map((item: any) => <Grid2
            xs={12}>{item.name.ru}</Grid2>)}</Grid2>)
    }
    const getAgentAbilities = (target: string) => {
        return state.result[target] ? state.result[target].map((it: any) => <Grid2 xs={3}>{it.abilities.map((ab: any) =>
            <Grid2 xs={12}>{ab.ability} - {ab.visible ? "" : "invisible"}</Grid2>)}</Grid2>) : <>нет инфы</>
    }
    const getAgentNames = (target: string) => {
        return state.result[target].map((it: any) => <Grid2 xs={3}>{it.agent.name} - {it.agent.id}</Grid2>)
    }
    const getAgentAttribute = (target: string, type: string) => {
        return state.result[target].map((it: any) => getParam(it.score[type]))
    }
    const getAgentDamage = (target: string) => {
        return state.result[target].map((it: any) => <Grid2 xs={12} container>
            {
                it.score.damage.map((damage: any) => <Grid2 xs={3}>
                    {damage.item.template_data[0].name.ru}
                    {getParam(damage.damage)}
                </Grid2>)
            }
        </Grid2>)
    }

    const getAgentScore = (target: string) => {
        return state.result[target].map((it: any) => <Grid2 xs={3}>{it.score.value}</Grid2>)
    }

    const getParam = (target: any) => {
        return <Grid2 xs={12} container>
            <Grid2 xs={4}>База - {target.base}</Grid2>
            <Grid2 xs={4}>{target.scale_bonuses.map((bonus: any) => <Grid2 container xs={12}><Grid2
                xs={6}>{bonus.label}</Grid2><Grid2 xs={6}>{bonus.percent}</Grid2></Grid2>)
                .concat(
                    target.percent_bonuses.map((bonus: any) => <Grid2 container xs={12}><Grid2
                        xs={6}>{bonus.label}</Grid2><Grid2 xs={6}>{bonus.percent}</Grid2></Grid2>)
                )}</Grid2>
            <Grid2 xs={4}>Итого - {target.total_value}</Grid2>
        </Grid2>
    }

    const getAgentsInstanceParams = (target: string, type: string) => {
        return state.result[target].map((it: any) => <Grid2 xs={12}>
            {getParam(it[type])}
        </Grid2>)
    }

    return (
        <Grid2 xs={12} container>
            {
                state.winner === 'defence_ransom' ? <div>
                        Игрок откупился
                    </div> :
                    <Grid2 xs={12} container>
                        <Grid2 xs={12} container>
                            <Grid2 xs={12}><Typography variant='h5'>Оборона</Typography></Grid2>
                            <Grid2 xs={12} container>
                                {
                                    state.result != null && state.result.defence_agents != null ?
                                        <>
                                            <Grid2 xs={12}>Богатство
                                                базы: {state.defence_wealth} - {state.defence_tier}</Grid2>
                                            <Grid2 xs={12}>Деньги: {state.defence_wealth_money}</Grid2>
                                            <Grid2 xs={12}>Строения: {state.defence_wealth_building}</Grid2>
                                            <Grid2 xs={12}>Предметы: {state.defence_wealth_items}</Grid2>
                                            <Grid2 xs={12}>Винрейт: {state.defence_winrate}</Grid2>
                                            <Grid2 xs={12} container>
                                                <Grid2
                                                    xs={12}>Имена: {getAgentNames('agent_parameters_defence')}</Grid2>
                                                <Grid2
                                                    xs={12}>Способности: {getAgentAbilities('original_defence_agents')}</Grid2>
                                                <Grid2
                                                    xs={12}>Предметы: {getAgentItems('agent_parameters_defence')}</Grid2>
                                                <Grid2
                                                    xs={12}>Атака: {getAgentAttribute('agent_parameters_defence', 'attack')}</Grid2>
                                                <Grid2
                                                    xs={12}>Урон: {getAgentDamage('agent_parameters_defence')}</Grid2>
                                                <Grid2
                                                    xs={12}>Защита: {getAgentAttribute('agent_parameters_defence', 'defence')}</Grid2>
                                                <Grid2
                                                    xs={12}>Выживание: {getAgentAttribute('agent_parameters_defence', 'survival')}</Grid2>
                                                <Grid2
                                                    xs={12}>Скрытность: {getAgentAttribute('agent_parameters_defence', 'stealth')}</Grid2>
                                                <Grid2
                                                    xs={12}>Крутость: {getAgentScore('agent_parameters_defence')}</Grid2>
                                                <Grid2 xs={12} container>
                                                    {
                                                        state.defence_account != null && state.winner === 'defence' ?
                                                            <Grid2 xs={12} container>
                                                                {
                                                                    ["gain_attack", "gain_defence", "gain_survival", "gain_stealth"].map((it: string, id: number) =>
                                                                        <Grid2 xs={3}>
                                                                            {["+Атака", "+Защита", "+Выживание", "+Скрытность"][id]} - {
                                                                            getAgentsInstanceParams('agent_parameters_defence', it)
                                                                        }
                                                                        </Grid2>)
                                                                }
                                                            </Grid2>
                                                            :
                                                            <></>
                                                    }
                                                </Grid2>
                                            </Grid2>
                                            <Grid2 xs={12} container>
                                                {
                                                    state.result.defender_base ?
                                                        <Grid2 xs={12} container>
                                                            {
                                                                state.result.defender_base.map((it: any) => <Grid2
                                                                    xs={12} container>
                                                                    <Grid2 xs={3}>Здание - {it.type}</Grid2>
                                                                    <Grid2 xs={3}>Уровень - {it.level}</Grid2>
                                                                    <Grid2 xs={3}>Индекс - {it.index}</Grid2>
                                                                    <Grid2 xs={3}>Страховка
                                                                        - {it.insured_until > 0 ? new Date(it.insured_until).toUTCString() : <>Не
                                                                            застраховано</>}</Grid2>
                                                                </Grid2>)
                                                            }
                                                            <Grid2 xs={12}>
                                                                Строится: {state.result.building_type} до
                                                                уровня: {state.result.target_level}
                                                            </Grid2>
                                                        </Grid2>
                                                        : <>Нет инфы</>
                                                }
                                            </Grid2>
                                            <Grid2 xs={12} container>
                                                <Grid2 xs={12}>Разрушены здания: {
                                                    state.result.destroyed_buildings.map((it: any) => <Grid2 xs={4}>
                                                        {it.type} - {it.level}
                                                    </Grid2>)
                                                }</Grid2>
                                                <Grid2 xs={12}>Ранены агенты: {
                                                    state.result.wound_defenders ? state.result.wound_defenders.map((it: any) =>
                                                        <Grid2 xs={3}>{it.name}</Grid2>) : <>нет инфы</>
                                                }</Grid2>
                                                <Grid2 xs={12}>Потеряны предметы: {
                                                    state.result.defender_lost_items.map((it: any) => <Grid2
                                                        xs={3}>{it.name.ru}</Grid2>)
                                                }</Grid2>
                                            </Grid2>
                                        </> :
                                        <>Нет инфы</>
                                }
                            </Grid2>
                            <Grid2 xs={12}>Крутость обороны: {state.result?.score_defence_base}</Grid2>
                            <Grid2 xs={12}>Бонусные мастера обороны: {state.result?.master_defence_bonus}</Grid2>
                            <Grid2 xs={12}>Процент от зданий: {state.result?.defence_base_increase_percent}</Grid2>
                            <Grid2 xs={12}>Процент от мин: {state.result?.defence_base_mines_percent}</Grid2>
                            <Grid2 xs={12}>Рандомный ролл: {state.result?.roll_defence}</Grid2>
                            <Grid2 xs={12}>Итого крутость обороны: {state.result?.score_defence_total}</Grid2>
                        </Grid2>


                        <Grid2 xs={12} container>
                            <Grid2 xs={12}><Typography variant='h5'>Атака</Typography></Grid2>
                            <Grid2 xs={12} container>
                                <Grid2
                                    xs={12}>Имена: {getAgentNames('agent_parameters_attack')}</Grid2>
                                <Grid2
                                    xs={12}>Способности: {getAgentAbilities('original_attack_agents')}</Grid2>
                                <Grid2
                                    xs={12}>Предметы: {getAgentItems('agent_parameters_attack')}</Grid2>
                                <Grid2
                                    xs={12}>Атака: {getAgentAttribute('agent_parameters_attack', 'attack')}</Grid2>
                                <Grid2
                                    xs={12}>Урон: {getAgentDamage('agent_parameters_attack')}</Grid2>
                                <Grid2
                                    xs={12}>Защита: {getAgentAttribute('agent_parameters_attack', 'defence')}</Grid2>
                                <Grid2
                                    xs={12}>Выживание: {getAgentAttribute('agent_parameters_attack', 'survival')}</Grid2>
                                <Grid2
                                    xs={12}>Скрытность: {getAgentAttribute('agent_parameters_attack', 'stealth')}</Grid2>
                                <Grid2
                                    xs={12}>Крутость: {getAgentScore('agent_parameters_attack')}</Grid2>
                                <Grid2 xs={12} container>
                                    {
                                        state.attack_account != null && state.winner === 'attack' ?
                                            <Grid2 xs={12} container>
                                                {
                                                    ["gain_attack", "gain_defence", "gain_survival", "gain_stealth"].map((it: string, id: number) =>
                                                        <Grid2 xs={12}>
                                                            {["+Атака", "+Защита", "+Выживание", "+Скрытность"][id]}: {getAgentsInstanceParams('agent_parameters_attack', it)}
                                                        </Grid2>)
                                                }
                                            </Grid2>
                                            : <>нет инфы</>
                                    }
                                </Grid2>
                            </Grid2>
                            <Grid2 xs={12}>Всего крутость атаки: {state.result.score_attack_base}</Grid2>
                            <Grid2 xs={12}>Рандомный ролл: {state.result.roll_attack}</Grid2>
                            <Grid2 xs={12}>Итого крутость атаки: {state.result.score_attack_total}</Grid2>
                        </Grid2>
                    </Grid2>
            }
        </Grid2>
    );
}

export default RaidPvpResult;