import React, {useReducer} from 'react';
import {useAdminState} from "../../../providers/AdminStateProvider";
import {Box, Button, Checkbox, FormControlLabel, Paper} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {useRequesting} from "../../../../../app/providers/RequestingProvider";

const PoolBios = () => {
    const adminState = useAdminState()!!
    const bios = adminState.getters.getPoolAgents()?.pool.bios
    const request = useRequesting()?.authorizeRequest!!
    const [, forceUpdate] = useReducer(x => x + 1, 0);


    const handleMaleChange = (id: number) => {
        if(bios){
            bios[id].male = !bios[id].male
            forceUpdate()
        }
    }

    const handlePreventChange = (id: number) => {
        if(bios){
            bios[id].prevent_generate = !bios[id].prevent_generate
            forceUpdate()
        }
    }

    const handleUpdateBio = (id: number) => {
        if(bios)
            request.post(`/admin/pools/bios/${id}/update`, {}, bios[id])
    }

    return (
        <>
            {bios?.map((it, key) => <Paper key={key} sx={{m: 1, minHeight: "30px"}}>
                <Grid2 xs={12} container justifyContent="center" alignItems="center" flexDirection="column">
                    <Grid2 xs={12} container>
                        <Grid2 xs={4}>
                            <img width="40%" src={`/images/uploaded/${it.portrait_big}`}/>
                        </Grid2>
                        <Grid2 xs={4}>
                            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                <img width="40%" src={`/images/uploaded/${it.portrait_small}`}/>
                            </Box>
                        </Grid2>
                        <Grid2 xs={4}>
                            <img width="40%" src={`/images/uploaded/${it.portrait_marketing}`}/> все изображения нужно будет отрефакторить, бред хранить все в одной папке
                        </Grid2>
                    </Grid2>
                    <Grid2 xs={12} container alignItems="center">
                        <Grid2 xs={6}>
                            {it.short_desc}
                        </Grid2>
                        <Grid2 xs={6}>
                            {it.long_desc}
                        </Grid2>
                    </Grid2>
                    <Grid2 xs={12} container mt={1}>
                        <Grid2 xs={3}>
                            <FormControlLabel control={<Checkbox checked={it.male} onClick={() => handleMaleChange(key)}/>} label="Is Male"/>
                        </Grid2>
                        <Grid2 xs={3}>
                            <FormControlLabel control={<Checkbox checked={it.prevent_generate} onClick={() => handlePreventChange(key)}/>} label="Prevent Generate"/>
                        </Grid2>
                        <Grid2 xs={3}>
                            <Button variant="outlined" onClick={() => handleUpdateBio(key)}>Сохранить</Button>
                        </Grid2>
                        <Grid2 xs={3}>
                            <Button variant="outlined" disabled>Удалить</Button>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </Paper>)}
        </>
    );
};

export default PoolBios;