import React, {useState} from 'react';
import {Box, Container, IconButton, Tab} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import AccountsPage from "../../../pages/admin/subpages/AccountsPage";
import {PageDataType, useTabs} from "../providers/TabsProvider";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

const TabsLayout = () => {
    const tabProvider = useTabs()

    const handleChangeTab = (newValue: string) => {
        tabProvider.setTab(newValue)
    }

    return (
        <Container sx={{minHeight: "100vh"}} disableGutters>
            <TabContext value={tabProvider.tab ? tabProvider.tab : "undefined"}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
                    <TabList textColor="secondary">
                        {
                            tabProvider.tabs.map((it: PageDataType, id: number) => <>
                                        <Tab
                                            key={id}
                                            label={it.name}
                                            value={it.path}
                                            iconPosition={"end"}
                                            sx={{minHeight: 50}}
                                            onClick={() => handleChangeTab(it.path)}
                                        />
                                        <IconButton onClick={() => tabProvider.closeTab(it.path)}>
                                            <ClearOutlinedIcon fontSize="small"/>
                                        </IconButton>
                            </>
                            )
                        }
                    </TabList>
                </Box>
                {
                    tabProvider.tabs.map((it: PageDataType, id: number) => <TabPanel key={id} value={it.path}>{it.component}</TabPanel>)
                }
            </TabContext>
        </Container>
    );
};

export default TabsLayout;