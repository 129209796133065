import React, { useState } from 'react';

import { Header } from '../components/pages/home/header';
import { Video } from '../components/pages/home/video';
import { Description } from '../components/pages/home/description';
import { Info } from '../components/pages/home/info';
import { OurTeam } from '../components/pages/home/ourTeam';
import { Connections } from '../components/pages/home/connections';
import { Footer } from '../components/pages/home/footer';
import { isMobile } from 'react-device-detect';

export const Home = (props) => {
    return (
        <main className="landingPage">
            <Header VideoBg />
            {/* <Video /> */}
            <Description />
            <Info />
            <OurTeam />
            <Connections />
            <Footer />
        </main>
    )
}