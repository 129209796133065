import React, {useEffect, useState} from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import ModalSelectionAccount from "../../../widgets/abstract_components/modals/ui/ModalSelectionAccount";
import AccountsContent from "../../../widgets/admin/main_components/content/AccountsContent";

const AccountsPage = () => {
    return (
        <Grid2 container justifyContent="center">
            <Grid2 container xs={12} justifyContent="center">
                <ModalSelectionAccount/>
            </Grid2>
            <Grid2 container xs={12} justifyContent="center">
                <AccountsContent/>
            </Grid2>
        </Grid2>
    );
};

export default AccountsPage;