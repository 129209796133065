import React, {useEffect, useState} from 'react';
import {useRequesting} from "../../../app/providers/RequestingProvider";
import Grid2 from "@mui/material/Unstable_Grid2";
import {Button, FormControl, InputLabel, MenuItem, Select, Typography} from "@mui/material";

type AdbConfigType = {
    title: string,
    pks: string[],
    sks: string[]
}

const AdbPage = () => {
    const request = useRequesting()?.authorizeRequest!!
    const [config, setConfig] = useState<AdbConfigType[]>([])
    const [selectedReport, setSelectedReport] = useState<string | undefined>(undefined)
    const [report, setReport] = useState<number | undefined>(undefined)

    useEffect(() => {
        request.get(`admin/adb/config`).then((res) => res.json()).then((res) => setConfig(res.metrics))
    }, [])

    const getReport = () => {
        request.post(`admin/adb/metric`, {}, {
            metric: selectedReport,
            request: []
        }).then((res) => res.json()).then((res) => setReport(res.total))
    }


    return (
        <Grid2 xs={12} container flexDirection="column" alignItems="center">
            <FormControl sx={{width: "50%"}}>
                <InputLabel>Отчет</InputLabel>
                <Select label="Отчет" onChange={(it) => setSelectedReport(it.target.value as string)}>
                    {config.map((it, key) => <MenuItem key={key} value={it.title}>{it.title}</MenuItem>)}
                </Select>
            </FormControl>
            <Button variant="outlined" sx={{mt: 1}} onClick={getReport}>Получить отчет</Button>
            <Grid2 xs={12} container justifyContent="center" mt={4}>
                <Grid2 xs={12} textAlign="center">
                    <Typography>Отчет</Typography>
                </Grid2>
                <Grid2 xs={12} textAlign="center">
                    {report}
                </Grid2>
            </Grid2>
        </Grid2>
    );
};

export default AdbPage;