import React, {useEffect, useState} from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import {Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {useRequesting} from "../../../../../app/providers/RequestingProvider";
import {AccountBuildingType} from "../../../types/AccountTypes";
import {AdminStateTypeActions, useAdminState} from "../../../providers/AdminStateProvider";

type RowType = {
    building: string,
    level: number,
    index: number,
    actions: React.JSX.Element
}

const AccountBase = () => {
    const request = useRequesting()?.authorizeRequest!!
    const [rows, setRows] = useState<RowType[]>()

    const adminState = useAdminState()!!
    const account = adminState.getters.getAccount()

    const breakBuilding = (type: string, id: number) => {
        request.post(`admin/accounts/${account?.selectedAcc}/destroy_building/${id}/${type}`)
            .then(() => adminState.dispatch({type: AdminStateTypeActions.GetAccount, payload: account?.selectedAcc}))
    }

    const upgradeBuilding = (type: string, id: number) => {
        request.post(`admin/accounts/${account?.selectedAcc}/upgrade_building/${id}/${type}`)
            .then(() => adminState.dispatch({type: AdminStateTypeActions.GetAccount, payload: account?.selectedAcc}))
    }

    const insureBuilding = (type: string, id: number) => {
        request.post(`admin/accounts/${account?.selectedAcc}/insure_building/${id}/${type}`)
            .then(() => adminState.dispatch({type: AdminStateTypeActions.GetAccount, payload: account?.selectedAcc}))
    }

    const stopInsureBuilding = (type: string, id: number) => {
        request.post(`admin/accounts/${account?.selectedAcc}/stop_insurance/${id}/${type}`)
            .then(() => adminState.dispatch({type: AdminStateTypeActions.GetAccount, payload: account?.selectedAcc}))
    }

    useEffect(() => {
        if(account?.accountDetail)
            setRows(account?.accountDetail.buildings.map((it: AccountBuildingType) => ({
                building: it.type,
                level: it.level,
                index: it.index,
                actions: <Grid2 xs={12}>
                    <Button size="small" onClick={() => breakBuilding(it.type, it.index)}>
                        Сломать
                    </Button>
                    <Button size="small" onClick={() => upgradeBuilding(it.type, it.index)}>
                        Улучшить
                    </Button>
                    <Button size="small" onClick={() => insureBuilding(it.type, it.index)}>
                        Застраховать
                    </Button>
                    <Button size="small" onClick={() => stopInsureBuilding(it.type, it.index)}>
                        Остановить страховку
                    </Button>
                </Grid2>})))
    }, [account?.accountDetail])

    return (
        <Grid2 container xs={12}>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Здание</TableCell>
                            <TableCell align="right">Уровень</TableCell>
                            <TableCell align="right">Индекс</TableCell>
                            <TableCell align="center">Действия</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows?.map((it, key) => <TableRow key={key}>
                            <TableCell>{it.building}</TableCell>
                            <TableCell align="right">{it.level}</TableCell>
                            <TableCell align="right">{it.index}</TableCell>
                            <TableCell>{it.actions}</TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid2>
    );
};

export default AccountBase;