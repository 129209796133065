import React, {useEffect, useState} from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import {ObjDataType} from "../../../../../pages/admin/subpages/TranslatesPage";
import {Box, Button, IconButton, Modal, Typography} from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
const TranslateObject = ({data, openModal}: {data: ObjDataType, openModal: (tag: string, values: Map<string, string>, obj: ObjDataType) => void}) => {

    useEffect(() => {
        console.log()
    }, [])

    return (
        <>
            <Grid2 xs={12} container alignItems="center" justifyContent="center">
                <Grid2 xs={8}>
                    {new Map(Object.entries(data.data)).get("ru")}
                </Grid2>
                <Grid2 xs={3}>
                    <Button onClick={() => openModal(data.tag, data.data, data)}>{data.obj} - Перевод</Button>
                </Grid2>
                <Grid2 xs={1}>
                    {(
                        !Object.keys(data.data).includes("ru") ||
                        !Object.keys(data.data).includes("en") ||
                        Object.entries(data.data).filter(([it, value]) => value == "").length > 0
                    ) && <ErrorOutlineIcon fontSize="small" color="error"/>}
                </Grid2>
            </Grid2>

        </>
    );
};

export default TranslateObject;