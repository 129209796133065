import React, {useEffect, useState} from 'react';
import {useAdminState} from "../../../providers/AdminStateProvider";
import CardPreset from "./pool_presets/CardPreset";
import {PresetType} from "../../../types/PoolAgentsTypes";
import ModalParamsPreset from "./pool_presets/ModalParamsPreset";

const PoolPresets = () => {
    const adminState = useAdminState()!!
    const presets = adminState.getters.getPoolAgents()?.pool.presets
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [modalPreset, setModalPreset] = useState<{id: number} | undefined>(undefined)

    useEffect(() => {
        if(modalPreset)
            setModalOpen(true)
    }, [modalPreset])

    const handleOpenModal = (id: number, preset: PresetType) => {
        setModalPreset({id: id})
    }

    const handleClose = () => {
        setModalOpen(false)
    }

    return (
        <>
            {presets?.map((it, key) => <CardPreset modal={handleOpenModal} key={key} id={key}/>)}
            {
                modalPreset && <ModalParamsPreset open={modalOpen} handleClose={handleClose} preset={presets!![modalPreset.id]}/>
            }
        </>
    );
};

export default PoolPresets;