import React, {useRef} from 'react';
import {Button, Container, Paper, TextField, Typography} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {useAuth} from "../../app/providers/AuthenticateProvider";

const LoginPage = () => {
    const nameRef = useRef<any>("")
    const passRef = useRef<any>("")

    const auth = useAuth()

    const authorize = () => {
        auth.authenticate(nameRef.current.value.toString(), passRef.current.value.toString())
    }

    return (
        <Container sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh"}}>
            <Paper sx={{padding: 2}}>
                <Grid2 container xs={12}>
                    <Grid2 xs={12} mb={2}>
                        <Typography variant="h5">Авторизация</Typography>
                    </Grid2>
                    <Grid2 xs={12} container mb={2} spacing={1}>
                        <Grid2 xs={6}>
                            <TextField inputRef={nameRef} label="Login" fullWidth/>
                        </Grid2>
                        <Grid2 xs={6}>
                            <TextField inputRef={passRef} label="Password" fullWidth/>
                        </Grid2>
                    </Grid2>
                    <Grid2 xs={12} container justifyContent="center">
                        <Button color="success" variant="contained" onClick={authorize}>
                            Войти
                        </Button>
                    </Grid2>
                </Grid2>
            </Paper>
        </Container>
    );
};

export default LoginPage;