import React from 'react';

const AdminHeader = () => {

    return (
        <>
            "Battle For Life"
        </>
    );
};

export default AdminHeader;