import React, {useReducer, useRef, useState} from 'react';
import {ItemRewardType, RewardType} from "../../../pages/admin/addable_pages/RaidPage";
import Grid2 from "@mui/material/Unstable_Grid2";
import {Button, Divider, FormControl, InputLabel, MenuItem, Paper, Select, TextField} from '@mui/material';
import RaidRewardItem from "./RaidRewardItem";

const RaidReward = ({reward}: {reward: RewardType}) => {
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const handleRewardType = (value: string) => {
        reward.type = value
        forceUpdate()
    }

    const handleUpdateWeight = (value: number) => {
        reward.weight = value
        forceUpdate()
    }

    const handleUpdateProcMoney = (value: number) => {
        reward.money_amount = value
        forceUpdate()
    }

    const addRewardItem = () => {
        const item: ItemRewardType[] = [{type: "item", item_template: "", quality: 1}]
        reward.items.push(item)
        forceUpdate()
    }

    const addRewardItemState = (index: number) => {
        reward.items[index].push({type: "item", item_template: "", quality: 1})
        forceUpdate()
    }

    return (
        <Grid2 container xs={12}>
            <Grid2 xs={12} sx={{display: "flex", alignItems: "center"}}>
                <FormControl sx={{width: "50%"}} margin={"dense"}>
                    <InputLabel>Тип</InputLabel>
                    <Select label="Тип" onChange={(e) => handleRewardType(e.target.value)} value={reward.type}>
                        <MenuItem value="no_reward">Нет Награды</MenuItem>
                        <MenuItem value="items">Предметы</MenuItem>
                        <MenuItem value="money">Деньги</MenuItem>
                    </Select>
                </FormControl>
                <TextField value={reward.weight} onChange={(e) => handleUpdateWeight(Number(e.target.value))} label="Weight" type="number" margin="dense"/>
                {reward.type !== "money" && <Button variant="outlined" onClick={addRewardItem}>Добавить</Button>}
            </Grid2>
            <Paper elevation={2} sx={{padding: 2, backgroundColor: "rgba(222, 222, 222, 0.1)", width: "100%"}}>
                <Grid2 xs={12} container spacing={1}>
                    {
                        reward.type === "no_reward" ? undefined :
                            reward.type === "items" ? reward.items.map((item, key) => <Grid2 key={key} xs={12} container alignItems="center">{item.map((itemLast, key) =>
                                    <RaidRewardItem key={key} item={itemLast}/>
                                )}
                                    <Button variant="outlined" sx={{height: 50}} onClick={() => addRewardItemState(key)}>Добавить</Button>
                                <Divider light={false} sx={{width: "100%", m: 1}}/>
                            </Grid2>) :
                                reward.type === "money" ? <Grid2 xs={4}>
                                    <TextField label="Процент" onChange={(e) => handleUpdateProcMoney(Number(e.target.value))} value={reward.money_amount} type="number"/>
                                </Grid2> : undefined
                    }
                </Grid2>
            </Paper>
        </Grid2>
    );
};

export default RaidReward;