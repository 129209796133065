import React, {useEffect, useState} from 'react';
import Grid2 from "@mui/material/Unstable_Grid2";
import {Box, Button, Typography} from "@mui/material";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {useTabs} from "../../../providers/TabsProvider";
import {useRequesting} from "../../../../../app/providers/RequestingProvider";
import {useAdminState} from "../../../providers/AdminStateProvider";
import RaidPvpDetails from "../../../addable_components/RaidPvpDetails";
import RaidCommonDetails from "../../../addable_components/RaidCommonDetails";

type CommonRaidType = {
    id: string
    name: string
    start_time: number
    end_time: number
    state: string
    result: string
}

const AccountRaids = () => {
    const tabsProvider = useTabs()
    const request = useRequesting()?.authorizeRequest!!
    const adminState = useAdminState()!!
    const account = adminState.getters.getAccount()
    const [raids, setRaids] = useState<undefined | CommonRaidType[]>(undefined)


    useEffect(() => {
        if(account?.selectedAcc)
            refreshRaids()
    }, [account?.selectedAcc])

    const columns: GridColDef[] = [
        {field: "id", headerName: "ID", width: 250, renderCell: (params) => <Button onClick={() => openRaid(params.id.toString())}>{params.id.toString()}</Button>},
        {field: "name", headerName: "Наименование"},
        {field: "start_time", headerName: "Время старта", width: 150},
        {field: "end_time", headerName: "Время окончания", width: 150},
        {field: "state", headerName: "Состояние"},
        {field: "result", headerName: "Результат"},
        {field: "actions", headerName: "Действия", width: 220, renderCell: (params) =>
                <Box>
                    <Button size="small" onClick={() => handleForceCalculate(params.id.toString())}>Рассчитать</Button>
                </Box>
        }
    ]

    const refreshRaids = () => {
        request.get(`admin/accounts/${account?.selectedAcc}/raids`)
            .then((res) => res.json())
            .then((res) => {
                const result = res.map((it: any) => ({
                    ...it,
                    start_time: new Date(it.start_time).toString(),
                    end_time: new Date(it.end_time).toString(),
                    result: it.meta?.result ? it.meta.result : "Неизвестно"
                }))
                setRaids(result)
            })
    }

    const openRaid = (id: string) => {
        tabsProvider.openTab(id, {
            name: id,
            path: id,
            component: <RaidCommonDetails raidId={id}/>
        })
    }

    const handleForceCalculate = (id: string) => {
        request.post(`admin/raid_instances/${id}/force_calculate`).then(() => adminState.refreshers.refreshAccount)
    }

    return (
        <Grid2 xs={12} container>
            <Grid2 xs={12}>
                <Typography variant="h5">Рейды</Typography>
                <DataGrid
                    sx={{width: "100%", color: "text.disabled"}}
                    columns={columns}
                    rows={raids ? raids : []}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 }
                        }
                    }}
                    pageSizeOptions={[10]}
                />
            </Grid2>
        </Grid2>
    );
};

export default AccountRaids;