import {Dispatch, ReactElement, SetStateAction, useState} from "react";

export enum PageComponentEnum {
    accounts,
    items,
    adb,
    control,
    push,
    translates,
    raids,
    iap,
    balance,
    poolsAgent,
}

const listContent: ItemListContent[] = [
    {
        name: "Аккаунты",
        path: "accounts",
        component: PageComponentEnum.accounts
    },
    {
        name: "Предметы",
        path: "items",
        component: PageComponentEnum.items
    },
    {
      name: "Покупки",
      path: "iap",
      component: PageComponentEnum.iap
    },
    {
        name: "Агенты",
        path: "poolsAgent",
        component: PageComponentEnum.poolsAgent
    },
    {
        name: "Общие",
        path: "test",
        component: PageComponentEnum.items,
        children: {
            data: [
                {
                    name: "Управление",
                    path: "control",
                    component: PageComponentEnum.control
                },
                {
                    name: "Аналитика",
                    path: "adb",
                    component: PageComponentEnum.adb
                },
                {
                    name: "Пуши",
                    path: "push",
                    component: PageComponentEnum.push
                },
                {
                    name: "Переводы",
                    path: "translates",
                    component: PageComponentEnum.translates
                },
                {
                    name: "Баланс",
                    path: "Balance",
                    component: PageComponentEnum.balance
                }
            ]
        }
    },
    {
        name: "Рейды",
        path: "Raids",
        component: PageComponentEnum.raids
    },
]

export type ItemListContent = {
    id?: number,
    name: string,
    icon?: ReactElement,
    path: string,
    children?: CollapsableItemList,
    component?: PageComponentEnum
}

type CollapsableItemList = {
    data: ItemListContent[]
}

export const listAdminBarItems = listContent