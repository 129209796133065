import React, {useEffect, useReducer, useState} from 'react';
import {ItemRewardType} from "../../../pages/admin/addable_pages/RaidPage";
import {FormControl, InputLabel, MenuItem, Paper, Select} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {useRequesting} from "../../../app/providers/RequestingProvider";
import {useAdminState} from "../../admin/providers/AdminStateProvider";

const RaidRewardItem = ({item}: {item: ItemRewardType}) => {
    const adminState = useAdminState()!!
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const itemTemplates = adminState.getters.getItems()?.filter((it) => it.type === "unique")

    const handleUpdateType = (value: string) => {
        item.type = value
        forceUpdate()
    }

    const handleUpdateQuality = (value: number) => {
        item.quality = value
        forceUpdate()
    }

    const handleUpdateUniqueItem = (value: string) => {
        item.item_template = value
        forceUpdate()
    }

    return (
        <Grid2 xs={4}>
                <FormControl sx={{width: "50%"}} margin={"dense"}>
                    <InputLabel>Тип</InputLabel>
                    <Select onChange={(e) => handleUpdateType(e.target.value)} label="Тип" defaultValue={item.type}>
                        <MenuItem value="unique_item">Уникальный предмет</MenuItem>
                        <MenuItem value="item">Снаряжение</MenuItem>
                        <MenuItem value="mod">Модификация</MenuItem>
                    </Select>
                </FormControl>
            {item.type === "unique_item" && <FormControl sx={{width: "50%"}} margin="dense">
                <InputLabel>Предмет</InputLabel>
                <Select onChange={(e) => handleUpdateUniqueItem(e.target.value)} label="Предмет" defaultValue={item.item_template}>
                    {itemTemplates?.map((it) => <MenuItem value={it.id}>{it.title}</MenuItem>)}
                </Select>
            </FormControl>}

            {item.type !== "unique_item" && <FormControl sx={{width: "50%"}} margin={"dense"}>
                <InputLabel>Качество</InputLabel>
                <Select label="Качество" onChange={(e) => handleUpdateQuality(Number(e.target.value))} defaultValue={item.quality}>
                    <MenuItem value="0">Плохое</MenuItem>
                    <MenuItem value="1">Обычное</MenuItem>
                    <MenuItem value="2">Хорошее</MenuItem>
                    <MenuItem value="3">Отличное</MenuItem>
                </Select>
            </FormControl>}
        </Grid2>
    );
};

export default RaidRewardItem;